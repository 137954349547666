import React, { useState } from 'react';
import { GradientBorder } from '../wrappers';
import { FaAngleDown } from "react-icons/fa6";

function Accordion({ data }) {
  const gradient = 'linear-gradient(90.14deg, #3883C8 72.95%, rgba(221, 221, 221, 0) 104.89%)'
  const [openQuestion, setOpenQuestion] = useState(null);

  const handleQuestionClick = (index) => {
    setOpenQuestion(openQuestion === index ? null : index);
  };

  const isQuestionOpen = (index) => openQuestion === index;

  return (
    <div>
      {data?.map((faq, index) => (
        <div key={index} className="mb-4">
          {!isQuestionOpen(index) && <div
            className={`flex justify-between gap-4 items-center cursor-pointer px-6 py-4 font-500  text-[14px] sm:text-[16px] leading-normal lg:leading-[21.6px] text-[#555555] transition-all duration-500 ease-in-out border-b-[1px] rounded-[8px]`}
            onClick={() => handleQuestionClick(index)}
          >
            <div className=''>
              {faq.question}
            </div>
            <div>
              <FaAngleDown className={`h-6 w-6 ${isQuestionOpen(index) ? 'transition-all ease-linear duration-200 transform rotate-180' : 'transition-all ease-linear duration-200 transform rotate-0'}`} />
            </div>
          </div>}
          {isQuestionOpen(index) && (
            <GradientBorder gradient={gradient} borderWidth={"1px"} radius={"8px"} left={false} right={false}>
              <div className="px-6 py-4 rounded mt-4 transition duration-300 ease-in-out opacity-100 font-500 text-[12px] lg:text-[14px] ">
                <div className='font-700 text-[16px] leading-[21.6px] flex items-center gap-4 justify-between'>
                  <div>{faq.question}</div>
                  <div onClick={() => handleQuestionClick(index)}>
                    <FaAngleDown className={`innerIcon h-6 w-6 cursor-pointer ${isQuestionOpen(index) ? 'transition-all ease-linear duration-200 transform rotate-180' : 'transition-all ease-linear duration-200 transform rotate-0'}`} />
                  </div>
                </div>
                <p className='mt-[12px] sm:mt-[28px] leading-[18px] lg:leading-[22px]'>{faq.answer}</p>
              </div>
            </GradientBorder>
          )}
        </div>
      ))}
    </div>
  );
};

export default Accordion;
