import React from "react";
import { PageLayout, RevealAnimation } from "../../ui/wrappers";
import ProductsMain from "../../components/products/productsMain";
import { Helmet } from "react-helmet";
import ProctoringMain from "../../components/proctoring/proctoringMain";
import ProctoringSection2 from "../../components/proctoring/proctoringSection2";
import ProctoringSection3 from "../../components/proctoring/proctoringSection3";
import ProctoringSection4 from "../../components/proctoring/proctoringSection4";

function ProctoringPage() {
  const gradient =
    "linear-gradient(115.03deg, rgba(142, 134, 226, 0.24) 17.08%, rgba(226, 223, 254, 0.12) 39.71%, rgba(139, 130, 233, 0.32) 63.92%)";
  const color = "#8079C8";
  return (
    <PageLayout>
      {/* <ProductsMain/> */}
      <Helmet>
        <title>
          Online Remote Proctoring Software I Online Remote Proctoring Tools
        </title>
        <meta
          name="description"
          content="Cutting-edge proctoring tools, designed to ensure the integrity of online assessments. Our AI-powered solutions offer make remote testing reliable and cheat-proof"
        />
        <link rel="canonical" href="https://ambitionhire.ai/proctoring" />
      </Helmet>
      <RevealAnimation>
        <ProctoringMain color={color} gradient={gradient} />
      </RevealAnimation>
      <RevealAnimation>
        <ProctoringSection2 color={color} gradient={gradient} />
      </RevealAnimation>
      <RevealAnimation>
        <ProctoringSection3 color={color} gradient={gradient} />
      </RevealAnimation>
      <RevealAnimation>
        <ProctoringSection4 color={color} gradient={gradient} />
      </RevealAnimation>
    </PageLayout>
  );
}

export default ProctoringPage;
