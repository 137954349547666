import React from 'react';

function QuestionCard({ text1, text2, text3, text4, text5, color }) {
  const commonStyles = 'rounded-[12px] px-[59px] text-center w-[172px] lg:w-[213px] h-[88px] flex items-center justify-center';

  return (
    <div className={`flex flex-col lg:gap-4 text-[18px] lg:text-[16px] leading-[24.3px] border-2 rounded-[12px] lg:p-4 font-500 relative`}>
      <div className={`bg-[#E7E7E7] lg:bg-[#F9F9F9] ${commonStyles} relative`}>{text1}</div>
      <div className={`bg-[#F4F4F4] lg:bg-[#F9F9F9]  ${commonStyles} relative z-10`}>{text2}</div>
      <div className={`bg-[#FFFFFF] lg:bg-[#F9F9F9]  ${commonStyles} relative z-20`}>{text3}</div>
      <div className={`text-white text-center ${color} ${commonStyles} flex-col gap-1 relative  z-30`}>
        <p className='font-700 whitespace-nowrap text-[16px] leading-[21.6px]'>{text4}</p>
        <p className='font-900 text-[12px] leading-[16.2px]'>{text5}</p>
      </div>
    </div>
  );
}

export default QuestionCard;
