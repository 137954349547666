import React from 'react';
import { GradientSection } from '../../../ui/wrappers';
import trailLogo from '../../../asset/questionLibrary/trialLogo.png';
import fig1 from '../../../asset/questionLibrary/fig1.png'
import fig2 from '../../../asset/questionLibrary/fig2.png'
import fig3 from '../../../asset/questionLibrary/fig3.png'
import { QuizButton, ContactUsButton } from '../../../ui/buttons';
import { PlayCircle, ScrollDown } from '../../../asset/icons';
import { MotionConfig, motion } from 'framer-motion'
function QuestionLibraryMain({ gradient }) {
  const color = '#8CB56C';
  const containerVariants = {
    initial: { opacity: 0 },
    animate: { opacity: 1, transition: { staggerChildren: .4 } },
  };
  const cardVariant1 = {
    initial: { opacity: 0, x: 1000 },
    animate: { opacity: 1, x: 0, transition: { type: 'spring', stiffness: 100, damping: 10, mass: 2.5 } },
  };
  const cardVariant2 = {
    initial: { opacity: 0, x: -1000 },
    animate: { opacity: 1, x: 20,y:-50, transition: { type: 'spring', stiffness: 100, damping: 20, mass: 2.5} },
  };
  const cardVariant3 = {
    initial: { opacity: 0, x: 1000 },
    animate: { opacity: 1, x:0,y:-100, transition: { type: 'spring', stiffness: 100, damping: 20, mass: 2.5 } },
  };
  return (
    <MotionConfig>
      <GradientSection gradient={gradient} trailLogo={trailLogo} bgColor="bg-[#8CB56C]" id={"gradientMain"} nav={true} color={color} textColor={'text-[#8CB56C]'}>
        <div className='flex flex-col-reverse sm:flex-row'>

          {/* Text Left Part */}
          <div className='flex-1 order-2'>
            <div className='flex flex-col -mt-10  sm:mt-0'>
              <h1 className='section_heading text-[24px] lg:text-[48px] text-center  sm:text-start leading-normal lg:leading-[60px] tracking-[-0.05em] sm:text-left'>Diverse Assessment Modules and Question Banks</h1>
              <div className=' text-[16px] md:text-[22px]  text-[#555] mt-4 lg:mt-6 font-500 leading-[29.7px] text-center sm:text-left'>
                <p>
                  Ambition Hire offers a diverse range of assessments, tailored to meet the needs of job seekers from various backgrounds and industries
                </p>
                <p className='hidden sm:block mt-6'>
                  Our question bank covers a wide gamut of industries, functional domains, and job roles. Whether you're looking for software developers, marketing professionals, project managers, or candidates in any other field, we've got you covered
                </p>
                <p className='hidden lg:block mt-6'>
                  Each module is tailored to address the specific skills and knowledge relevant to the job role, ensuring that candidates are assessed comprehensively
                </p>
              </div>
              {/* buttons */}
              <div className='mt-6 lg:mt-[48px] flex flex-col lg:flex-row gap-4 lg:gap-6'>
                <QuizButton link={"#"} text={"Take a Quick Demo"} bgColor={"bg-green"} textColor={"text-green"} />
                <ContactUsButton link="/contact" text={"Contact Us"} borderColor="border-green" textColor="text-green" />
              </div>
              <div className='mt-4 lg:mt-[24px] flex gap-2 items-center justify-center sm:justify-start font-700 text-[18px]'>
                <PlayCircle color={"#8CB56C"} />
                <a href='#' className='text-[14px] lg:text-[18px] text-green'>Check out our Product Summary</a>
              </div>
            </div>
          </div>

          {/* Images, Right Part */}
          <motion.div
            variants={containerVariants}
            initial="initial"
            animate="animate"
            className="flex-1 lg:flex-initial order-2">
            <motion.img
              variants={cardVariant1}
              src={fig1} className='hidden sm:block relative' />
            <motion.img
              variants={cardVariant2}
              src={fig2} className='relative scale-90 sm:scale-100 transform  z-10' />
            <motion.img
              variants={cardVariant3}
              src={fig3} className='relative  transform scale-75 sm:scale-100 translate-x-[40px] xs:translate-x-[60px]' />
          </motion.div>

          <div className='sm:hidden mt-[48px] flex items-center justify-center pb-4 order-1'>
            <span className='font-700 leading-[18.9px] text-[14px]'>Scroll</span>
            <ScrollDown />
          </div>
        </div>

      </GradientSection>
    </MotionConfig>
  );
}

export default QuestionLibraryMain;
