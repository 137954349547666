import { GradientBorder, GradientSection } from "../../ui/wrappers";
import { Icons } from "../../asset";
function ResumeParserSection3({ gradient, color }) {
  const data = [
    "Parsing resumes in multiple formats (PDF, Word, plain text)",
    "Extracting key candidate information (name, contact details, education, work experience, skills)",
    "Secure and scalable API infrastructure",
    "Customizable parsing rules to match your specific requirements",
    "Real-time parsing and data retrieval",
  ];

  return (
    <GradientSection
      sectionHeading={
        "Empower Your Hiring Process with Ambition Hire Resume Parser API"
      }
    >
      <p className="my-8 text-center text-sm  md:text-[22px] font-500 leading-[18.9px] md:leading-[34px] text-primary-light px-10">
        In addition to our powerful resume parser software, we also offer a
        robust resume parser API (Application Programming Interface) that allows
        you to integrate our resume parsing capabilities directly into your
        existing applications and workflows. By leveraging our API, you can
        automate and streamline your resume parsing process, ensuring a seamless
        and efficient candidate evaluation pipeline{" "}
      </p>
      <div className="mt-8 sm:mt-[64px] lg:mt-24 flex flex-col gap-6 mb-32">
        {data?.map((item, index) => (
          <div
            key={index}
            className="rounded-[16px] border py-6 px-8"
            style={{ background: gradient }}
          >
            <div className="text-base md:text-2xl leading-[21.6px] md:leading-[32.3px] text-primart-light font-700 flex gap-4">
              <div className="h-6 w-6 ">
                <Icons.Asterisk color={color} />
              </div>
              <p>{item}</p>
            </div>
          </div>
        ))}
        <div className="mt-32 mb-20">
          <GradientBorder
            gradient={
              "linear-gradient(93.24deg, #3883C8 46.36%, rgba(219, 219, 219, 0) 105.44%)"
            }
            radius={16}
          >
            <div className="h-full w-full py-12 px-8">
              <h2 className="text-center text-xl md:text-[32px] font-krona tracking-[-0.05em] leading-5 md:leading-10 rounded-[16px]">
                Get Started Today!
              </h2>
              <p className="font-500 text-sm md:text-xl leading-6 md:leading-[34px] text-center text-satoshi mt-8">
                Don't let the tedious task of manually parsing resumes hold you
                back any longer. Embrace the future of recruitment with our
                resume parser. Contact us today to schedule a demo or to learn
                more about how our solution can revolutionize your hiring
                process. Our dedicated team of experts is ready to assist you in
                achieving a more efficient and effective recruitment strategy
              </p>
            </div>
          </GradientBorder>
        </div>
      </div>
    </GradientSection>
  );
}

export default ResumeParserSection3;
