import React from 'react';
import { GradientBorder, GradientSection } from '../../ui/wrappers';
import mainImage from '../../asset/freeTrail/BACKGROUND.png';
import { SubmitButton } from '../../ui/buttons';
import { TextArea, Input, InputImage, DropdownInput } from '../../ui/inputs';
import formSmall from '../../asset/general/formSmall.png';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

function FreeTrialMain() {
  const gradient = 'linear-gradient(115.03deg, rgba(125, 73, 150, 0.18) 17.08%, rgba(125, 73, 150, 0.08) 39.71%, rgba(125, 73, 150, 0.24) 63.92%)';
  const options = [1, 2, 3, 4, 5, 6, 7, 8];
  const inputClass = 'w-full col-span-2 sm:col-span-1';

  const initialValues = {
    imageName: null,
    companyName: '',
    companyDescription: '',
    companyAddress: '',
    websiteLink: '',
    numberOfEmployees: '',
    linkedInProfileLink: '',
    spocEmailAddress: '',
    spocMobileNumber: '',
  };

  const validationSchema = Yup.object({
    imageName: Yup.mixed().required('Image is required'),
    companyName: Yup.string().required('Company Name is required'),
    companyDescription: Yup.string().required('Company Description is required'),
    companyAddress: Yup.string().required('Company Address is required'),
    websiteLink: Yup.string().url('Invalid URL').required('Website Link is required'),
    numberOfEmployees: Yup.string().required('Number of Employees is required'),
    linkedInProfileLink: Yup.string().url('Invalid URL').required('LinkedIn Profile Link is required'),
    spocEmailAddress: Yup.string().email('Invalid email address').required('SPOC Email Address is required'),
    spocMobileNumber: Yup.string().matches(/^[6-9]\d{9}$/, 'Invalid Mobile Number').required('SPOC Mobile Number is required'),
  });

  const handleSubmit = (values, { resetForm }) => {
    // Handle form submission logic here
    console.log(values);
    alert(JSON.stringify(values));
    // Reset the form after submission if needed
    resetForm();
  };

  return (
    <GradientSection gradient={gradient} nav={true} color={"#7D4996"} textColor={'text-[#7D4996]'}>
      <div className='pb-[64px]'>
        <div className='rounded-[12px] sm:hidden overflow-hidden my-8'>
          <img src={formSmall} className='object-cover w-full h-full' />
        </div>
        <h2 className='font-krona text-[20px] lg:text-[24px] leading-[25px] lg:leading-[30px] tracking-[-0.05em] text-center md:text-left'>Fill in the details to start your free trial</h2>
        <div className='flex mt-6 gap-6'>
          {/* Image Box */}
          <div className='flex-1 rounded-[12px] hidden sm:block overflow-hidden '>
            <img src={mainImage} className='max-w-[479px]' />
          </div>

          {/* Form */}
          <div className='flex-2 lg:flex-1'>
            <GradientBorder radius={"12px"} borderWidth={"1px"}>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                <Form className='p-4 lg:p-6 space-y-8'>
                  <div className='grid grid-cols-2 gap-6'>
                    <div className='flex flex-col md:flex-row md:justify-between md:items-center col-span-2 gap-[17px] md:gap-8 pb-6 border-b'>
                      <InputImage id={'imageName'} />
                      <div className='w-full md:flex-1'>
                        <Input label={"Company Name*"} placeholder={"Enter Company Name"} id="companyName" />
                      </div>
                    </div>
                    <div className="col-span-2">
                      <TextArea label={"Company Description*"} placeholder={"Enter Company Description"} id="companyDescription" />
                    </div>
                    <div className={`${inputClass}`}>
                      <Input label={"Company Address*"} placeholder={"Enter Company Address"} id="companyAddress" />
                    </div>
                    <div className={`${inputClass}`}>
                      <Input label={"Website Link*"} placeholder={"Enter Website Link"} id="websiteLink" />
                    </div>
                    <div className={`${inputClass}`}>
                      <DropdownInput label={"Number of Employees*"} placeholder={"Select Number of Employees"} options={options} id="numberOfEmployees" />
                    </div>
                    <div className={`${inputClass}`}>
                      <Input label={"LinkedIn Profile Link*"} placeholder={"Enter LinkedIn Profile Link"} id="linkedInProfileLink" />
                    </div>
                    <div className={`${inputClass}`}>
                      <Input label={"SPOC Email Address*"} placeholder={"Enter Organization Email Address"} subScript={["Don’t have organization email address?", "Contact Sales Team"]} id="spocEmailAddress" />
                    </div>
                    <div className={`${inputClass}`}>
                      <Input label={"SPOC Mobile Number*"} placeholder={"Enter SPOC Mobile Number"} id="spocMobileNumber" phone={true} />
                    </div>
                  </div>
                  <div className='flex sm:justify-end'>
                    <SubmitButton text={"Avail Free Trial"} textColor={"text-primary"} bgColor={"bg-primary"} />
                  </div>
                </Form>
              </Formik>
            </GradientBorder>
          </div>
        </div>
      </div>
    </GradientSection>
  );
}

export default FreeTrialMain;
