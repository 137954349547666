import { isValidDateValue } from '@testing-library/user-event/dist/utils'
import React from 'react'
import { motion } from 'framer-motion';
function Product_Tab({ isActive, name, handleClick }) {
    const classes = isActive ? " sm:w-[217px] sm:h-[117px] bg-blue-light text-white border-blue-light" : " border-blue-light text-blue-light";
    return (
        <motion.button
            onClick={() => handleClick(name)}
            className={`${classes} sm:w-[217px] sm:h-[117px]  grid place-content-center border-2  z-40  rounded-[128px] px-6 py-6 lg:px-8 text-[12px] xs:text-[14px] lg:text-[20px] font-krona leading-[17.5px] lg:leading-[27.5px] transition-all duration-200 ease-linear`}
        >
            <p className='text-center'>{name}</p>
        </motion.button>
    )
}

export default Product_Tab