import React, { useState } from 'react';
import { FaAngleDown } from "react-icons/fa6";
import { useField, useFormikContext } from 'formik';
import { motion } from 'framer-motion';
function DropdownInput({ id, label, placeholder, phone, options }) {
    const { setFieldTouched, setFieldValue } = useFormikContext();
    const [isFocused, setIsFocused] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');

    const handleFocus = () => {
        setIsFocused(true);
        setShowDropdown(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
        // Delay hiding the dropdown to capture click events on the dropdown
        setTimeout(() => {
            setShowDropdown(false);
        }, 200);
        setFieldTouched(id, true);
    };

    const handleDropdownItemClick = (value) => {
        // Handle the selected dropdown item value
        setSelectedOption(value);
        setShowDropdown(false); // Hide the dropdown after selection
        setFieldValue(id, value);
    };

    const handleLogoClick = () => {
        // Toggle the visibility of the dropdown when clicking on the logo
        setShowDropdown(!showDropdown);
    };

    const [field, meta] = useField(id);

    const boxStyle = {
        border: `2px solid ${isFocused ? '#8B5CF6' : (meta.touched && meta.error ? '#FF0000' : '#D1D1D1')}`,
        outline: 'none',
        transition: 'border-color 0.1s ease-in-out',
    };

    return (
        <div className={`flex flex-col gap-y-2 relative maindiv`}>
            <label className={`font-bold text-[12px] font-700`} htmlFor={id || label}>
                {label}
            </label>
            <div className='flex items-center bg-white rounded-[6px] p-[12px]' style={boxStyle}>
                {phone && <span className='px-2 border-r-2'>+91 </span>}
                <div className='flex justify-center items-center w-full'>
                    <input
                        type={phone ? 'tel' : 'text'}
                        placeholder={placeholder}
                        {...field}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        value={selectedOption} // Use the selected option as the input value
                        className={`w-full focus:outline-none placeholder:text-[#BBBBBB] placeholder:font-400 font-700 text-[#8079C8] rounded-[6px] ${phone ? "px-2" : ""}`}
                        id={id || label}
                    />
                    {showDropdown && (
                        <div className="absolute top-full left-0 mt-2  translate-y-[-32px] bg-white border rounded shadow-md w-full z-40">
                            {/* Render your dropdown options dynamically */}
                            {options?.map((option) => (
                                <motion.div
                                    whileHover={{ opacity: 0.5, color: '#8B5CF6' }}
                                    key={option} onClick={() => handleDropdownItemClick(option)} className='cursor-pointer border-b py-2 px-10 grid place-content-start'>
                                    {option}
                                </motion.div>
                            ))}
                        </div>
                    )}
                    <FaAngleDown onClick={handleLogoClick} className='cursor-pointer' />
                </div>
            </div>
            <div className='text-[12px] h-4'>
                {meta.touched && meta.error && (
                    <span className="font-500 leading-[16.2px] text-red-500">
                        {meta.error}
                    </span>
                )}
            </div>
        </div>
    );
}

export default DropdownInput;
