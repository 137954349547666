import React from 'react'

function Menu() {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="menu-02">
                <path id="Icon" d="M4 16H20M4 8H28M4 24H28" stroke="#1E1B1E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </g>
        </svg>

    )
}

export default Menu;