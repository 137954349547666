import React from 'react'
import whatsapp from '../../../../asset/home/whatsapp.png'
import chatMain from '../../../../asset/home/chatMain.png';
import { MotionConfig, motion } from 'framer-motion'
function CustomerSupport() {
  return (
    <MotionConfig
      transition={{ duration: .5 }}
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        <h3 className='lvisible  text-[20px] md:text-[24px] tracking-[-1.2px] text-[#6B9649] font-krona'>24/7 Customer Support</h3>
        <p className='lvisible text-[16px] mt-[14px] leading-[21.6px] font-500'>With our around-the-clock, 24/7 customer support, recruiters can reach out for assistance, answers, or solutions to any queries or challenges they may encounter.</p>
        <motion.div
          initial={{ opacity: 0, scale: 0.5,y:-1000}}
          animate={{ opacity: 1, scale: 1,y:30 }}
          transition={{ type: 'spring', stiffness: 100, damping: 15, mass:2.5 }}
          className='flex flex-col lg:flex-row'>

          <motion.div>
            <img src={whatsapp} className='object-fill' />
          </motion.div>
          <motion.div className='relative'>
            <img src={chatMain} className='mt-[-25px] lg:mt-[50px]' />
          </motion.div>

        </motion.div>
      </motion.div>
    </MotionConfig>
  )
}

export default CustomerSupport;