import React from 'react';

function StrategyBox({ title, content, gradient }) {
  const boxStyle = {
    background: gradient,
  };

  return (
    <div className='rounded-[24px] px-4 py-4 sm:px-[18px] lg:p-8' style={boxStyle}>
      <h3 className='font-krona text-[16px] lg:text-[20px] leading-[20px] lg:leading-[25px]'>{title}</h3>
      <p className='mt-4 lg:mt-6 text-[14px] lg:text-[18px]  font-500  text-[#555555] leading-[22px] lg:leading-[26px]'>{content}</p>
    </div>
  );
}

export default StrategyBox;
