import React from "react";
import { GradientBorder, GradientSection } from "../../ui/wrappers";
import { ContactUsButton, QuizButton } from "../../ui/buttons";
import { PlayCircle } from "../../asset/icons";
import { color } from "framer-motion";
import { Link,  useNavigate } from 'react-router-dom';
import { FaArrowRight } from "react-icons/fa6";

function ProctoringMain({ gradient, color }) {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };  
  const navigate = useNavigate();

  const handleClick =  () => {
      navigate('/online-assessment-platform');
      scrollToTop();
    };
  return (
    <GradientSection
      nav={true}
      color={color}
      bgColor={color}
      gradient={gradient}
      textColor={"text-violet"}
    >
      <div className="flex flex-col lg:mb-32">
        {/* Headings */}
        <h1 className="text-[24px] lg:text-[48px] font-krona tracking-[-0.05em]  max-w-[781px]">
          Best Online Proctoring Tool from Ambition Hire
        </h1>
        <div className="mt-6">
          {/* <h5 className="font-700 text-[22px] leading-[29.7px] font-satoshi">
            A game-changer in the world of recruitment!
          </h5> */}
          <div className="text-sm lg:text-[22px] font-500 leading-[18.9pxpx] sm:leading-[21.6px]  lg:leading-[34px] mt-4 lg:mt-6 text-[#505050]">
            <p>
              In today's digital era, maintaining integrity in{" "}
              <span
                className=" hover:underline text-blue-800"
                onClick={handleClick}              
                >
                {" "}
                online assessments
              </span>{" "}
              is paramount. With the surge in remote learning and assessments,
              institutions worldwide are seeking robust solutions to combat
              cheating and ensure fair evaluation. Our proctoring tool stands at
              the forefront, offering unparalleled features to uphold exam
              integrity seamlessly.
            </p>

            <p className="mt-5">
              Proctoring software is a technological solution designed to
              monitor online tests and assessments to ensure integrity and
              prevent cheating. It employs various techniques such as webcam
              monitoring, screen recording, and advanced algorithms to detect
              and deter dishonesty during remote or online tests.
            </p>
          </div>
        </div>
        {/* Buttons */}
        <div className="mt-6 mb-12 lg:mb-24 lg:mt-[64px] flex flex-col   sm:flex-row gap-4 lg:gap-6">
          <Link
            to={"/quiz-demo"}
            className="flex gap-2 lg:gap-[18px] items-center justify-center bg-violet px-[32px] py-[12px] lg:py-4 rounded-[128px] w-full lg:w-auto"
          >
            <span className="text-[13px] lg:text-[18px] font-krona text-white">
              Schedule a Quick Demo
            </span>
            <p className="bg-white rounded-full grid place-content-center p-[5.455px] lg:p-[7.273px]">
              <FaArrowRight className={`text-[20px] text-violet`} />
            </p>
          </Link>
        </div>
      </div>
    </GradientSection>
  );
}

export default ProctoringMain;
