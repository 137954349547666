import { Link } from "react-router-dom";
import { GradientSection } from "../../ui/wrappers";
function ResumeParserSection2({ gradient }) {
  const data = [
    {
      title: "Saves your Time and Effort",
      text: "Ambition Hire’s resume parser can process hundreds of resumes in a matter of minutes, saving you countless hours that would otherwise be spent manually parsing each document. This increased efficiency allows you to focus on more critical tasks, such as evaluating candidates and making informed hiring decisions",
    },
    {
      title: "Improved Candidate Matching",
      text: "With the Ambition Hire resume parser, you can easily identify the most qualified candidates based on your specific job requirements. The extracted data can be seamlessly integrated into your applicant tracking system (ATS), enabling you to search for and rank candidates based on their skills, experience, and qualifications",
    },
    {
      title: "Scalability and Flexibility",
      text: "Whether you're a small business or a large corporation, our resume parser can accommodate your unique hiring needs. With its scalable architecture and flexible deployment options, you can process resumes in bulk or integrate it seamlessly into your existing HR processes",
    },
    {
      title: "Enhanced Data Accuracy",
      text: "Manual data entry is prone to errors, but our resume parser ensures high levels of accuracy by automatically extracting and organizing candidate information. This minimizes the risk of overlooking crucial details and ensures that you have a comprehensive understanding of each applicant's profile",
    },
  ];

  return (
    <GradientSection
      sectionHeading={"Why choose Ambition Hire’s Resume Parser?"}
    >
      <div className="mt-8 sm:mt-[64px] lg:mt-24 grid grid-cols-1 md:grid-cols-2 gap-8">
        {data?.map((item, index) => (
          <div
            key={index}
            className="rounded-[24px] p-8"
            style={{ background: gradient }}
          >
            <h3 className="text-base sm:text-xl font-krona leading-5 sm:leading-[25px] text-center">
              {item.title}
            </h3>
            <p className="text-sm sm:text-lg text-[#555555] leading-[18.9px] sm:leading-[26px] font-500 mt-6 sm:mt-8">
              {index === 1 ? (
                <>
                  With the{" "}
                  <Link
                    className="hover:underline text-blue-800"
                    to="/"
                  >
                    Ambition Hire
                  </Link>{" "}
                  resume parser, you can easily identify the most qualified
                  candidates based on your specific job requirements. The
                  extracted data can be seamlessly integrated into your{" "}
                  <Link
                    className="hover:underline text-blue-800"
                    to="/online-assessment-platform"
                  >
                    applicant tracking system (ATS)
                  </Link>
                  , enabling you to search for and rank candidates based on
                  their skills, experience, and qualifications.
                </>
              ) : (
                item.text
              )}
            </p>
          </div>
        ))}
      </div>
    </GradientSection>
  );
}

export default ResumeParserSection2;
