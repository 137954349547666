// Hire_Card component
import { MotionConfig } from 'framer-motion';
import React from 'react';

function Hire_Card({ count = 1, title = 'Title', description = 'Description', onClick, isSelected }) {
  const cardClass = `cursor-pointer ${isSelected ? 'bg-[#ADD8E6]' : 'hover:bg-[#F0FAFC]'
    } border-[2px] rounded-[16px] transition duration-300 ease-in-out`;

  return (
    <MotionConfig>
      <div className={cardClass} onClick={onClick}>
        <div className={`${isSelected ? 'text-[#495F96]' : 'text-[#505050]'} ${isSelected ? 'bg-[#F0FAFC]' : 'bg-white'} w-full lg:w-[379px] flex p-4 gap-x-3 rounded-[16px] transition-all duration-300 ease-in-out`}>
          <div className='box1'>
            <div className={`rounded-full p-2 h-6 w-6 text-[12px] font-500 ${isSelected ? 'bg-[#495F96]' : 'bg-[#505050]'} text-white flex items-center justify-center`}>
              {count}
            </div>
          </div>
          <div className=''>
            <h3 className={`text-[18px] font-700 leading-[24.3px] ${isSelected ? 'text-[#495F96]' : 'text-[#505050]'}`}>{title}</h3>
            <p className="text-[12px] mt-[8px] leading-[16.2px] font-500">{description}</p>
          </div>
        </div>
      </div>
    </MotionConfig>
  );
}

export default Hire_Card;
