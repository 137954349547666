import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

// components import
import { ContactButton } from "../../ui/buttons";
import { TextArea, Select, Input } from "../../ui/inputs";
import SubmitButton from "../../ui/buttons/submitButton";
import { OR } from "../../ui/tabs";

//asset import
import styles from "./style.module.css";
import NavBar from "../nav";
import trail from "../../asset/contact/trial.png";
import phone from "../../asset/contact/phone.svg";
import whatsapp from "../../asset/contact/whatsapp.svg";
import noise from "../../asset/home/Noise.png";
import { ContactAPI } from "../../service/api";

function ContactForm() {
  const navigate = useNavigate();
  const color = "#8079C8";

  const options = [
    {
      name: "Pricing Queries",
    },
    {
      name: "Partnership Queries",
    },
    {
      name: "Request a Demo",
    },
    {
      name: "Others",
    },
  ];

  const initialValues = {
    name: "",
    phone: "",
    company_name: "",
    email: "",
    query_description: "",
    subject: options[0].name, // Ensure only one 'subject' field
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Full Name is required"),
    phone: Yup.string()
      .matches(/^(0\d{10}|[6-9]\d{9})$/, "Invalid Phone Number")
      .required("Phone Number is required"),
    company_name: Yup.string().required("Company Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Work Email Address is required"),
    query_description: Yup.string().required("Query is required"),
    subject: Yup.string().required("Select subject"),
  });

  const handleSubmit = async (values, { resetForm }) => {
    try {
      // Add additional default fields to the values object
      const updatedValues = {
        ...values,
        query_type: "support",
        alternate_email: "",
        remarks: "", 
        status: "open",
      };

      // Handle form submission logic here
      await ContactAPI(updatedValues);
      navigate("/thanks");
      window.scrollTo(0, 0);

      // Reset the form after submission if needed
      resetForm();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const [selectedOption, setSelectedOption] = useState(options[0].name);
  const handleOptionClick = (name) => {
    setSelectedOption(name);
  };

  return (
    <div className={`relative`}>
      <div
        className={`absolute -z-10 opacity-85 ${styles.gradient_bg} h-full w-full top-0 left-0`}
      ></div>

      <div className="wrapper py-10">
        <NavBar
          bgColor="bg-[#8079C8]"
          trailLogo={trail}
          color={color}
          textColor={"text-[#8079C8]"}
        />
        <div className="grid place-content-center">
          <div className="flex flex-col items-center  max-w-[790px] gap-6 mt-10   place-content-center">
            <div className="w-full">
              <h2 className=" text-[20px] lg:text-[24px] font-krona text-center md:text-left">
                Contact Us
              </h2>
              <div className="flex flex-col sm:flex-row place-content-center gap-5 mt-5">
                <ContactButton
                  text="Call now"
                  contact="+91 9728000469"
                  symbol={phone}
                  textColor="text-[#8079C8]"
                />
                <ContactButton
                  text="Whatsapp"
                  contact="+91 9728000469"
                  symbol={whatsapp}
                  textColor="text-[#8079C8]"
                />
              </div>
            </div>
            <OR />
            <div className="w-full">
              <h2 className="text-[20px] lg:text-[24px] font-krona text-center md:text-left">
                Submit a Query
              </h2>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                <Form className="mt-6  p-4 lg:p-6 grid grid-cols-1 md:grid-cols-2 gap-6 lg:gap-8 place-content-center">
                  <Input
                    placeholder={"Gaurav Sharma"}
                    label={"Full Name"}
                    spread={2}
                    id={"name"}
                  />
                  <Input
                    placeholder={"Enter Phone Number"}
                    label={"Phone Number"}
                    phone={true}
                    id={"phone"}
                  />
                  <Input
                    placeholder={"Enter Company Name"}
                    label={"Company Name"}
                    spread={1}
                    id={"company_name"}
                  />
                  <Input
                    placeholder={"Enter Work Email Address"}
                    label={"Work Email Address"}
                    spread={1}
                    id={"email"}
                  />
                  <div className="md:col-span-2">
                    <Select
                      options={options}
                      title={"Select"}
                      onSelect={handleOptionClick}
                      selectedOption={selectedOption}
                      id={"subject"}
                    />
                  </div>
                  <div className="md:col-span-2">
                    <TextArea
                      placeholder={"Enter a Message for us"}
                      label={"Query"}
                      id={"query_description"}
                    />
                  </div>
                  <div className="md:col-span-2 flex justify-end items-end">
                    <SubmitButton
                      text={"Submit Query"}
                      bgColor={"bg-violet"}
                      textColor={"text-violet"}
                    />
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </div>
      <img
        src={noise}
        className="object-cover w-full h-full object-fill opacity-[20%] absolute top-0 -z-10 "
        alt="noise"
      />
    </div>
  );
}

export default ContactForm;
