import React, { useState } from 'react'
import { Offer_Box } from '../../../../ui/cards';
import chart from '../../../../asset/home/result/chart.png'
import donut from '../../../../asset/home/result/donut chart 1.png'
import { motion } from 'framer-motion';
export default function Result() {
    const containerVariants = {
        initial: { opacity: 0 },
        animate: { opacity: 1, transition: { type: 'spring', stiffness: 500, damping: 10, staggerChildren: .6 } },
    };
    const cardVariant1 = {
        initial: { opacity: 0, y: 1000 },
        animate: { opacity: 1, y: 0, transition: { type: 'spring', stiffness: 100,damping: 30, mass: 2.5 } },
    };
    const cardVariant2 = {
        initial: { opacity: 0, y: -1000 },
        animate: { opacity: 1, y: 0, transition: { type: 'spring', stiffness: 100, damping: 30, mass: 2.5 } },
    };
    return (
        <>
            {/* tab text */}
            <div className='flex-1'>
                <h4 className='text-blue-light text-[16px] lg:text-[24px] font-500'>Providing insights to our recruiters for an in-depth understanding of their candidates and job performance</h4>
                <div className='mt-[24px] lg:mt-[48px] flex flex-col gap-4 lg:gap-6'>
                    <Offer_Box title="Candidates’ Performance" desc="Take a look at the total number of candidates hired by you along with a pie chart showcasing the candidates assessed, successfully passed the set of assessments and the ones who got hired" />
                    <Offer_Box title="Assessment In-sights" desc="Get practical module-wise in-sights for assessments which translates legibility of assessments for candidates as well as set cut-offs" />
                </div>
            </div>
            {/* tab images */}
            <motion.div
                variants={containerVariants}
                initial="initial"
                animate="animate"
                className='mvisible flex-1 overflow-hidden'>
                <motion.div variants={cardVariant1}>
                    <img src={donut} alt='tab_image' className='transform lg:translate-x-[70px]' />
                </motion.div >
                <motion.div variants={cardVariant2}>
                    <img src={chart} alt='tab_image' className='transform translate-y-[-100px]' />
                </motion.div>
            </motion.div>
        </>
    )
}
