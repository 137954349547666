import React from "react";
import {  GradientSection } from "../../ui/wrappers";
import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa6";

function ResumeParserMain({ gradient, color }) {
  return (
    <GradientSection
      nav={true}
      color={color}
      bgColor={color}
      gradient={gradient}
      textColor={"text-azure"}
    >
      <div className="flex flex-col relative">
        {/* Headings */}
        <h1 className="text-[24px] lg:text-[48px] font-krona tracking-[-0.05em]  max-w-[781px]">
          Hire fast, Hire right with Ambition Hire Resume Parser
        </h1>
        <div className="mt-6">
          <h5 className="font-700 text-sm sm:text-[22px] leading-[18.9px] sm:leading-[29.7px] font-satoshi">
            A game-changer in the world of recruitment!
          </h5>
          <div className="text-[14px] lg:text-[22px] font-500 leading-[18.9px] md:leading-[34px] lg:leading-normal mt-4 lg:mt-6 text-[#505050]">
            <p>
              With our cutting-edge technology, you can say goodbye to the
              tedious and time-consuming task of{" "}
              <Link
                className="hover:underline text-blue-800"
                to="/resume-parser"
              >
                parsing resumes
              </Link>{" "}
              and hello to a more efficient and streamlined hiring process.
            </p>

            <p className="mt-5">
              A resume parser is an intelligent software solution that
              automatically extracts relevant information from resumes, such as
              candidate names, contact details, educational qualifications, work
              experience, skills, etc. By leveraging advanced natural language
              processing and machine learning algorithms, our resume parser can
              quickly and accurately parse resumes in various formats, including
              PDF, Word, and plain text.
            </p>
          </div>
        </div>
        {/* Buttons */}
        <div className="mt-6 mb-12 lg:mb-24 lg:mt-[64px] flex flex-col   sm:flex-row gap-4 lg:gap-6">
          <Link
            to={"/quiz-demo"}
            className="flex gap-2 lg:gap-[18px] items-center justify-center bg-azure px-[32px] py-[12px] lg:py-4 rounded-[128px] w-full lg:w-auto"
          >
            <span className="text-[13px] lg:text-[18px] font-krona text-white">
              Schedule a Quick Demo
            </span>
            <p className="bg-white rounded-full grid place-content-center p-[5.455px] lg:p-[7.273px]">
              <FaArrowRight className={`text-[20px] text-azure`} />
            </p>
          </Link>
          {/* <Link to={'/quiz-demo'} className='font-krona text[14px] lg:text-[18px] text-azure border-[3px] border-azure px-[40px] py-[12px] lg:py-[20px] rounded-[128px] whitespace-nowrap'>
            Explore Assesst LIbrary
          </Link> */}
        </div>

        {/* Trust Box */}
        <div
          className="relative top-32 lg:top-48 border py-12 px-8 flex flex-col gap-8 rounded-[16px] bg-white mb-12 lg:mb-24"
          style={{ boxShadow: "0px 16px 36px 0px #5A5A5A14" }}
        >
          <h3 className="text-xl lg:text-[32px] font-krona tracking-[-0.05em] leading-[25px] lg:leading-10 text-center">
            Trusted by Leading Organizations
          </h3>
          <p className=" text-sm lg:text-xl leading-[18.9px] lg:leading-[34px] text-center text-primary-light">
            Our resume parser has been trusted by countless organizations across
            various industries, helping them to streamline their hiring
            processes and make more informed hiring decisions. Join the ranks of
            satisfied customers who have experienced the transformative power of
            our resume parsing solution
          </p>
        </div>
      </div>
    </GradientSection>
  );
}

export default ResumeParserMain;
