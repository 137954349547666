import { GradientSection } from "../../ui/wrappers";
function VideoInterviewSection2({ gradient }) {
  const data = [
    {
      title: "Increased Efficiency",
      text: "With our video interview platforms, you can schedule and conduct interviews with ease, saving valuable time and resources that would otherwise be spent on coordinating in-person meetings. This streamlined process enables you to evaluate more candidates in a shorter timeframe, accelerating your hiring cycle",
    },
    {
      title: "Wider Talent Pool",
      text: "By removing geographical limitations, our video interview platforms open up a world of possibilities, giving you access to a vast pool of talented candidates from diverse locations. This increased reach ensures that you can find the best fit for your organization, regardless of where the candidate is based",
    },
    {
      title: "Enhanced Candidate Experience",
      text: "Our user-friendly video interview platforms provide a professional and engaging experience for candidates, fostering a positive impression of your company from the very beginning. With features like high-quality video and audio, screen-sharing capabilities, and virtual whiteboards, you can conduct thorough and immersive interviews that showcase your organization's commitment to technology and innovation",
    },
    {
      title: "Secure and Reliable",
      text: "We understand the importance of protecting sensitive information during the interview process. Our virtual interview platforms are built with robust security measures, including data encryption and secure video transmission, ensuring the confidentiality and privacy of your interviews",
    },
  ];

  return (
    <GradientSection
      sectionHeading={
        "Benefits of Using Ambition Hire’s Video Interview Platform"
      }
    >
      <div className="mt-8 sm:mt-[64px] lg:mt-24 grid grid-cols-1 md:grid-cols-2 gap-8">
        {data?.map((item, index) => (
          <div
            key={index}
            className="rounded-[24px] p-8"
            style={{ background: gradient }}
          >
            <h3 className="text-lg sm:text-xl font-krona leading-[22.6px] sm:leading-[25px] text-center">
              {item.title}
            </h3>
            <p className="text-base sm:text-lg text-[#555555] leading-[22px] sm:leading-[26px] font-500 mt-6 sm:mt-8">
              {item.text}
            </p>
          </div>
        ))}
      </div>
    </GradientSection>
  );
}

export default VideoInterviewSection2;
