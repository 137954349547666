import React from "react";
import { QuestionLabel } from "../buttons";
import { FaArrowRight } from "react-icons/fa6";
import { useMedia } from "../../utils/useMedia";
import { useRoutes } from "react-router-dom";
function QuestionBankCard({ data, onDetailsClick }) {
  // const maxLength = 306;
  return (
    <div className="border rounded-[24px] p-4 lg:p-6">
      <h3 className="text-[14px] lg:text-[20px] text-wrap font-krona leading-[25px] tracking-[-0.7px] lg:tracking-[-0.05em] overflow-x-scroll scrollbar-hide">
        {data.title}
      </h3>
      <div className="flex flex-col sm:flex-row gap-2 lg:gap-4 mt-4 lg:mt-6 justify-center sm:justify-start">
        <QuestionLabel type={data.type} labelText={data.labelText} />
        {/* <p className="text-[12px] lg:text-[16px] font-500 lg:font-700 leading-[21.6px] text-[#555555] px-4 py-[6px] lg:py-2 border rounded-[32px] text-center">
          {data.numberOfQuestions || "00"} Questions
        </p> */}
      </div>
      <p className="text-[14px] font-500 leading-[18.9px] text-[#555555] mt-4 max-h-32 line-clamp-4">
        <span className="hidden sm:inline">{data.introductionText}</span>
      </p>

      <div className="flex justify-end mt-4 lg:mt-8">
        <button
          onClick={onDetailsClick}
          className="text-[#555555] flex items-center gap-[4px] lg:gap-[6px] px-4 py-2 sm:bg-[#F3F3F3] rounded-[32px]"
        >
          <span className="text-[12px] lg:text-[18px] font-700 leading-normal lg:leading-[24.3px]">
            Details
          </span>
          <span className="sm:bg-[#555555] rounded-full p-1">
            <FaArrowRight className="text-[#55555] sm:text-white" />
          </span>
        </button>
      </div>
    </div>
  );
}

export default QuestionBankCard;
