import React, { useState } from 'react'
import { Offer_Box } from '../../../../ui/cards';
import proctoring from '../../../../asset/home/proctoring/proctoring.png'
import photo from '../../../../asset/home/proctoring/photo.png'
import video from '../../../../asset/home/proctoring/video.png'
import radio1 from '../../../../asset/home/proctoring/radio1.png'
import radio2 from '../../../../asset/home/proctoring/radio2.png'
import tabs from '../../../../asset/home/proctoring/tabs.png'
import { motion } from 'framer-motion';

import {  useNavigate } from 'react-router-dom';

export default function Proctoring() {
    const scrollToTop = () => {
        window.scrollTo(0, 0);
      };  
      const navigate = useNavigate();
    
      const handleClick =  () => {
          navigate('/proctoring');
          scrollToTop();
        };
    return (
        <>
            {/* tab text */}
            <div className='flex-1'>
                <h4 className='text-blue-light text-[16px] lg:text-[24px] font-500'>Ensuring integrity in assessments with proctoring and anti-cheat solutions for trustworthy candidate evaluation                       
                <span
                className=" hover:underline  text-[16px] lg:text-[24px] font-semibold "
                onClick={handleClick}
              >
                {" "}
                View All
              </span>{" "}</h4>            
                <div className='mt-[24px] lg:mt-[48px] flex flex-col gap-4 lg:gap-6'>
                    <Offer_Box title="UMC-Free Assessments" desc="Ambition Hire can detect No-Face, Multi-Face and Multi-Voice, while recruiters can set threshold (duration or/and count) for each one of these. Our platform prohibits candidates from accessing other tab windows of the browser and right-click menu for a foolproof assessment platform" />
                    <Offer_Box title="Detect Suspicious Behavior" desc="Video proctoring pins time stamps for any suspicious moments noticed during the assessment and allows recruiters to automatically filter out candidates who were involved in dishonest means." />
                </div>
            </div>
            {/* tab images */}
            <motion.div
                initial={{ opacity: 0, scale: 0 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ type: 'spring', stiffness: 100,damping: 10, mass: 2.5 }}
                className='mvisible flex-1 overflow-hidden px-10 '>
                <img src={radio2} alt='tab_image' className='z-40 relative' />
                <div className='flex transform translate-y-[-30px] relative z-10'>
                    <img src={video} alt='tab_image' className='' />
                    <img src={photo} alt='tab_image' className='transform translate-x-[-30px]' />
                </div>
                <img src={proctoring} alt='tab_image' className='transform translate-x-[50px] lg:translate-x-[100px] translate-y-[-70px] relative' />
                <img src={radio1} alt='tab_image' className='transform translate-x-[70px] lg:translate-x-[230px] translate-y-[-130px] relative z-10' />
                <img src={tabs} alt='tab_image' className='transform translate-x-[1px] translate-y-[-190px] relative z-20' />
            </motion.div>
        </>
    )
}
