import React from 'react'

function UploadIcon() {
  return (
<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="upload-01">
<path id="Icon" d="M11.4549 8.18169V8.83623C11.4549 9.75268 11.4549 10.2109 11.2765 10.5609C11.1197 10.8688 10.8693 11.1192 10.5614 11.2761C10.2114 11.4544 9.75317 11.4544 8.83672 11.4544H4.2549C3.33845 11.4544 2.88023 11.4544 2.53019 11.2761C2.22229 11.1192 1.97196 10.8688 1.81507 10.5609C1.63672 10.2109 1.63672 9.75268 1.63672 8.83623V8.18169M9.27308 4.3635L6.54581 1.63623M6.54581 1.63623L3.81854 4.3635M6.54581 1.63623V8.18169" stroke="#7D4996" stroke-width="1.09091" stroke-linecap="round" stroke-linejoin="round"/>
</g>
</svg>    
  )
}

export default UploadIcon;