import React, { useEffect, useState } from "react";
import { Star } from "../../asset/icons";
import bannerBG from "../../asset/general/bannerBg.png";
import { CalculateTimeRemaining, targetDate } from "../../utils";
import { ConfettiWrapper } from "../../ui/wrappers";

//functions
function Header({ color = "#7D4996", textColor = "text-[#7D4996]" }) {
  //Functions
  const calculateClock = (remainingTime) => {
    const days = Math.floor(remainingTime / (60 * 60 * 24));
    const hours = Math.floor(
      (remainingTime % (1000 * 60 * 60 * 24)) / (60 * 60)
    );
    const minutes = Math.floor((remainingTime % (60 * 60)) / 60);
    // Display days, hours, minutes, and seconds
    return {
      days: formatTwoDigits(days),
      hours: formatTwoDigits(hours),
      minutes: formatTwoDigits(minutes),
      seconds: formatTwoDigits(remainingTime % 60),
    };
  };

  // Function to format digits to always have two digits (e.g., 03, 09)
  const formatTwoDigits = (number) => {
    if (number === 0) return "00";
    return number < 10 ? `0${number}` : `${number}`;
  };

  // component varialbles
  const [timeRemaining, setTimeRemaining] = useState(CalculateTimeRemaining());
  const [clock, setClock] = useState(calculateClock(timeRemaining));
  const [confettiExpirationTime, setConfettiExpirationTime] = useState(null);
  const [isConfettiActive, setConfettiActive] = useState(false);

  // life cycle hook
  useEffect(() => {
    // console.log("timeRemaining", timeRemaining);
    const timerInterval = setInterval(() => {
      setTimeRemaining((prevTime) => {
        let newTimeRemaining = parseInt(prevTime, 10);
        newTimeRemaining -= 1;

        setClock(calculateClock(newTimeRemaining));

        if (newTimeRemaining > 0) {
          setConfettiActive(true);
          setConfettiExpirationTime(new Date(targetDate.getTime() + 60 * 1000));
          // console.log("confettiExpirationTime", confettiExpirationTime);
          //   clearInterval(timerInterval);
        } else {
          setConfettiActive(false);
          clearInterval(timerInterval);
        }

        return newTimeRemaining;
      });
    }, 1000);

    return () => clearInterval(timerInterval);
  });

  return (
    <ConfettiWrapper isConfettiActive={isConfettiActive}>
      <div className="relative h-[48px] z-10">
        {/* <p>{timeRemaining}</p> */}
        <div className="overflow-hidden -z-10 absolute top-0 left-0 w-full h-full">
          <img src={bannerBG} className="w-full h-full object-cover" />
        </div>
        <div className="flex justify-center  wrapper items-center h-full">
          <div className="flex flex-col md:flex-row items-center justify-center font-krona text-[14px] lg:text-[20px] leading-[20px] tracking-[-0.03em] gap-x-[6px] gap-y-1 md:gap-y-0">
            <div className="flex gap-1">
              <Star color={color} />
              <p className="font-satoshi text-[16px] font-700 tracking-[0.16px]">
                AMBITION HIRE is all set to launch in
              </p>
            </div>
            <div className="flex gap-1 justify-start">
              {timeRemaining > 0 && (
                <div
                  className={`${textColor} font-krona text-[16px] tracking-[-0.48px]border-2`}
                >
                  {clock.days > 0 && <p>{clock.days} Days</p>}
                  {clock.days <= 0 && (
                    <p>
                      {clock.hours} Hours{" "}
                      <span className="w-7 inline-block">{clock.minutes}</span>{" "}
                      Minutes{" "}
                      <span className="w-7 inline-block"> {clock.seconds}</span>{" "}
                      Seconds
                    </p>
                  )}
                </div>
              )}
              <Star color={color} />
            </div>
          </div>
        </div>
      </div>
    </ConfettiWrapper>
  );
}

export default Header;
