import React from "react";

const TermsAndConditions = () => {
  return (
    <div class="flex flex-col container mx-auto py-12 w-full justify-center items-center max-w-[1135px] px-3 sm:px-6 lg:px-0 tracking-wider">
      <div class="flex bg-ambition-fandango-faded-color justify-center rounded-full p-2 border max-w-60">
        <h1 class="text-lg font-normal text-center text-blue-700 text-nowrap px-10 py-1">
          Terms and Conditions
        </h1>
      </div>
      <div class="mt-8 mb-10">
        <p class="text-2xl sm:text-4xl font-normal">Our terms of service</p>
      </div>
      {/*  CANDIDATE AGREEMENT  */}
      <div class="item-start w-full">
        <h2 class="text-xl sm:text-2xl font-700 mb-8 text-center text-ambition-fandango-color mt-8">
          CANDIDATE AGREEMENT
        </h2>
        <p class="text-xl mb-8 text-justify">
          This Candidate Agreement (hereinafter referred to as the “Agreement”)
          is being executed at Gurgaon by and between “M/s Ambition Hire Private
          Limited,” is a Company incorporated under the Companies Act 2013,
          having its registered office at 64F Professor Colony, Yamuna Nagar,
          Haryana -135001 (hereinafter referred to as the “Company”) and the
          “Candidate” being an individual(s) availing the services of the
          Company for seeking employment from the Recruiter (below defined).
        </p>
        <h2 class="text-2xl mb-8 font-500">
          ABOUT M/S Ambition Hire PRIVATE LIMITED
        </h2>
        <p class="text-xl mb-8 text-justify">
          The Company is engaged in the business of providing online services
          through which employers and staffing company seeking
          employees/independent contractors for themselves or their clients can
          proficiently and efficiently evaluate and interview individuals
          (hereinafter referred to as ‘Candidate’). Company has an online
          website www.Ambition Hire.ai (hereinafter referred to as the
          ‘Website’). Company provides suite of product and services features
          through its applications and Website subject to the terms and
          conditions as stated herein.
        </p>
        {/* Definitions */}
        <div class="mb-8">
          <h2 class="text-2xl mb-8  font-500">1. Definitions</h2>
          <p class="text-xl mb-8 text-justify">
            The following expressions shall have the following meanings in the
            Agreement defined below:
          </p>

          <ul class="list-disc pl-4 text-xl">
            <li class="mb-2 text-justify">
              <strong>Agreement:</strong> Candidate Agreement inclusive of all
              attachments and Annexures attached herewith deemed to be executed
              at Gurgaon.
            </li>
            <li class="mb-2 text-justify">
              <strong>Date of Activation:</strong> The date specified by the
              Company in its notice to the Candidates either through email
              indicating the approval to the Candidate registration and
              permission to access the Service.
            </li>
            <li class="mb-2 text-justify">
              <strong>Expiry Date:</strong> The expiry date of the notice of
              termination of the services.
            </li>
            <li class="mb-2 text-justify">
              <strong>Product:</strong> Products features through the Company
              Applications and Website inter alia.
            </li>
            <li class="text-justify">
              <strong>Services:</strong>
              <ul class="list-disc pl-4">
                <li class="mb-2 text-justify">
                  Candidate could post any and/or all information including
                  video interviews and portfolio shared or submitted through any
                  of the sources utilized by Company relating to his candidature
                  provided by him on Company website, mobile application, social
                  media pages, print media advertisements or such other
                  classified sections on the Website.
                </li>
                <li class="text-justify">
                  Candidate could receive information, advertisements,
                  newsletters and promotional messages on Website and through
                  email, telephonic/IVR calls.
                </li>
              </ul>
            </li>
            <li class="mb-2 text-justify">
              <strong>Registration Data:</strong> All the information and
              particulars of the Candidate requested on initial application and
              subscription, including but without limiting to the Candidate
              name, mailing address, email address, account and telephone
              number, video interviews and any information shared by the
              Candidate.
            </li>
            <li class="mb-2 text-justify">
              <strong>Recruiter:</strong> All those Companies registered on the
              website of the Company for seeking prospective employees or
              independent contractors/consultants.
            </li>
            <li class="text-justify">
              <strong>Website:</strong> The Internet web site of the Company at
              <a href="https://www.Ambitionhire.ai" class="text-blue-700">
                www.Ambitionhire.ai
              </a>
              .
            </li>
          </ul>
        </div>
        {/*                    General Terms                */}
        <div class="mb-8">
          <h2 class="text-2xl font-bold mb-8">2.General Terms</h2>

          <ul class="list-disc pl-4 text-xl">
            <li class="mb-2 text-justify">
              This Candidate hereby permits the Company to place any and/or all
              information including video interviews and profile; jointly called
              as video portfolio shared by the Candidate or submitted through
              any of the sources like mobile application and/or as part of the
              source mix campaigns-online and offline utilized by the Company;
              relating to the candidature of the Candidates provided by the
              Candidate on mobile application, social media pages, print media
              advertisements or such other classified sections on the Website or
              such other mirror or parallel site(s) or in allied publications as
              the Company may deem fit and proper and such other services as
              provided by the Company.
            </li>
            <li class="mb-2 text-justify">
              The Candidate, in order to avail the services and products, must
              register on the Website/mobile applications/kiosk and/or any other
              platform made available by the Company.
            </li>
            <li class="mb-2 text-justify">
              The Candidate shall not hold the Company liable for any
              use/misuse/stroke/ morphing/editing and alteration of any kind
              whatsoever of the profile/video recording once the same leaves the
              secured server of the Company.
            </li>
            <li class="mb-2 text-justify">
              The Company reserves the right to charge/modify subscription
              and/or membership fees in respect of any aspect of this
              Website/mobile applications/kiosk and/or any other platform made
              available by the Company without prior notice.
            </li>
            <li class="mb-2 text-justify">
              The Company reserves all the rights to terminate the Candidate
              account without any prior notice for any violation of this
              Agreement.
            </li>
            <li class="text-justify">
              The Company shall have exclusive right to use, cancel or delete
              any content including any profile or information or data fed by
              the Candidate without assigning any reason whatsoever from being
              published or reflected on its Website/mobile applications/kiosk
              and/or any other platform made available by the Company.
            </li>
            <li class="mb-2 text-justify">
              Candidate hereby agrees that they shall not use Company for the
              following acts or purpose:
              <ul class="list-disc pl-4">
                <li class="mb-2 text-justify">
                  Intentionally or unintentionally violating any applicable
                  local, state, national, or international law, or any rules or
                  regulations thereunder.
                </li>
                <li class="mb-2 text-justify">
                  Any unlawful activity thereby including illegal access to the
                  code repositories of the Company/Database of the Company.
                </li>
                <li class="mb-2 text-justify">
                  Disseminating or posting of harmful content including, but not
                  limited to viruses, Trojan horses, worms, spyware, or any
                  other computer programming routines that may damage, interfere
                  with, secretly intercept, or seize any system, program, data,
                  or personal information.
                </li>
                <li class="mb-2 text-justify">
                  Disseminating materials that are lewd, offensive,
                  inflammatory, harassing, threatening, defamatory, or otherwise
                  objectionable as determined by the Company in its sole
                  discretion.
                </li>
                <li class="mb-2 text-justify">
                  Posting or transmitting, or causing to be posted or
                  transmitted through Company:
                  <ul class="list-disc pl-4">
                    <li class="text-justify">any obscene matter or</li>
                    <li class="text-justify">sending spam messages or</li>
                    <li class="text-justify">
                      misuse of email services/any video
                    </li>
                    <li class="text-justify">
                      any business related advertisement other than permitted by
                      the Company.
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li class="mb-2 text-justify">
              All or any changes/modifications made by the Candidate to the
              data/information shall be effected and will come into operation
              only after at the earliest as deemed possible by the company. In
              case the Candidate data and video have already reached the login
              page of the Recruiter, then it will remain for that Recruiter
              without it getting affected by any update made by the Candidate on
              his profile for that Recruiter.
            </li>
            <li class="mb-2 text-justify">
              Duplication of information or uploading of multiple profiles by
              the same Candidate using the same or different accounts shall
              entitle Company to reserve the right to remove the profiles
              without notice to the Candidate. However, the Candidate can apply
              for multiple roles and hence can create separate video and profile
              for the same.
            </li>
            <li class="mb-2 text-justify">
              Candidates will not assign or transfer their rights or account
              login information to a third person or entity and shall prohibit
              other persons from using their account on the Company’s
              Website/mobile applications/kiosk and/or any other platform made
              available by the Company.
            </li>
            <li class="mb-2 text-justify">
              In case of any breach of security such as loss, theft,
              unauthorized disclosure or use of Product or Services or its login
              information; the Candidate will inform the same to the Company as
              soon as it comes to the knowledge of the Candidates.
            </li>
            <li class="mb-2 text-justify">
              The Candidates are solely responsible for the accuracy of the
              Registration Data given to the Company in the application at the
              time of registration and subsequent modification thereof in order
              to use the Products and Services.
            </li>
            <li class="mb-2 text-justify">
              The Candidates are responsible for the setup or configuration of
              all the necessary equipment required to access the Service except
              where it is specifically for a charge by the Company.
            </li>
            <li class="mb-2 text-justify">
              The Candidate will comply with all notices or instructions given
              by the Company from time to time in respect of the use of the
              Products and Services.
            </li>
            <li class="mb-2 text-justify">
              The Candidate shall be solely responsible for all information
              retrieved, stored, and transmitted through the Products and
              Services by it.
            </li>
            <li class="mb-2 text-justify">
              Company offers neither guarantee(s) nor warranty(s) that there
              would be a satisfactory response or any at all response once the
              job is put on display.
            </li>
            <li class="mb-2 text-justify">
              The Candidate shall not use/circulate/forward any Recruiter’s
              information on Company website/mobile applications/kiosk and/or
              any other platform made available by the Company to anyone.
            </li>
            <li class="mb-2 text-justify">
              Candidates will use the Service only for the purpose for which it
              is registered. Candidates will comply with all applicable laws and
              shall not contravene any applicable law of India relating to the
              Services, including any regulation made pursuant thereto.
            </li>
          </ul>
        </div>
        {/*             INTELLECTUAL PROPERTY RIGHTS                  */}
        <div class="mb-8">
          <h2 class="text-2xl font-bold mb-8">
            3. INTELLECTUAL PROPERTY RIGHTS
          </h2>
          <ul class="list-decimal pl-4 text-xl">
            <li class="mb-2 text-justify">
              The Company shall at all times retain all rights, title and
              interest in and to the Intellectual Property Rights contained in
              the Services, products and processes, including but not limited to
              all rights under applicable patents, copyrights, trademarks and
              trade secrets and all renewals and extensions thereto. The
              disclosure of any documents, data and other information in
              connection with this Agreement, including without limitation when
              such disclosure is made in fulfillment of a contractual obligation
              hereunder, shall not be construed as a grant or transfer of such
              rights.
            </li>
            <li class="mb-2 text-justify">
              Candidates will not breach any intellectual property rights of the
              Company or retain information in any computer system or otherwise
              with such intention.
            </li>
          </ul>
        </div>

        {/*             Termination of Service                  */}
        <div class="mb-8">
          <h2 class="text-2xl font-bold mb-8">4. Termination of Service</h2>
          <ul class="list-disc pl-4 text-xl">
            <li class="mb-2 text-justify">
              The Company may terminate this Agreement with immediate effect,
              without prior notice to the Candidate, if the Candidates have
              breached any of the terms or conditions of this Agreement.
            </li>
            <li class="text-justify">
              The Company’s right to termination is without prejudice to its
              right to have recourse legal remedies for any breach by the
              Candidate inter alia including non-payment for any product or
              services already availed by the Candidate.
            </li>
          </ul>
        </div>

        {/*             Disclaimer                 */}
        <div class="mb-8">
          <h2 class="text-2xl font-bold mb-8">5. Disclaimer</h2>
          <ul class="list-disc pl-4 text-xl">
            <li class="mb-2 text-justify">
              The Company hereby disclaims all warranties of any kind, whether
              express or implied, including, but not limited to, the implied
              warranties of merchantability, fitness for a particular purpose
              and/or non-infringement.
            </li>
            <li class="mb-2 text-justify">
              The Services are provided on an “as-is” and “as-available” basis.
              The Company makes no warranty and disclaims that the services will
              be uninterrupted, timely, secure, or error-free; or that defects
              will be corrected.
            </li>
            <li class="text-justify">
              The Company does not warrant, nor makes any representations
              regarding the use, or results of, any of the services it provides,
              in terms of their correctness, accuracy, reliability, or
              otherwise.
            </li>
          </ul>
        </div>
        {/*             Termination of Service                  */}
        <div class="mb-8">
          <h2 class="text-2xl font-bold mb-8">6. Indemnity</h2>
          <ul class="list-disc pl-4 text-xl">
            <li class="text-justify">
              The Candidate agrees to indemnify and hold Company, its
              subsidiaries, affiliates, officers, agents, and other partners and
              employees, harmless from any loss, liability, claim, or demand,
              including reasonable attorney's fees, made by any third party due
              to or arising out of the Candidate’s use of the Services in
              violation of this Agreement and/or arising from a breach of these
              Terms of Use and/or any breach of representations and warranties
              set forth above.
            </li>
          </ul>
        </div>
        {/*             Confidentiality                  */}
        <div class="mb-8">
          <h2 class="text-2xl font-bold mb-8">7. Confidentiality</h2>
          <ul class="list-disc pl-4 text-xl">
            <li class="text-justify">
              The Parties shall treat as strictly confidential any information
              pertaining to this Agreement, including the document itself as
              well as any individual data obtained while providing services as
              required under this Agreement (“Confidential Information”).
            </li>
            <li class="text-justify">
              Confidential Information shall include, but not be limited to, any
              business, technical and strategic data disclosed by the other
              Party at any time for any reason - comprising of all such
              information in oral or visual form.
            </li>
            <li class="text-justify">
              Neither Party shall disclose any Confidential Information to any
              employee, except where such disclosure is necessary in order to
              fulfill the obligations under this Agreement. Either Party may
              disclose Confidential Information to its certified accountants and
              attorneys, such persons to be instructed to adhere to the terms of
              this clause and as required by applicable law.
            </li>
            <li class="text-justify">
              Any Party receiving Confidential Information from the other Party
              in connection with this Agreement (“Beneficiary”) shall not be
              bound by the confidentiality obligations under this clause if the
              Beneficiary can prove that the Confidential Information:
              <ul class="list-disc pl-4">
                <li class="text-justify">
                  was already known to him at the time it received said
                  information without any obligation of confidentiality;
                </li>
                <li class="text-justify">
                  was already known to the public prior to the date the
                  Beneficiary received said information;
                </li>
                <li class="text-justify">
                  has become known to the public after the date the Beneficiary
                  received said information, except if the Beneficiary is the
                  originator of the publication of said information;
                </li>
                <li class="text-justify">
                  has been received from a third party before such disclosure
                  without any obligation of confidentiality thereon must be
                  disclosed because of mandatory law or a binding and final
                  order of a competent court or authority, in which case the
                  Beneficiary will immediately inform the other Party about such
                  mandatory disclosure and limit the disclosure to the extent
                  legally required.
                </li>
              </ul>
            </li>
          </ul>
        </div>
        {/*             Governing Law and Jurisdiction                  */}
        <div class="mb-8">
          <h2 class="text-2xl font-bold mb-8">
            8. Governing Law and Jurisdiction
          </h2>
          <p class="pl-4 text-xl">
            This Agreement and any dispute or matter arising therefrom is
            governed by the laws of India and the Parties agree to submit to the
            exclusive jurisdiction of the courts at Gurgaon, India.
          </p>
        </div>
        {/*            SEVERABILITY             */}
        <div class="mb-8">
          <h2 class="text-2xl font-bold mb-8">9. SEVERABILITY</h2>
          <p class="pl-4 text-xl">
            If any provision of this Agreement is held invalid, by any competent
            court of law having jurisdiction to adjudicate upon the matter, for
            any reasons whatsoever, the remainder clauses of this Agreement
            shall be valid and shall be enforceable between the Parties
          </p>
        </div>
      </div>
      {/*  EMPLOYER AGREEMENT  */}
      <div class="item-start w-full">
        <h4 class="text-4xl font-medium mb-8 text-center text-ambition-fandango-color mt-8">
          EMPLOYER AGREEMENT
        </h4>
        <p class="text-xl mb-8 text-justify">
          This Employer Product Service Contract (“Contract”) is being entered
          between M/s Ambition Hire Private Limited having its registered office
          at 64F Professor Colony, Yamuna Nagar, Haryana -135001 (hereinafter
          referred to as “Ambition Hire” / “the Company”) and “Employer” M/s
          Company Name having its registered office at Registered Address,
          (hereinafter referred to as “Client”/ “Employer”) <br />
          <br />T These terms of Service (“Terms”) shall apply to the products
          and services (“Services”) provided by Ambition Hire through its online
          site i.e. www.Ambition Hire.ai (“Website”), mobile applications, kiosk
          and/or any other platform made available by the Company.
        </p>
        {/*                    REGISTRATION                 */}
        <div class="mb-8">
          <h2 class="text-2xl font-bold mb-8">1. Registration</h2>
          <ul class="list-disc pl-4 text-xl">
            <li class="text-justify mb-2">
              The Employer shall register on the Website, mobile applications,
              kiosk and/or any other platform made available by Ambition Hire
              and/or the Employer to use the Ambition Hire Software as a Service
              or access the Services as provided by Ambition Hire.
            </li>
            <li class="text-justify mb-2">
              The Employer hereby acknowledges and agrees that it has read all
              the Terms and agrees to be legally bound upon registration by such
              Terms as stated herein.
            </li>
            <li class="text-justify mb-2">
              In the event of any employee/nominee of the Employer registering
              on behalf of the Employer, the Employer hereby represents that
              such employee/nominee has the authority to so represent the
              Employer and accept the Terms as stated herein and that the
              Employer shall be bound by such acts of its employees/nominees.
            </li>
            <li class="text-justify mb-2">
              The Employer hereby agrees to:
              <ul class="list-disc pl-4">
                <li class="text-justify mb-2">
                  provide accurate, truthful, and complete information when
                  creating an account.
                </li>
                <li class="text-justify mb-2">
                  maintain and promptly update account information.
                </li>
                <li class="text-justify mb-2">
                  maintain the security of its account by not sharing password
                  with others and restricting access to its account.
                </li>
                <li class="text-justify mb-2">
                  take responsibility for all activities that occur under the
                  account and accept all risks of unauthorized access.
                </li>
              </ul>
            </li>
            <li class="text-justify mb-2">
              The Employer shall upload/share all job-related content including
              text Job-Description, company details, videos, one-way video job
              description, profile etc., via Ambition Hire Website, mobile
              applications, kiosk and/or any other platform made available by
              Ambition Hire and/or deployed by Employer to access the Ambition
              Hire platform.
            </li>
          </ul>
        </div>

        {/*                   Description of Services               */}
        <div class="mb-8 text-justify">
          <h2 class="text-2xl font-bold mb-8">Description of Services</h2>
          <ul class="list-disc pl-4 text-xl">
            <li class="mb-2">
              Ambition Hire shall inter alia provide the following Services to
              the Employer:
              <ul class="list-disc pl-4 mb-2">
                <li>
                  <strong>Support for Sourcing:</strong> Ambition Hire shall
                  provide support to source (not directly source)
                  Profile-for-Success that includes multiple candidate details
                  including but not limited to profile/CV, candidate social
                  profile, one-way video interview, other interview related
                  interactions, associated analytics and relevancy ratings of
                  the job seekers/prospective employees to arrive at
                  accept/reject scenarios for the Employer. The Ambition Hire
                  support for sourcing is available on a pre-agreed and
                  chargeable basis post project kick-off and includes any or all
                  the services mentioned below:
                  <ul class="list-disc pl-4 mb-2">
                    <li>
                      Careers page & ATS Integration with Ambition Hire product
                    </li>
                    <li>
                      Workshop to build online marketing plan to increase
                      traffic on career website/ company social media pages
                    </li>
                    <li>
                      Support in online/offline campaigns to market Employer
                      website and/or specific JDs
                    </li>
                    <li>Recording of one way video JDs</li>
                    <li>
                      Recruitment kiosk to assist in recruitment drives/walk-ins
                    </li>
                    <li>Digital-Reach to source from remote locations</li>
                    <li>
                      Multiple recruitment consultant/internal recruiters
                      log-ins to support quality recruitment
                    </li>
                    <li>
                      Manual upload of candidate data for them to receive
                      6-digit code via SMS, email, IJPs, referrals etc.
                    </li>
                    <li>Any other source</li>
                  </ul>
                </li>
                <li>
                  The Employer can undertake Ambition Hire support to source
                  job-seekers or choose to do it on their own. In case, Ambition
                  Hire support for sourcing is undertaken, then the associated
                  fee for such services will be mutually agreed on an email, a
                  copy of which will be attached to this Contract and will be
                  binding on both parties.
                </li>
                <li>
                  <strong>Filtering (Pre-Screening):</strong> Ambition Hire
                  product provides Employers with filtering of
                  candidates/job-seekers profiles including video portfolios
                  from its machine learning, social profile mapping, video
                  analytics & artificial intelligence engine that is offered as
                  a SAAS (Software as a Service) platform. The filtering on the
                  platform enables creation of select/reject scenarios for the
                  Employer and translates into more efficient and faster
                  identification of relevant profiles. This provides a solution
                  to the following two critical problems for the Employer:
                  <ul class="list-disc pl-4 mb-2">
                    <li>
                      Remove/reduce disconnect between hiring decisions and
                      outcomes;
                    </li>
                    <li>
                      Reduce high cost and time to hire because of cumbersome
                      process involving multiple points of manual intervention;
                    </li>
                  </ul>
                </li>
                <li>
                  In order to realize the full potential of the Ambition Hire
                  Product, it is incumbent on the Employer to undertake most if
                  not all of the activities as highlighted in Section 2.1(a) or
                  additional activities to enable realization of the full
                  potential of the Ambition Hire Product, whether undertaken
                  with Ambition Hire support or otherwise.
                </li>
                <li>
                  Employer fully understands that, for Ambition Hire product to
                  work efficiently and effectively the Employer
                  (Employees/Nominees) is required to put in adequate time,
                  attention, training & dissemination including any product
                  adoption activity suggested by Ambition Hire.
                </li>
                <li>
                  Once the video portfolios and profiles are uploaded, the
                  Employer can use Ambition Hire Services for short-listing the
                  candidates as per its preference.
                </li>
                <li>
                  It should be understood that, the Ambition Hire
                  Product/Service is not equivalent to background verification
                  of candidate data, and as such, Ambition Hire shall not be
                  liable for any misrepresentation of data shared by the
                  candidate.
                </li>
              </ul>
            </li>
            <li>
              <strong>Scheduling:</strong> Ambition Hire product and chatbots
              will allow functionality of scheduling face to face and/or
              telephonic interviews between job seeker/prospective employees and
              the Employer via Ambition Hire through any/all its platforms.
            </li>
            <li>
              <strong>Personality Report:</strong> If required by the Employer,
              Ambition Hire shall provide Personality Reports for its existing
              employees, wherein the Employer shall provide video pitches or
              recordings of target employees through any format (Text and/or
              digital) form and the detailed personality reports of such
              employees shall be generated.
              <ul class="list-disc pl-4 mb-2">
                <li>
                  The validity to consume the Personality Reports will be for a
                  period of 3 months from the date of issuing the invoice for
                  the requested reports by the Client to Ambition Hire via email
                  from the Client’s authorized personnel. The payment received
                  for any reports that remain unconsumed beyond the validity
                  period will not be liable to be delivered by Ambition Hire.
                </li>
              </ul>
            </li>
          </ul>
        </div>

        {/*             AMENDMENT AND ALTERATION TO TERMS  */}
        <div class="mb-8">
          <h2 class="text-2xl font-bold mb-8">
            3. Amendment and Alternation to Terms
          </h2>
          <p class="pl-4 text-xl">
            Ambition Hire reserves the right to make any alterations,
            amendments, changes or modifications to the existing Terms contained
            herein without prior notice to the Employer for enhancing the
            delivery of its Product/Service to all its Clients. The Employer can
            be rest assured that all such product modifications/version-updates
            will add to the overall Client experience.
          </p>
        </div>
        {/*             LIABILITY       */}
        <div class="mb-8 text-justify">
          <h2 class="text-2xl font-bold mb-8">4. Liability</h2>
          <ul class="list-disc pl-4 text-xl">
            <li class="mb-2">
              Ambition Hire is not liable for any conduct/misconduct on part of
              the job seeker/prospective employee prior to/after his/her joining
              the Employer.
            </li>
            <li>
              Ambition Hire shall not be liable to the Employer for any
              direct/indirect, incidental, consequential, or punitive damages
              whatsoever, arising out of or related to this Contract.
            </li>
          </ul>
        </div>

        {/*            Conduct and Use of Services                */}
        <div class="mb-8 text-justify">
          <h2 class="text-2xl font-bold mb-8">
            5. Conduct and Use of Services
          </h2>
          <ul class="list-disc pl-4 text-xl">
            <li class="mb-2">
              The Employer shall be solely responsible for its conduct and the
              conduct of any representatives/nominees who use the Ambition Hire
              Services on its behalf, including interactions with job
              seekers/prospective employees.
            </li>
            <li class="mb-2">
              The Employer shall not transmit or otherwise make available any
              data, files, software, or other materials that are harmful,
              disruptive, or invasive, including any viruses, malware, adware,
              worms, or any other unauthorized programming on the Ambition Hire
              product.
            </li>
            <li class="mb-2">
              The Employer shall not use the Services for any illegal or
              unauthorized purpose or engage in, encourage, or promote any
              illegal activity, or any activity that violates these Terms.
            </li>
            <li class="mb-2">
              The Employer shall not attempt to gain unauthorized access to the
              Services or related systems or networks.
            </li>
            <li class="mb-2">
              The Employer shall not use the Services to store or transmit
              infringing, libelous, or otherwise unlawful or tortuous material,
              or to store or transmit material in violation of the rights of
              Ambition Hire or any third party.
            </li>
            <li class="mb-2">
              The Employer shall not impersonate another person by using a name,
              likeness, or background information that is not its own,
              misrepresent its affiliation with a person or entity, or otherwise
              publish inaccurate information about itself.
            </li>
            <li class="mb-2">
              The Employer shall not disclose to any third party any information
              or analysis relating to the Services provided by Ambition Hire.
            </li>
            <li>
              The data of Profiles-for-Success including video portfolios,
              profiles, social profiles, and associated analytics will be hosted
              on our cloud servers. The infrastructure and processing cost
              associated with the database of profiles will be provided by
              Ambition Hire free of cost to the Employer for a period of 15 days
              post-upload of such data. Ambition Hire will provide the entire
              functionality of the product alongside storage at an additional
              charge over and above the prescribed agreed period (Charges
              detailed in Annexure 5). This charge will be on a cumulative
              accumulation basis to account for each previous month’s product
              storage and product functionality benefits delivered to the
              client. Ambition Hire will have limited access to the data
              uploaded via the Employer's efforts until the Employer continues
              to pay for the requisite charges for the services, post which
              Ambition Hire may choose to bear the cost of storage, in which
              case it shall gain full access and right to the data.
            </li>
          </ul>
        </div>

        {/*             PRICING, PAYMENTS AND BILLING                 */}
        <div class="mb-8">
          <h2 class="text-2xl font-bold mb-8">
            6. Pricing, Payments and Billing
          </h2>

          <p class="mb-2 text-xl">
            The Ambition Hire product Provided to the Employer as an Enterprise
            License as detailed AT THE TIME OF CLOSURE/ MENTIONED IN PRICING
            SECTION. In addition to the license cost, Ambition Hire also
            provides certain Ancillary services for which the pricing can be
            confirmed from Ambition Hire Team. The pricing will vary for product
            deployment across countries.
          </p>
          <ul class="list-disc pl-4 text-xl">
            <li class="mb-2">
              <ul>
                <strong>Narration for Filtering & Scheduling :</strong>
                <ul class="list-disc pl-4 mt-2">
                  <li class="mb-2 text-justify">
                    For Filtering (Pre-screening) of job-seekers profiles and
                    Scheduling of interviews for Employer the applicable fee
                    will be charged based on a monthly license fee that includes
                    a certain number of Profile Uploads with/without personality
                    reports as the case may be. Further, for every upload beyond
                    the threshold limit there will be an additional charge
                  </li>
                  <li class="text-justify">
                    The Ambition Hire Kiosks will be provided at the Employers
                    office location or any designated location as identified by
                    the Employer. The cartage of sending and picking up the
                    kiosks from the Clients office location will be borne by the
                    Client from the nearest Ambition Hire base location of Delhi
                    NCR, Bangalore or Mumbai, on actuals plus 10%. Also, the
                    Kiosks will be fitted with 4G sim card and the
                    weekly/monthly rentals for the same will be charged to the
                    Client on actuals plus 10%. The cost of signage on the Kiosk
                    specific to the Client, with respect to the design shared by
                    the Client, shall be charged over and above the rentals on
                    actual cost basis. The cost of electricity to power the
                    Kiosk including lights, fan and computer tablet will be
                    borne by the Employer. Also, the physical security or any
                    loss/theft of the Kiosk will be the responsibility of thee
                    Employer.
                  </li>
                </ul>
              </ul>
            </li>
            <li class="mb-2">
              <ul>
                <strong>Narration for Personality reports :</strong>
                <ul class="list-disc pl-4 mt-2">
                  <li class="mb-2 text-justify">
                    If required, Ambition Hire will provide Personality Reports
                    via video pitch inputs for Clients employees/ prospective
                    employees / stakeholders etc.; at a fee based on transaction
                    volume per personality report per person based on a video
                    portfolio input by the person. <br />
                    The batch of personality reports translates into a mandate
                    given in one lot for a specified period, not extending 30
                    (thirty) days’ post commencement of work. The responsibility
                    of providing the video portfolio input will be on the
                    Employer. The Personality Reports extracted from a video
                    pitch are the most efficient and effective way to identify
                    the key attributes of employees/prospective employees to
                    arrive at various decisions including but not limited to:
                    Appraisal, Learning & Development, making effective project
                    teams, Career progression of employees etc.
                  </li>
                </ul>
              </ul>
            </li>
            <li class="mb-2">
              <ul>
                <strong>
                  Narration for Project Adoption and ongoing support :
                </strong>
                <ul class="list-disc pl-4 mt-2">
                  <li class="mb-2 text-justify">
                    The Employer will receive a benefit of up-to 30 days (or
                    lesser) of free adoption time from the Ambition Hire
                    operations team that includes product training, setting
                    governance matrices, escalation metrics and overall support
                    for Employers recruitment team to activate and implement the
                    usage of Ambition Hire product across the entire source mix.
                  </li>
                  <li class="mb-2 text-justify">
                    Post the adoption period of 30 days (or lesser), as agreed
                    with the Employer, Ambition Hire will charge an adoption fee
                    to support various high phase recruitment requirements
                    during the Employers recruitment process.
                  </li>
                  <li class="mb-2 text-justify">
                    The Employer sourced job seekers can make call on a helpline
                    number to get requisite telephonic/email/chat based help to
                    upload profiles and remove any concerns/queries that may
                    arise during the interview process. This will currently be
                    available at NIL cost, but later will be charged basis a
                    small fee applicable with respect to the call volume
                    received by Ambition Hire on behalf of the Employer. The
                    same will be agreed on an addendum to this Contract as and
                    when the need arises.
                  </li>
                </ul>
              </ul>
            </li>

            <li class="mb-2">
              Ambition Hire will commence providing services only upon receipt
              of amount/charges upfront from the Employer.
            </li>
            <li class="mb-2">
              Any other combination of services/product requirements
              /customization requests will be undertaken as part of an addendum
              to this Contract.
            </li>
            <li class="mb-2">
              All out of pocket expenses Other than the reasonable norms of
              deliverance of services stated or otherwise in this Contract
              including but not limited to expenses such as travel and
              conveyance, hotel, secretarial support and expenses incurred in
              connection with the Employer will be in addition to our
              professional fees and will be charged based on actuals as incurred
              on such account.
            </li>
            <li>
              The invoices and payments will be payable by the Employer to
              Ambition Hire
              <ul class="list-disc pl-4 mt-2">
                <li class="mb-2 text-justify">
                  Ambition Hire shall raise an invoice to the Employer at the
                  time of Employer providing the mandate for rendering of any of
                  the Services mentioned in the Contract.
                </li>
                <li class="mb-2 text-justify">
                  The Employer is required to pay for the invoice to Ambition
                  Hire, 100% as initial payment at the time of providing mandate
                  for rendering the services or pay online whichever is
                  appropriate and suitable. Without payment of the fees,
                  Ambition Hire is not obligated to start providing any of its
                  services to the Employer.
                </li>
              </ul>
            </li>
            <li class="mb-2">
              Ambition Hire shall raise an invoice To the Employer at the time
              of Employer providing the mandate for rendering of any of the
              Services mentioned in the Contract. The Employer is required to
              pay for the invoice to Ambition Hire, 100% as initial payment at
              the time of providing mandate for rendering the services or pay
              online whichever is appropriate and suitable. Without payment of
              the fees, Ambition Hire is not obligated to start providing any of
              its services to the Employer.
            </li>
            <li class="mb-2">
              All invoices raised by Ambition Hire Shall attract Service Tax as
              applicable/levied by the Government of India from time to time on
              the billing amount. The Service Tax is payable by the Employer as
              part of the invoice to Ambition Hire and it will be billed
              exclusive of the fee for the Services as mentioned in this
              Contract.
            </li>
            <li class="mb-2">
              The Employer shall be entitled to deduct TDS As per the applicable
              provisions on the fees for Services invoiced by Ambition Hire and
              shall issue necessary TDS certificate for such tax deducted as per
              applicable laws.
            </li>
            <li class="mb-2">
              If the cheque issued by the Employer is returned or dishonored Or
              in case of a chargeback on an online transaction (including credit
              card payment), Services will be immediately deactivated. In cases
              of cheques dishonored, Ambition Hire shall be entitled to pursue
              legal action and avail legal remedies available under the law for
              the time being in force.
            </li>
          </ul>
        </div>
        {/*           TERM AND TERMINATION                */}
        <div class="mb-8">
          <h2 class="text-2xl font-bold mb-8">7. Term and Termination</h2>
          <p class="pl-4 text-xl text-justify">
            The parties hereby agree that the term of the Contract shall be for
            a period of 7 years starting w.e.f the date of signing and/or
            execution of this Contract. In the event that the parties agree to
            renew the Contract, such renewals shall be on such terms and
            conditions as mutually agreed between both parties.
            <br />
            Unless terminated earlier as permitted below, the term of this
            Contract will be for the Subscription Period, and thereafter, this
            Contract will automatically renew for additional periods equivalent
            in length to the initial Subscription Period, unless either party
            gives notice of its desire to not renew prior to the end of the
            applicable Term. Either party may terminate this Contract, if the
            other party breaches the Terms of this Contract and does not remedy
            such failure within 180 days (except non-payment by the Employer, in
            which case Ambition Hire may terminate this Contract immediately)
            after receipt of such notice of breach. Further, if the Employer has
            not paid Ambition Hire for the use of the Services, Ambition Hire
            may terminate this Contract for non-payment by issuing notice to the
            employer without prejudice to its right to take appropriate legal
            action including the pursuit of civil, criminal, or injunctive
            redress against the Employer. Ambition Hire may recover its
            attorney’s fees (reasonable extent) and legal costs from the
            Employer for such actions.
          </p>
        </div>
        {/*          INTELLECTUAL PROPERTY   */}
        <div class="mb-8">
          <h2 class="text-2xl font-bold mb-8">8. Intellectual Property</h2>
          <p class="pl-4 text-xl text-justify">
            Ambition Hire owns all right, title and interest in and to (a) the
            Services, (b) trademarks, logos, and any other product, service
            name, or slogan included in the Services, (c) the Feedback, (d) the
            Anonymous Usage Data, (e) all information, materials, and content
            (including text, graphics, data, formatting, graphs, designs, HTML,
            look and feel, photographs, music, sounds, images, software, videos,
            designs, typefaces, source and object code, format, queries,
            algorithms, and other content) of the Services, and (f) any and all
            intellectual property rights embodied therein or related thereto
            (collectively, the “Ambition Hire Material”). The Employer is not
            granted any rights in or to the Services by implication, estoppel,
            or other legal theory, and all rights in and to the Services not
            expressly granted in these Terms are hereby reserved and retained by
            Ambition Hire.
          </p>
        </div>

        {/*            INDEMNITY                  */}
        <div class="mb-8">
          <h2 class="text-2xl font-bold mb-8">9. Indemnity</h2>
          <p class="pl-4 text-xl">
            The Employer agree to indemnify and hold Company, its subsidiaries,
            affiliates, officers, agents, and other partners and employees,
            harmless from any loss, liability, claim, or demand, including
            reasonable attorney's fees, made by any third party due to or
            arising out of the Job Seeker’s use of the Services in violation of
            this Contract and/or arising from a breach of these Terms of Use
            and/or any breach of representations and warranties set forth above.
          </p>
        </div>
        {/*            DISCLAIMER             */}
        <div class="mb-8">
          <h2 class="text-2xl font-bold mb-8">10. Desclaimer</h2>
          <ul class="list-disc pl-4 text-xl">
            <li class="mb-2">
              The Company hereby disclaims all warranties of any kind, whether
              express or implied, including, but not limited to, the implied
              warranties of merchantability, fitness for a particular purpose
              and/or non-infringement
            </li>
            <li>
              The Services are provided on an “as-is” and “as-available” basis.
              The Company makes no warranty and disclaims that the services will
              be uninterrupted, timely, secure, or error free; or that defects
              will be corrected on a routine basis.
            </li>
            <li>
              The Company does not warrant, nor makes any representations
              regarding the use, or results of, any of the services it provides,
              in terms of their correctness, accuracy, reliability, or
              otherwise.
            </li>
          </ul>
        </div>
        {/*            Confidentiality             */}

        <div class="mb-8 text-justify">
          <h2 class="text-2xl font-bold mb-8">11. Confidentiality</h2>
          <ul class="list-disc pl-4 text-xl">
            <li class="text-xl mb-2">
              The Parties shall treat as strictly confidential any information
              pertaining to this Contract, including the document itself as well
              as any individual data obtained while providing services as
              required under this Contract (“Confidential Information”).
            </li>
            <li class="text-xl mb-2">
              Confidential Information shall include, but not be limited to, any
              business, technical and strategic data disclosed by the other
              Party at any time for any reason - comprising of all such
              information in oral or visual form.
            </li>
            <li class="text-xl mb-2">
              Neither Party shall disclose any Confidential Information to any
              employee, except where such disclosure is necessary in order to
              fulfill the obligations under this Contract. Either Party may
              disclose Confidential Information to its certified accountants and
              attorneys, such persons to be instructed to adhere to the terms of
              this clause and as required by applicable law.
            </li>
            <li class="text-xl mb-2">
              Any Party receiving Confidential Information from the other Party
              in connection with this Contract (“Beneficiary”) shall not be
              bound by the confidentiality obligations under this clause if the
              Beneficiary can prove that the Confidential Information:
            </li>
            <ul class="list-disc pl-4 text-xl">
              <li class="mb-2">
                was already known to it at the time it received said information
                without any obligation of confidentiality.
              </li>
              <li class="mb-2">
                was already known to the public prior to the date the
                Beneficiary received said information.
              </li>
              <li class="mb-2">
                has become known to the public after the date the Beneficiary
                received said information, except if the Beneficiary is the
                originator of the publication of said information.
              </li>
              <li>
                has been received from a third party before such disclosure
                without any obligation of confidentiality thereon must be
                disclosed because of mandatory law or a binding and final order
                of a competent court or authority, in which case the Beneficiary
                will immediately inform the other Party about such mandatory
                disclosure and limit the disclosure to the extent legally
                required.
              </li>
            </ul>
          </ul>
        </div>
        {/*        Governing Law and Jurisdiction           */}
        <div class="mb-8 text-justify">
          <h2 class="text-2xl font-bold mb-8">
            12. Governing Law and Jurisdiction
          </h2>
          <p class="text-xl">
            This Agreement and any dispute or matter arising therefrom are
            governed by the laws of India, and the Parties agree to submit to
            the exclusive jurisdiction of the courts at Gurgaon, India.
          </p>
        </div>
        {/*       Severability           */}
        <div class="mb-8 text-justify">
          <h2 class="text-2xl font-bold mb-8">13. Severability</h2>
          <p class="text-xl">
            If any provision of this Agreement is held invalid by any competent
            court of law having jurisdiction to adjudicate upon the matter, for
            any reasons whatsoever, the remainder clauses of this Agreement
            shall be valid and shall be enforceable between the Parties.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
