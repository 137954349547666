import React from "react";
import { PageLayout, RevealAnimation } from "../../ui/wrappers";
import { Helmet } from "react-helmet";
import VideoInterviewMain from "../../components/videoInterview/videoInterviewMain";
import VideoInterviewSection2 from "../../components/videoInterview/videoInterviewSection2";
import VideoInterviewSection3 from "../../components/videoInterview/videoInterviewSection3";

function VideoInterviewPage() {
  const gradient =
    "linear-gradient(115.03deg, rgba(58, 189, 181, 0.08) 17.08%, rgba(255, 255, 255, 0.08) 39.71%, rgba(58, 189, 181, 0.16) 63.92%)";
  const color = "#3ABDB5";
  return (
    <PageLayout>
      {/* <ProductsMain/> */}
      <Helmet>
        <title>
          Online Video Interview Platform | Top Virtual Interview Tools.
        </title>
        <meta
          name="description"
          content="Video interview platform offers seamless integration, automatic interview capture, and advanced candidate evaluation features to ensure efficient and effective remote hiring."
        />
        <link
          rel="canonical"
          href="https://ambitionhire.ai/video-interview-platform"
        />
      </Helmet>
      <RevealAnimation>
        <VideoInterviewMain gradient={gradient} color={color} />
      </RevealAnimation>
      <RevealAnimation>
        <VideoInterviewSection2 gradient={gradient} color={color} />
      </RevealAnimation>
      <RevealAnimation>
        <VideoInterviewSection3 gradient={gradient} color={color} />
      </RevealAnimation>
    </PageLayout>
  );
}

export default VideoInterviewPage;
