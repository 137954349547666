import React from "react";
import { PageLayout, RevealAnimation } from "../../ui/wrappers";
import { Helmet } from "react-helmet";
import OnlineAssessmentSection2 from "../../components/onlineAssessment/onlineAssessmentSection2";
import OnlineAssessmentSection3 from "../../components/onlineAssessment/onlineAssessmentSection3";
import OnlineAssessmentMain from "../../components/onlineAssessment/onlineAssessmentMain";

function OnlineAssessmentPage() {
  const gradient =
    "linear-gradient(115.03deg, rgba(140, 181, 108, 0.18) 17.08%, rgba(255, 255, 255, 0.08) 39.71%, rgba(140, 181, 108, 0.24) 63.92%)";
  const color = "#8CB56C";
  return (
    <PageLayout>
      {/* <ProductsMain/> */}
      <Helmet>
        <title>
          Top Online Assessment Platform for Hiring | Online Assessment Tools
        </title>
        <meta
          name="description"
          content="Explore our comprehensive online assessment platform for hiring, featuring tests for jobs, candidate evaluation tools, and personality assessments."
        />
        <link
          rel="canonical"
          href="https://ambitionhire.ai/online-assessment-platform"
        />
      </Helmet>
      <RevealAnimation>
        <OnlineAssessmentMain gradient={gradient} color={color} />
      </RevealAnimation>
      <RevealAnimation>
        <OnlineAssessmentSection2 gradient={gradient} color={color} />
      </RevealAnimation>
      <RevealAnimation>
        <OnlineAssessmentSection3 gradient={gradient} color={color} />
      </RevealAnimation>
    </PageLayout>
  );
}

export default OnlineAssessmentPage;
