import React, { useEffect, useState } from "react";
import { getBlogs } from "../../service/api";
import { GradientSection } from "../../ui/wrappers";
import { useNavigate } from "react-router-dom";
import DOMPurify from "dompurify";
import "./style.css";
import blogImage from "../../asset/blog/blog.png";

function BlogSection2({ blog }) {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const fetchedBlogs = await getBlogs();

        setBlogs(fetchedBlogs?.details?.slice(0, 3));
      } catch (error) {
        console.error("Error fetching blogs:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }
  const preprocessHtml = (html) => {
    // Replace empty <p></p> tags with <br />
    return html.replace(/<p><\/p>/g, "<br />");
  };

  const createMarkup = (html) => {
    const sanitizedHtml = DOMPurify.sanitize(html);
    return { __html: preprocessHtml(sanitizedHtml) };
  };
  const handleViewAllClick = () => {
    navigate("/blogs");
  };
  const handleBlogClick = (id) => {
    scrollToTop();
    navigate(`/blog/${id}`);
  };
  return (
    <GradientSection>
      <div
        className="relative -top-32 lg:-top-64 border py-12 px-8 flex flex-col gap-8 rounded-[16px] bg-white"
        style={{ boxShadow: "0px 16px 36px 0px #5A5A5A14" }}
      >
        {blog ? (
          blog.description ? (
            <div
              className="text-sm lg:text-xl leading-[18.9px] lg:leading-[34px] text-left text-primary-light"
              dangerouslySetInnerHTML={createMarkup(blog.description)}
            />
          ) : (
            <p className="text-sm lg:text-xl leading-[18.9px] lg:leading-[34px] text-left text-primary-light">
              No Blog Description Available
            </p>
          )
        ) : (
          <p className="text-sm lg:text-xl leading-[18.9px] lg:leading-[34px] text-left text-primary-light">
            Loading...
          </p>
        )}
      </div>
      <div className="flex justify-between items-center my-8 -mt-24">
        <h2 className="text-2xl font-normal font-krona">Also Read</h2>
        <button
          className="text-[#3883C8] underline"
          onClick={handleViewAllClick}
        >
          View All
        </button>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-32 h-[300px]">
        {blogs.map((blog, index) => (
          <div
            key={index}
            className="rounded-[16px] p-6 border-2 cursor-pointer"
            onClick={() => handleBlogClick(blog.id)}
          >
           <div className=" w-full h-[164px]  rounded-md bg-[#D9D9D9]">
              {blog && blog.file_url ? (
            <img
              src={blog.file_url}
              alt={blog.title}
              className="w-full h-full object-cover"
            />
          ) : (
            <img
              src={blogImage}
              alt="Default blog"
              className=" w-full object-cover"
            />
          )}
              </div>
              <p className="font-normal font-krona text-[18px] leading-[20px] sm:leading-[22.5px] mt-4 overflow-hidden text-ellipsis"
                 style={{
                   display: '-webkit-box',
                   WebkitLineClamp: 2,
                   WebkitBoxOrient: 'vertical'
                 }}>
                {blog.title}
              </p>
          </div>
        ))}
      </div>
    </GradientSection>
  );
}

export default BlogSection2;
