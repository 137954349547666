import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaChevronDown } from 'react-icons/fa';

const MultiDropdown = ({ options, selectedOptions, setSelectedOptions }) => {
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    const toggleOption = (option) => {
        setSelectedOptions((prevSelected) =>
            prevSelected.includes(option)
                ? prevSelected.filter((item) => item !== option)
                : [...prevSelected, option]
        );
    };

    const toggleAllOptions = () => {
        // If "Select All" is already selected, unselect all; otherwise, select all
        const allOptionsSelected = selectedOptions.length === options.length;
        setSelectedOptions(allOptionsSelected ? [] : options);
    };

    const toggleDropdown = () => {
        setDropdownOpen(!isDropdownOpen);
    };

    const closeDropdown = () => {
        setDropdownOpen(false);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                closeDropdown();
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <div className="relative inline-block text-left w-full" ref={dropdownRef}>
            <div>
                <button
                    type="button"
                    className="inline-flex justify-between items-center w-full focus:outline-none gap-10"
                    onClick={toggleDropdown}
                >
                    <p className='overflow-scroll scrollbar-hide'>
                        {selectedOptions.length > 0 && selectedOptions.length < options.length
                            ? selectedOptions.join(', ')
                            : 'Assessment'}
                    </p>
                    <motion.div
                        initial={{ rotate: 0 }}
                        animate={{ rotate: isDropdownOpen ? 180 : 0 }}
                        transition={{ duration: 0.3 }}
                    >
                        <FaChevronDown />
                    </motion.div>
                </button>
            </div>
            <AnimatePresence>
                {isDropdownOpen && (
                    <motion.div
                        key="dropdown"
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                        className="origin-top-right absolute mt-[19px] w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                    >
                        <div className="py-1">
                            <label className="flex items-center px-4 py-2">
                                <input
                                    type="checkbox"
                                    className="form-checkbox h-5 w-5 text-gray-600"
                                    checked={selectedOptions.length === options.length}
                                    onChange={toggleAllOptions}
                                />
                                <span className="ml-2 text-gray-700">Select All</span>
                            </label>
                            {options.map((option) => (
                                <label key={option} className="flex items-center px-4 py-2">
                                    <input
                                        type="checkbox"
                                        className="form-checkbox h-5 w-5 text-gray-600"
                                        checked={selectedOptions.includes(option)}
                                        onChange={() => toggleOption(option)}
                                    />
                                    <span className="ml-2 text-gray-700">{option}</span>
                                </label>
                            ))}
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default MultiDropdown;
