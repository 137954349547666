import { GradientBorder, GradientSection } from "../../ui/wrappers";
import { Icons } from "../../asset";
function OnlineAssessmentSection3({ gradient, color }) {
  const features = [
    {
      title: "Technical Skills Assessments",
      text: " - Assess candidates' proficiency in programming languages, software applications, data analysis tools, and more with our comprehensive technical skills assessments. Identify top performers and ensure they possess the necessary expertise for the role",
    },
    {
      title: "Cognitive Ability Tests",
      text: " - Measure candidates' problem-solving, critical thinking, and analytical skills with our cognitive ability tests. These assessments are designed to evaluate their mental agility and ability to tackle complex challenges",
    },
    {
      title: "Online Personality Assessment Tests for Jobs",
      text: " - Gain valuable insights into candidates' personality traits, work styles, and cultural fit with our scientifically validated online personality assessment tests. Hire individuals who align with your organization's values and work environment, promoting better teamwork and productivity",
    },
  ];

  const advancedFeatures = [
    {
      title: "Customizable Assessments",
      text: " - Tailor online assessment tests to align with your unique job requirements and company culture",
    },
    {
      title: "Automated Scoring and Reporting",
      text: " - Receive detailed performance reports and analytics, enabling data-driven hiring decisions",
    },
    {
      title: "Integrated Applicant Tracking System (ATS)",
      text: "Seamlessly integrate our platform with your existing ATS for a streamlined hiring process",
    },
    {
      title: "Remote Proctoring",
      text: " - Ensure test integrity and prevent cheating with our secure remote proctoring solutions",
    },
  ];

  return (
    <GradientSection
      sectionHeading={"Why Choose Ambition Hire’s Online Assessment Platform"}
    >
      <p className="my-8 text-center text-sm md:text-[22px] font-500 leading-[18.9px] md:leading-[34px] text-primary-light px-10">
        At Ambition Hire we understand that every position requires a unique set
        of skills and attributes. That's why our online assessment platform
        offers a wide range of online assessment tests tailored to specific job
        roles and industries. From technical skills assessments to cognitive
        ability tests, we've got you covered
      </p>
      <div className="mt-8 sm:mt-[64px] lg:mt-24 flex flex-col gap-6 mb-32">
        {/* Features */}
        {features?.map((item, index) => (
          <div
            key={index}
            className="rounded-[16px] border py-6 px-8"
            style={{ background: "#8CB56C0D" }}
          >
            <div className="text-base md:text-2xl leading-[21.6px] md:leading-[32.3px] text-primart-light font-700 flex gap-4">
              <div className="h-6 w-6 mt-2">
                <Icons.Asterisk color={color} />
              </div>

              <p>
                <span className="font-700">{item?.title}</span>{" "}
                <span className="font-500">{item?.text}</span>
              </p>
            </div>
          </div>
        ))}

        {/* Advanced Features */}
        <h2 className="text-xl md:text-[32px] leading-[25px] md:leading-10 tracking-[-0.05em] text-center font-krona mt-24 md:mt-48 mb-12 md:mb-20">
          Key Features
        </h2>
        {advancedFeatures?.map((item, index) => (
          <div
            key={index}
            className="rounded-[16px] border py-6 px-8"
            style={{ background: "#8CB56C0D" }}
          >
            <div className="text-base md:text-2xl leading-[21.6px] md:leading-[32.3px] text-primart-light font-700 flex gap-4">
              <div className="h-6 w-6 mt-2">
                <Icons.CircleCheck color={color} />
              </div>

              <p>
                <span className="font-700">{item?.title}</span>{" "}
                <span className="font-500">{item?.text}</span>
              </p>
            </div>
          </div>
        ))}
      </div>
    </GradientSection>
  );
}

export default OnlineAssessmentSection3;
