import React, { useState } from "react";
import styles from "./style.module.css";
import noise from "../../../asset/home/Noise.png";
import play from "../../../asset/home/play-circle.png";
import landing from "../../../asset/home/landing.png";
import landingSmall from "../../../asset/home/smallScreen/landingSmall.png";
import { Border_Box, Landing_Card, Gradient_Box } from "../../../ui/cards";
import NavBar from "../../nav";
import { QuizButton, ContactUsButton } from "../../../ui/buttons";
import { PlayCircle, ScrollDown } from "../../../asset/icons";
import { MotionConfig, motion } from "framer-motion";
import { Modal } from "../../../ui/wrappers";
function Main() {
  const color = "#7D4996";
  const [modelOpen, setModelOpen] = useState(false);
  const handleModelOpen = () => {
    setModelOpen(true);
  };

  return (
    <MotionConfig
      transition={{
        duration: 1,
      }}
    >
      {modelOpen && (
        <Modal onClose={setModelOpen}>
          <div className="md:h-[320px] md:w-[650px] bg-white rounded-lg">
            <div className="hidden md:block">
              <video
                width="750"
                height="700"
                controls
                src="https://drive.google.com/uc?id=1tzalS42xcxwKHP77N_iwDt4ILOOeoa77"
                className="hidded md:block"
              />
            </div>

            <div className="block md:hidden">
              <video
                width="350"
                height="300"
                controls
                src="https://drive.google.com/uc?id=1tzalS42xcxwKHP77N_iwDt4ILOOeoa77"
              />
            </div>
          </div>
        </Modal>
      )}
      <main className={`mix-blend-overlay border relative`}>
        <div
          className={`absolute -z-10 opacity-85 ${styles.gradient_bg} h-full w-full top-0 left-0`}
        ></div>

        <div className="wrapper  mt-4 lg:mt-9 ">
          {/* navbar */}
          <NavBar
            bgColor={"bg-[#7D4996]"}
            color={color}
            textColor={"text-[#7D4996]"}
          />
          {/* main content */}
          <div className="flex mt-8 sm:mt-20 lg:mt-[100px] mb-[110px] flex-col-reverse sm:flex-row sm:gap-4">
            {/* Left part */}
            <div className="mt-6 lg:mt-0 flex-1">
              {/* headings */}
              <div className="flex flex-col items-center">
                <h1 className="font-krona text-[24px] lg:text-[48px] text-center sm:text-left max-w-[681px] leading-[30px] lg:leading-[60px] tracking-normal">
                  Seamless Job Assessments & Endless Options For Your Business
                </h1>
                <p className="mt-4 lg:mt-[24px] font-500 text-[16px] sm:text-[14px] lg:text-[24px] text-center sm:text-left max-w-[681px] leading-[21.6px] lg:leading-[32.4px]">
                  Revolutionize your recruitment process with our wide range of
                  assessments and proctoring-enabled platform
                </p>
              </div>

              {/* buttons */}
              <div className="mt-8 lg:mt-[48px] flex flex-col items-center sm:items-start   lg:flex-row gap-4 lg:gap-6">
                <QuizButton
                  link={"#"}
                  text={"Take a Quick Demo"}
                  bgColor={"bg-primary"}
                  textColor={"text-primary"}
                />
                <ContactUsButton
                  link="/contact"
                  text={"Contact Us"}
                  borderColor="border-primary"
                  textColor="text-primary"
                />
              </div>
              <div
                className="mt-8 lg:mt-[24px] flex justify-center md:justify-start gap-2 items-center font-700"
                onClick={handleModelOpen}
              >
                <PlayCircle color={"#7D4996"} />
                <a
                  href="#"
                  className="text-[14px] lg:text-[18px] text-primary leading-[18.9px] lg:leading-[24.3px]"
                >
                  Get a Glimpse of the Platform
                </a>
              </div>
            </div>

            {/* Right part */}
            <motion.div
              initial={{ x: 1000 }}
              animate={{ x: 0 }}
              transition={{
                type: "spring",
                stiffness: 100,
                damping: 25,
                mass: 5,
              }}
              className="flex flex-col flex-1"
            >
              <div className="px-[30px] xs:px-[60px] sm:px-0">
                <img
                  src={landing}
                  className="hidden sm:block w-full object-fill "
                />
                <img
                  src={landingSmall}
                  className="sm:hidden w-full object-fill "
                />
              </div>
              <div
                className={`${styles.gradient_button} hidden lg:block flex justify-center text-[18px] rounded-[128px] px-[32px] py-4 font-500 mt-[30px] leading-[24.3px] text-center`}
              >
                <button>We cater to both - corporates and colleges</button>
              </div>
            </motion.div>
          </div>

          {/* Botttom Part of Boxes */}
          <div className="flex gap-4 lg:gap-x-6  items-center justify-center lg:items-start lg:justify-start">
            <div className="hidden sm:flex gap-4 w-full">
              <Gradient_Box data="45%" text="Time Saving" />
              <Gradient_Box data="2X" text="Quality Hiring" />
              <Gradient_Box data="62%" text="Cost Effective" />
            </div>
          </div>
        </div>
        <div className="sm:hidden mt-auto flex items-center justify-center pb-4">
          <span className="font-700 leading-[18.9px] text-[14px]">Scroll</span>
          <ScrollDown />
        </div>
        <img
          src={noise}
          className="object-cover w-full h-full object-fill opacity-[20%] absolute top-0 -z-10 "
        />
      </main>
    </MotionConfig>
  );
}

export default Main;
