import React from 'react'
import { QuestionCard } from '../../../../ui/cards';
import { motion, MotionConfig } from 'framer-motion';
function QuestionBank() {
    const containerVariants = {
        initial: { opacity: 0 },
        animate: { opacity: 1, transition: { type: 'spring', stiffness: 500, damping: 10 ,staggerChildren:.5} },
    };

    const cardVariants = {
        initial: { opacity: 0, x: 1000 },
        animate: { opacity: 1, x: 0, transition: { type: 'spring', stiffness: 100, damping: 20,mass:2.5 } },
    };
    return (
        <MotionConfig
            transition={{ duration: 1 }}
        >
            <motion.div
            className=''
            >
                <h3 className='lvisible  text-[20px] md:text-[24px] tracking-[-1.2px] text-blue-light font-krona'>Massive Question Bank</h3>
                <p className='lvisible text-[16px] mt-[14px] font-500 leading-[21.6px]'>Get access to an extensive question bank featuring over 40 specialized modules, each brimming with approximately 100 thoughtfully crafted questions for tailored recruitment</p>
                <motion.div
                    variants={containerVariants}
                    initial="initial"
                    animate="animate"
                    className='flex flex-wrap justify-center xl:flex-nowrap w-full gap-3 mt-[24px]'>
                    <motion.div
                        key="card1"
                        variants={cardVariants}
                        className='your-question-card-class'
                    >
                        <QuestionCard text1={"Retail"} text2={"Sales"} text3={"BPO"} text4={"15+ Modules in"} text5={"FUNCTIONAL"} color={"bg-[#FF7604]"} />
                    </motion.div>

                    <motion.div
                        key="card2"
                        variants={cardVariants}
                        className='your-question-card-class'
                    >
                        <QuestionCard text1={"Read & Repeat"} text2={"Grammar"} text3={"Error Identification"} text4={"9 Modules in"} text5={"ENGLISH"} color={"bg-[#B654BD]"} />
                    </motion.div>

                    <motion.div
                        key="card3"
                        variants={cardVariants}
                        className='your-question-card-class'
                    >
                        <QuestionCard text1={"Personality"} text2={"IQ"} text3={"Sales Litmus"} text4={"3+ Modules in"} text5={"PSYCHOMETRIC"} color={"bg-[#3ABDB5]"} />
                    </motion.div>
                </motion.div>
            </motion.div>

        </MotionConfig>
    )
}

export default QuestionBank;