// AboutBox component
import React from 'react';
import gradientBorder from '../../asset/about/gradientBorder.png';
import { LearnMoreButton } from '../buttons';
import { Link, animateScroll as scroll } from 'react-scroll';
import { GradientBorder } from '../wrappers';

function AboutBox({ text, link, smallText }) {
  const handleLearnMoreClick = () => {
    scroll.scrollTo(link, {
      smooth: true,
    });
  };
  const gradient = "linear-gradient(115.5deg, #FFFFFF 55.45%, #B9B9B9 100%)"
  return (
    <GradientBorder borderWidth={"2px"} radius={"18px"} gradient={gradient}>
      <div className='p-4 lg:p-6 flex flex-col sm:w-[245px] lg:w-[278px] lg:max-w-[300px] sm:h-[139px] lg:h-[200px]'>
        <p className='hidden sm:block font-krona text-[12px] lg:text-[18px] leading-[15px] lg:leading-[22.5px] tracking-[-0.05em] text-[#656565]'>
          {text}
        </p>
        <div className='mt-auto'>
          <Link
            to={link}
            smooth={true}
            offset={-50}
            delay={10}
            duration={250}
            className='cursor-pointer'>
            <LearnMoreButton bgColor='bg-light-green' smallText={smallText} />
          </Link>
        </div>
      </div>
    </GradientBorder>
  );
}

export default AboutBox;
