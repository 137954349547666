import React from 'react'

function Star({ color }) {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="star/filled/plain">
                <path id="Star" d="M9 0L10.2728 7.72721L18 9L10.2728 10.2728L9 18L7.72721 10.2728L0 9L7.72721 7.72721L9 0Z" fill={color} />
            </g>
        </svg>


    )
}

export default Star;