import { GradientSection } from "../../ui/wrappers";
function OnlineAssessmentSection2({ gradient }) {
  const data = [
    {
      title: "User-Friendly Online Candidate Assessment Tools",
      text: "Our online assessment platform is designed with a user-friendly interface, making it easy for both candidates and hiring managers to navigate. With our intuitive online candidate assessment tools, you can create, customize, and administer assessments seamlessly",
    },
    {
      title: "Streamline your Hiring Process and Reduce Time-to-Hire",
      text: "By leveraging our online assessment platform, you can significantly reduce the time and effort required to identify top candidates. Candidates can conveniently complete online assessment tests from anywhere while hiring managers can quickly evaluate results and make informed hiring decisions",
    },
    {
      title: "Enhance Objectivity and Minimize Bias",
      text: "Our online assessment tests and candidate assessment tools are scientifically validated and designed to minimize bias, ensuring a fair and equitable evaluation process. By focusing on measurable skills, cognitive abilities, and job-relevant traits, you can make hiring decisions based on merit, rather than subjective factors",
    },
    {
      title: "Trusted by Leading Organizations Worldwide",
      text: "Organizations across industries have trusted our online assessment platform to streamline their hiring processes, reduce turnover rates, and build high-performing teams. Our proven solutions have helped businesses gain a competitive edge in attracting and retaining top talent",
    },
  ];

  return (
    <GradientSection
      sectionHeading={
        "What sets Ambition Hire’s Online Assessment Tests apart?"
      }
    >
      <div className="mt-8 sm:mt-[64px] lg:mt-24 grid grid-cols-1 md:grid-cols-2 gap-8">
        {data?.map((item, index) => (
          <div
            key={index}
            className="rounded-[24px] p-8"
            style={{ background: gradient }}
          >
            <h3 className="text-lg md:text-xl font-krona leading-[22.6px] md:leading-[25px] text-center">
              {item.title}
            </h3>
            <p className="text-sm md:text-lg text-[#555555] leading-[18.9px] md:leading-[26px] font-500 mt-6 md:mt-8">
              {item.text}
            </p>
          </div>
        ))}
      </div>
    </GradientSection>
  );
}

export default OnlineAssessmentSection2;
