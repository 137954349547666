import React from "react";
import { GradientBorder, GradientSection } from "../../ui/wrappers";
import mainImage from "../../asset/quizDemo/background.png";
import { Input } from "../../ui/inputs";
import SubmitButton from "../../ui/buttons/submitButton";
import formSmall from "../../asset/general/formSmall.png";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ContactAPI } from "../../service/api";
import { useNavigate } from "react-router-dom";
function QuizDemoMain() {
  const navigate = useNavigate();
  const gradient =
    "linear-gradient(115.03deg, rgba(125, 73, 150, 0.18) 17.08%, rgba(125, 73, 150, 0.08) 39.71%, rgba(125, 73, 150, 0.24) 63.92%)";
  const gradientBox = "linear-gradient(112.56deg, #C3BFEB 0%, #FFFFFF 100%)";
  const color = "#7D4996";
  const validationSchema = Yup.object({
    name: Yup.string().trim().required("Full Name is required"),
    phone: Yup.string()
      .trim()
      .matches(/^(0\d{10}|[6-9]\d{9})$/, "Invalid Phone Number")
      .required("Phone Number is required"),
    company_name: Yup.string().trim().required("Company Name is required"),
    email: Yup.string()
      .trim()
      .email("Invalid email address")
      .required("Work Email Address is required"),
    alternate_email: Yup.string().trim().email("Invalid email address"),
  });

  const initialValues = {
    name: "",
    phone: "",
    company_name: "",
    email: "",
    alternate_email: "",
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const updatedValues = {
        ...values,
        query_description: "",
        query_type: "sales",
        subject: "Others",
        remarks: "",
        status: "open",
      };

      // Call the quizDemoAPI to submit the form data with updated values
      await ContactAPI(updatedValues);
      navigate("/thanks");
      window.scrollTo(0, 0);

      // Reset the form after successful submission
      resetForm();
    } catch (error) {
      // Handle errors if needed
      console.error("Error submitting form:", error);
    }
  };

  return (
    <GradientSection
      nav={true}
      gradient={gradient}
      color={color}
      textColor={"text-[#7D4996]"}
    >
      <div className="mt-[100px] pb-[223px] sm:px-[70px] ">
        {/*  navigate error */}
        <div className="rounded-[12px] sm:hidden overflow-hidden my-8">
          <img src={formSmall} className="object-cover w-full h-full" />
        </div>
        <h2 className="font-krona  text-[20px] lg:text-[24px] leading-[25px] lg:leading-[30px] tracking-[-0.05em] text-center md:text-left ">
          Take a Quick Demo
        </h2>
        <div className="grid sm:grid-cols-3 lg:grid-cols-2 mt-6 gap-[24px] ">
          {/* Form Image */}
          <div className="rounded-[12px] hidden sm:block overflow-hidden">
            <img src={mainImage} className="object-cover w-full h-full" />
          </div>

          {/* Form */}
          <div className="col-span-2 lg:col-span-1">
            <GradientBorder
              gradient={gradientBox}
              radius={12}
              borderWidth={"1px"}
            >
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                <Form className="grid grid-cols-1 gap-8 lg:gap-12 p-4 lg:p-6 ">
                  <div className="flex flex-col gap-6">
                    <Input
                      id="name"
                      label="Full Name"
                      placeholder="Enter Full Name"
                    />
                    <Input
                      id="phone"
                      label="Phone Number"
                      placeholder="Enter Phone Number"
                      phone
                    />
                    <Input
                      id="company_name"
                      label="Company Name"
                      placeholder="Enter Company Name"
                    />
                    <Input
                      id="email"
                      label="Work Email Address"
                      placeholder="Enter Work Email Address"
                    />
                    <Input
                      id="alternate_email"
                      label="Alternate Email Address"
                      placeholder="Enter Alternate Email Address"
                    />
                  </div>
                  <div className="flex xs:justify-end">
                    <SubmitButton
                      text="Book a Slot"
                      bgColor="bg-primary"
                      textColor="text-primary"
                    />
                  </div>
                </Form>
              </Formik>
            </GradientBorder>
          </div>
        </div>
      </div>
    </GradientSection>
  );
}

export default QuizDemoMain;
