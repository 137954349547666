export const faqsQuestionsAnswers = [
  {
    question: "How do I create my account?",
    heading: "How do I create my account?",
    answer:
      "You can get in touch with us and we will help you do this in a jiffy",
  },
  {
    question: "How safe in my data on the platform?",
    heading: "How safe in my data on the platform?",
    answer:
      "We are a GDPR compliant & we have a multi-tenancy architecture with tenant isolation. Hence, that ensures each client's data is kept distinct from other clients' data, preventing any mixing or unauthorized access.",
  },
  {
    question: "Can I customize my assessments?",
    heading: "Can I customize my assessments?",
    answer: "Yes, the platform is completely customizable",
  },
  {
    question: "Does this work on mobile?",
    heading: "Does this work on mobile?",
    answer: "Yes, it is screen agnostic",
  },
  {
    question: "Does Candidate need to download the app?",
    heading: "Does Candidate need to download the app?",
    answer:
      "No, we are a responsive platform. Candidate can give the assessment on Mobile/Tablet/Laptop or any other device across all browsers.",
  },
  {
    question: "How long it will take to start the services?",
    heading: "How long it will take to start the services?",
    answer: "It will take less than 60 seconds to start the services",
  },
];
