import React from "react";
import { PageLayout, RevealAnimation } from "../../ui/wrappers";
import QuizDemoMain from "../../components/quizDemo/quizDemoMain";
import { Helmet } from "react-helmet";

function DemoQuizPage() {
  return (
    <PageLayout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Free trial for online assessment platform.</title>
        <meta
          name="description"
          content="Reasons why you should choose Ambition Hire - Massive question bank, intuitive user interface, customizable product, 24X7 customer support and ATS integration."
        />
        <link rel="canonical" href="https://ambitionhire.ai/free-trial" />
      </Helmet>
      <RevealAnimation>
        <QuizDemoMain />
      </RevealAnimation>
    </PageLayout>
  );
}

export default DemoQuizPage;
