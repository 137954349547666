export const QuestionData = [
  // <<===============  General ===============>>
  {
    title: "Mathematical Ability",
    type: "General",
    labelText: "General",
    numberOfQuestions: "105",
    introductionText:
      "A dynamic Mathematical Ability assessment module to evaluate candidates' quantitative skills and problem-solving proficiency. This module presents candidates with a series of math-related questions that vary in complexity, covering areas such as arithmetic, algebra, geometry, and data interpretation. Candidates engage with these questions, solving mathematical problems within a specified time frame. The module utilizes adaptive questioning, adjusting the difficulty level based on candidate responses, ensuring a tailored evaluation of their mathematical acumen.",
    keyPersonalityTraits:
      "Quantitative Proficiency Measurement: The module offers recruiters a comprehensive measure of a candidate's quantitative proficiency, assessing their ability to apply mathematical concepts and solve problems effectively.Problem-Solving Aptitude: By presenting candidates with diverse mathematical challenges, recruiters gain insights into their problem-solving skills. This is crucial for roles that involve analytical thinking and data-driven decision-making.Adaptive Assessment: The adaptive nature of the module ensures that candidates are appropriately challenged, allowing recruiters to identify individuals with a range of mathematical abilities, from foundational to advanced.Role-Specific Relevance: The module can be tailored to assess mathematical skills relevant to specific job roles, ensuring that candidates are evaluated on the mathematical competencies essential for success in their potential positions.",
    benefits:
      "Data-Driven Roles: For roles requiring data analysis, statistical interpretation, or financial calculations, the Mathematical Ability module aids recruiters in identifying candidates with the necessary quantitative skills. Engineering and Technical Positions: In technical fields, such as engineering or computer science, the module is instrumental in evaluating a candidate's mathematical foundation and problem-solving capabilities. Finance and Accounting: For positions in finance or accounting, where numerical accuracy and analytical thinking are paramount, the module provides recruiters with valuable insights into a candidate's mathematical acuity. Project Management: In roles involving project management or strategic planning, the module helps recruiters assess a candidate's ability to use quantitative data for decision-making and problem-solving.",
  },
  {
    title: "Analytical Ability",
    type: "General",
    labelText: "General",
    numberOfQuestions: "105",
    introductionText:
      "An Analytical Ability assessment module designed to evaluate candidates' critical thinking, logical reasoning, and problem-solving skills. This module presents candidates with a series of scenarios and questions that require them to analyze information, draw conclusions, and make informed decisions. Candidates engage with diverse analytical challenges, ranging from data interpretation to logical puzzles, allowing recruiters to gauge their ability to approach complex problems strategically.",
    keyPersonalityTraits:
      "Holistic Critical Thinking Assessment: The module provides recruiters with a holistic evaluation of a candidate's critical thinking abilities, assessing their capacity to analyze information, draw meaningful insights, and solve intricate problems. Logical Reasoning Proficiency: By presenting candidates with a variety of logical scenarios, the module evaluates their logical reasoning skills, essential for roles that demand sound decision-making and strategic thinking. Problem-Solving Competence: Recruiters gain insights into candidates' problem-solving competence, identifying those with a strong analytical mindset capable of addressing challenges and making data-driven decisions. Adaptive Evaluation: The adaptive nature of the module tailors the difficulty of questions based on candidate responses, ensuring a precise assessment that distinguishes between varying levels of analytical ability.",
    benefits:
      "Management and Strategy Roles: For managerial and strategic positions, where analytical thinking is crucial, the module assists recruiters in identifying candidates with the capacity to analyze complex business scenarios. Data Analysis and Research Roles: In roles requiring data analysis, market research, or trend interpretation, the module provides recruiters with valuable insights into a candidate's ability to extract meaningful information from diverse datasets. Consulting and Advisory Positions: For roles in consulting or advisory capacities, where sound analytical thinking is fundamental, the module aids recruiters in selecting candidates capable of providing informed and strategic advice. Problem-Solving-Centric Positions: In roles focused on addressing challenges, finding innovative solutions, and making data-backed decisions, the Analytical Ability module serves as a critical tool for assessing a candidate's problem-solving skills.",
  },
  {
    title: "General Aptitude",
    type: "General",
    labelText: "General",
    numberOfQuestions: "105",
    introductionText:
      "AmbitionHire introduces a versatile General Aptitude assessment module designed to evaluate candidates' overall cognitive abilities and readiness for diverse professional challenges. This module encompasses a range of questions that test candidates' numerical reasoning, verbal proficiency, abstract reasoning, and attention to detail. By offering a well-rounded evaluation of fundamental aptitudes, recruiters gain valuable insights into a candidate's cognitive strengths and adaptability across various job functions.",
    keyPersonalityTraits:
      "Holistic Cognitive Assessment: The module provides recruiters with a holistic understanding of a candidate's cognitive abilities, assessing their numerical, verbal, and abstract reasoning skills in a single, comprehensive evaluation. Adaptability Measurement: By incorporating diverse question types, the module gauges a candidate's adaptability and versatility in tackling different cognitive challenges, reflecting their readiness for a range of professional scenarios. Foundation for Specialized Roles: Recruiters can utilize the General Aptitude module as a foundational assessment, identifying candidates with a strong cognitive base suitable for more specialized roles in areas such as management, analytics, or research. Time-Efficient Screening: The module offers recruiters an efficient means of screening candidates' cognitive abilities, saving time in the initial stages of the recruitment process and allowing for a more focused evaluation in subsequent stages.",
    benefits:
      "Entry-Level Positions: For entry-level roles across diverse industries, the General Aptitude module serves as a valuable tool for evaluating candidates' cognitive abilities and potential, providing a foundation for their professional development. Roles Requiring Multifaceted Skills: In positions that demand a combination of numerical, verbal, and abstract reasoning skills, the module helps recruiters identify candidates with a well-rounded aptitude for handling diverse responsibilities. Leadership and Management Roles: For leadership and management positions, where a comprehensive skill set is crucial, the General Aptitude module aids in assessing a candidate's ability to handle complex challenges and make informed decisions. Career Development Initiatives: Companies looking to identify high-potential individuals for career development programs can use the General Aptitude module to assess candidates' cognitive strengths and suitability for advancement.",
  },
  {
    title: "Sales Aptitude",
    type: "General",
    labelText: "General",
    numberOfQuestions: "105",
    introductionText:
      "AmbitionHire introduces a specialized Sales Aptitude assessment module tailored to evaluate candidates' suitability for roles in sales and business development. This module is designed to assess not only fundamental cognitive abilities but also specific traits crucial for success in sales, including communication skills, interpersonal acumen, persuasion techniques, and resilience. By presenting scenarios and questions aligned with real-world sales challenges, recruiters gain valuable insights into a candidate's potential to excel in a sales-driven environment.",
    keyPersonalityTraits:
      "Targeted Sales Skills Evaluation: The module assesses candidates' sales-specific skills, including effective communication, persuasive abilities, relationship-building, and resilience, providing recruiters with a targeted evaluation for sales-oriented roles. Real-World Sales Scenario Simulation: By presenting scenarios reflective of actual sales challenges, recruiters gain insights into how candidates approach and navigate common situations encountered in a sales role, ensuring a more accurate assessment. Identification of Sales Potential: Recruiters can identify candidates with innate sales aptitude, separating those who possess the natural traits and skills essential for success in sales-driven positions. Adaptive Evaluation: The module adapts to candidate responses, ensuring that the assessment aligns with the varying demands of sales roles, from entry-level to senior positions.",
    benefits:
      "Sales Representative Positions: For roles directly involved in sales and client interactions, the Sales Aptitude module assists recruiters in identifying individuals with the right mix of skills and traits to thrive in a sales-driven environment. Business Development Roles: In positions focused on expanding client portfolios and driving revenue growth, the module aids recruiters in evaluating candidates' abilities to prospect, negotiate, and build lasting client relationships. Client-Facing Positions: For roles that involve frequent client interactions, such as account management or customer success, the module provides insights into a candidate's customer-centric approach and communication skills. Leadership in Sales: Recruiters can leverage the module to assess not only the sales acumen of candidates but also their leadership potential within a sales team, identifying those capable of driving results and inspiring others.",
  },
  {
    title: "Customer Centricity",
    type: "General",
    labelText: "General",
    numberOfQuestions: "105",
    introductionText:
      "AmbitionHire introduces a Customer Centricity assessment module designed to evaluate candidates' alignment with customer-focused values, behaviors, and skills. This module assesses not only the candidate's ability to understand customer needs but also their proficiency in delivering exceptional customer experiences. By presenting scenarios and questions that simulate customer interactions, recruiters gain valuable insights into a candidate's empathy, communication skills, problem-solving approach, and overall commitment to fostering positive customer relationships.",
    keyPersonalityTraits:
      "Customer-Focused Skills Evaluation: The module provides recruiters with a comprehensive evaluation of a candidate's customer-centric skills, assessing their ability to understand, empathize, and respond effectively to customer needs. Empathy and Communication Assessment: By incorporating scenarios that require candidates to demonstrate empathy and effective communication, recruiters gain insights into a candidate's interpersonal skills, essential for delivering superior customer experiences. Problem-Solving in Customer Scenarios: The module assesses a candidate's approach to problem-solving in the context of customer interactions, ensuring that they can navigate challenges and provide satisfactory solutions in a customer-centric manner. Cultural Fit for Customer-Centric Organizations: Recruiters can use the module to identify candidates whose values align with a customer-centric organizational culture, fostering a workforce dedicated to delivering exceptional service.",
    benefits:
      "Customer Service Positions: For roles directly involved in customer service, support, or helpdesk functions, the Customer Centricity module aids recruiters in selecting candidates with the right mindset and skills to excel in customer-facing roles. Client Relationship Management: In positions requiring the management of client relationships, such as account management or client success, the module helps assess a candidate's ability to build and maintain positive, long-term relationships. Sales and Business Development: For roles involving client acquisition and business development, the module ensures that candidates understand the importance of customer-centric practices in driving sales and fostering customer loyalty. Leadership with a Customer Focus: Recruiters can leverage the module to assess not only frontline employees but also leaders and managers, ensuring that leadership within the organization is committed to maintaining a customer-centric approach.",
  },
  {
    title: "Logical Reasoning",
    type: "General",
    labelText: "General",
    numberOfQuestions: "105",
    introductionText:
      "AmbitionHire presents a specialized Logical Reasoning assessment module designed to evaluate candidates' deductive and inductive reasoning abilities, critical thinking, and problem-solving skills. This module encompasses a diverse range of logic-based scenarios and questions, challenging candidates to analyze information, draw logical conclusions, and apply structured reasoning. By engaging candidates in thought-provoking exercises, recruiters gain valuable insights into their cognitive processes and analytical capabilities.",
    keyPersonalityTraits:
      "Comprehensive Critical Thinking Evaluation: The module provides recruiters with a comprehensive assessment of a candidate's critical thinking skills, measuring their ability to analyze information, make sound judgments, and solve complex problems. Problem-Solving Proficiency: By presenting candidates with varied logical challenges, recruiters gain insights into their problem-solving proficiency, essential for roles that require analytical thinking and strategic decision-making. Adaptive Evaluation: The adaptive nature of the module tailors the difficulty of questions based on candidate responses, ensuring a nuanced and accurate assessment that distinguishes varying levels of logical reasoning ability. Identification of Analytical Thinkers: Recruiters can use the module to identify candidates who excel in analytical thinking, a crucial attribute for roles that involve data analysis, research, and decision-making based on structured reasoning.",
    benefits:
      "Analytical Roles: For positions that involve data analysis, research, and interpreting complex information, the Logical Reasoning module aids recruiters in identifying candidates with strong analytical thinking capabilities. Management and Strategy Positions: In leadership and strategic roles, where the ability to make informed decisions based on logical reasoning is paramount, the module helps recruiters assess a candidate's suitability for such responsibilities. Problem-Solving-Centric Roles: For roles focused on addressing challenges, finding innovative solutions, and making data-backed decisions, the Logical Reasoning module serves as a critical tool for evaluating a candidate's problem-solving skills. Technical and Engineering Positions: In technical fields, such as engineering or computer science, the module is instrumental in evaluating a candidate's logical reasoning skills, ensuring their ability to approach technical challenges systematically.",
  },
  {
    title: "Situational Judgement",
    type: "General",
    labelText: "General",
    numberOfQuestions: "46",
    introductionText:
      "AmbitionHire introduces a specialized Situational Judgement assessment module designed to evaluate candidates' ability to make sound decisions in realistic workplace scenarios. This module presents candidates with a series of context-specific situations, each accompanied by a set of response options. Candidates are tasked with selecting the most effective and appropriate course of action based on the given scenarios. By simulating workplace situations, recruiters gain valuable insights into candidates' judgment, problem-solving skills, and their alignment with organizational values.",
    keyPersonalityTraits:
      "Realistic Decision-Making Assessment: The module provides recruiters with a realistic evaluation of a candidate's decision-making abilities in workplace scenarios, mirroring the challenges they might encounter in their prospective roles. Behavioral Competency Evaluation: By assessing how candidates respond to situational challenges, recruiters gain insights into their behavioral competencies, including problem-solving, interpersonal skills, and adaptability. Cultural Fit Assessment: The module aids recruiters in assessing candidates' alignment with the organization's values and expected behaviors, ensuring a cultural fit that contributes to a positive work environment. Predictive of On-the-Job Performance: The situational judgment assessment offers a predictive measure of a candidate's on-the-job performance, providing recruiters with indicators of how well candidates are likely to navigate real-world challenges.",
    benefits:
      "Leadership and Management Roles: For leadership positions where decision-making and judgment are critical, the Situational Judgement module helps recruiters identify candidates who exhibit the necessary skills to navigate complex situations. Customer Service and Client Interactions: In roles requiring direct customer interactions, the module aids recruiters in assessing a candidate's ability to handle customer-related challenges and provide satisfactory solutions. Team Collaboration Positions: For roles emphasizing teamwork and collaboration, the module helps recruiters evaluate a candidate's ability to make decisions that contribute positively to team dynamics and organizational goals. Conflict Resolution Roles: In positions where conflict resolution is key, such as human resources or managerial roles, the Situational Judgement module assists recruiters in identifying candidates who can effectively manage workplace conflicts.",
  },
  {
    title: "General Knowledge",
    type: "General",
    labelText: "General",
    numberOfQuestions: "165",
    introductionText:
      "AmbitionHire introduces a comprehensive General Knowledge assessment module designed to evaluate candidates' awareness of diverse topics, current affairs, and general information. This module encompasses a wide range of questions covering geography, history, science, technology, current events, and more. By engaging candidates in a test of their general knowledge, recruiters gain insights into their intellectual curiosity, awareness of the world, and ability to stay informed on a broad spectrum of subjects.",
    keyPersonalityTraits:
      "Intellectual Curiosity Evaluation: The module provides recruiters with an assessment of a candidate's intellectual curiosity and interest in staying informed about a variety of topics beyond their specific field of expertise. Cultural Awareness Assessment: By covering diverse subjects, the General Knowledge module aids recruiters in assessing a candidate's cultural awareness and their ability to connect with a broad audience or work in diverse environments. Current Affairs Relevance: Recruiters can use the module to evaluate a candidate's awareness of current affairs, ensuring they are up-to-date on important events and trends that may impact their work or the industry. Communication and Networking: For roles that involve networking, client interactions, or public-facing responsibilities, the General Knowledge module provides insights into a candidate's ability to engage in informed conversations across a variety of topics.",
    benefits:
      "Client-Facing and Public Relations Roles: In positions involving client interactions, public relations, or roles where a broad understanding of various subjects is beneficial, the General Knowledge module aids recruiters in assessing a candidate's suitability. Roles Requiring Cross-Functional Collaboration: For roles that require collaboration across different departments or functions, the module helps recruiters identify candidates with a well-rounded knowledge base that complements their specific expertise. Educational and Training Positions: In roles related to education, training, or knowledge dissemination, the General Knowledge module ensures that candidates possess a depth of information that aligns with the requirements of imparting knowledge to others. Multinational and Global Roles: For positions that involve interactions with international clients or global stakeholders, the module aids recruiters in assessing a candidate's global awareness and cultural sensitivity.",
  },

  // <<===============  Domain ===============>>
  {
    title: "Retail",
    type: "Domain",
    labelText: "Domain",
    numberOfQuestions: "120",
    introductionText:
      "AmbitionHire is proud to introduce a specialized Retail Recruitment Excellence module tailored to assess candidates for success in the dynamic and customer-centric retail sector. This module is designed to evaluate essential skills and attributes specific to the retail industry, ensuring recruiters identify candidates who can thrive in the fast-paced, customer-focused retail environment.",
    keyPersonalityTraits:
      "Customer Service Proficiency: The module assesses candidates' customer service skills, evaluating their ability to provide exceptional service, resolve inquiries, and create positive shopping experiences. Sales and Upselling Capability: Recruiters gain insights into candidates' sales acumen, assessing their ability to drive sales, upsell products, and contribute to revenue growth in a retail setting. Adaptability and Multitasking: By presenting scenarios reflective of the retail environment, the module evaluates candidates' adaptability, multitasking skills, and their ability to handle the dynamic nature of retail operations. Product Knowledge and Brand Alignment: Recruiters can assess candidates' product knowledge and their alignment with the brand, ensuring they can effectively communicate product details and represent the brand in a positive light.",
    benefits:
      "Store Associate Positions: For roles involving direct customer interactions on the sales floor, the Retail Recruitment Excellence module aids recruiters in identifying candidates with the right blend of customer service and sales skills. Store Management and Leadership Roles: In positions requiring leadership in a retail setting, the module helps assess candidates' ability to manage teams, drive sales targets, and uphold a positive in-store experience. Visual Merchandising and Product Presentation Roles: Recruiters can leverage the module to assess candidates for roles related to visual merchandising, ensuring they have an eye for product presentation and store aesthetics. Customer-Centric Roles in Retail Operations: For roles that involve managing customer service desks, handling returns, or overseeing customer-centric operations, the module assists recruiters in identifying candidates with the right skills for these functions.",
  },
  {
    title: "Banking",
    type: "Domain",
    labelText: "Domain",
    numberOfQuestions: "120",
    introductionText:
      "AmbitionHire is excited to introduce a specialized Banking Proficiency Assessment Module designed to identify candidates with the skills and knowledge essential for success in the dynamic and regulated field of banking. This module is crafted to assess candidates for roles in retail banking, financial services, and related positions, ensuring that recruiters can confidently select candidates who demonstrate expertise in key banking competencies.",
    keyPersonalityTraits:
      "Financial Acumen Evaluation: The module assesses candidates' financial literacy, including their understanding of banking products, investment instruments, and financial market dynamics. Customer Relationship Management: Recruiters gain insights into candidates' abilities to build and maintain customer relationships, ensuring they possess the interpersonal skills necessary for client-facing roles in banking. Regulatory Compliance Knowledge: In the heavily regulated banking industry, the module evaluates candidates' awareness of regulatory requirements and their ability to adhere to compliance standards. Risk Management and Decision-Making: By presenting scenarios reflective of banking operations, recruiters can assess candidates' risk management skills, decision-making abilities, and their approach to financial problem-solving.",
    benefits:
      "Customer-Facing Roles in Retail Banking: For positions involving customer interactions in retail banking branches, the Banking Proficiency module aids recruiters in identifying candidates with strong financial knowledge and customer service skills. Financial Advisory and Investment Roles: Recruiters can leverage the module to assess candidates for roles in financial advisory, investment management, and wealth management, ensuring they have the expertise to guide clients in their financial decisions. Risk and Compliance Positions: In roles focused on risk management, compliance, and ensuring adherence to regulatory standards, the module assists recruiters in identifying candidates with a strong understanding of banking regulations. Operational and Branch Management: For leadership roles in banking operations or branch management, the module helps recruiters evaluate candidates for their strategic thinking, leadership skills, and ability to oversee complex banking functions.",
  },
  {
    title: "Insurance",
    type: "Domain",
    labelText: "Domain",
    numberOfQuestions: "120",
    introductionText:
      "AmbitionHire introduces a specialized Insurance Excellence Assessment Module tailored to identify candidates with the skills and knowledge essential for success in the dynamic and regulated field of insurance. This module is designed to assess candidates for roles in insurance underwriting, sales, claims management, and related positions, ensuring recruiters can confidently select candidates who demonstrate expertise in key insurance competencies.",
    keyPersonalityTraits:
      "Insurance Product Knowledge: The module evaluates candidates' understanding of various insurance products, including life, health, property, and casualty insurance, ensuring they possess the necessary knowledge to navigate the diverse insurance landscape. Sales and Client Relationship Management: Recruiters gain insights into candidates' abilities to sell insurance products, build client relationships, and provide effective insurance solutions based on client needs. Risk Assessment and Underwriting Skills: In the insurance underwriting domain, the module assesses candidates for their risk assessment skills, underwriting acumen, and the ability to make sound decisions in evaluating insurance applications. Claims Management and Customer Service: By presenting scenarios reflective of insurance claims processes, recruiters can evaluate candidates' claims management skills, customer service abilities, and their approach to resolving insurance-related issues.",
    benefits:
      "Insurance Sales and Advisory Roles: For positions involving insurance sales, client advisory, and guiding clients in selecting appropriate insurance coverage, the Insurance Excellence module aids recruiters in identifying candidates with a strong sales and customer service orientation. Underwriting and Risk Management Positions: Recruiters can leverage the module to assess candidates for roles in underwriting and risk management, ensuring they have the skills to evaluate and manage risks associated with insurance policies. Claims Adjusting and Processing: In roles focused on claims management and processing, the module assists recruiters in identifying candidates with a solid understanding of claims procedures, attention to detail, and effective problem-solving skills. Insurance Operations and Leadership: For leadership roles in insurance operations or management, the module helps recruiters evaluate candidates for their strategic thinking, leadership skills, and ability to oversee complex insurance functions.",
  },
  {
    title: "BPO",
    type: "Domain",
    labelText: "Domain",
    numberOfQuestions: "120",
    introductionText:
      "AmbitionHire introduces a specialized BPO Excellence Assessment Module designed to evaluate candidates for success in the dynamic and customer-centric Business Process Outsourcing (BPO) industry. This module is crafted to assess candidates for roles in customer support, technical support, sales, and other BPO-related functions, ensuring recruiters can confidently select candidates who demonstrate expertise in key BPO competencies.",
    keyPersonalityTraits:
      "Customer Service and Communication Skills: The module assesses candidates' customer service and communication skills, evaluating their ability to handle customer inquiries, resolve issues, and provide excellent service. Technical Proficiency for Tech Support Roles: Recruiters gain insights into candidates' technical knowledge and troubleshooting abilities, ensuring they are well-equipped to handle technical support roles effectively. Sales and Upselling Capability: For roles involving sales and upselling, the module aids recruiters in identifying candidates with a sales-oriented mindset and the ability to achieve revenue targets. Adaptability and Multitasking: By presenting scenarios reflective of the BPO environment, the module evaluates candidates' adaptability, multitasking skills, and their ability to handle diverse tasks efficiently.",
    benefits:
      "Customer Support Roles: For positions involving direct customer interactions and issue resolution, the BPO Excellence module aids recruiters in identifying candidates with strong customer service skills. Technical Support and IT Helpdesk: Recruiters can leverage the module to assess candidates for technical support and IT helpdesk roles, ensuring they possess the technical knowledge required for these positions. Sales and Telemarketing Positions: In roles focused on sales and telemarketing, the module helps recruiters identify candidates with a sales-oriented mindset and the ability to achieve sales targets. Operations and Team Management: For leadership roles in BPO operations or team management, the module assists recruiters in evaluating candidates for their leadership skills, strategic thinking, and ability to oversee BPO functions.",
  },
  {
    title: "EdTech",
    type: "Domain",
    labelText: "Domain",
    numberOfQuestions: "120",
    introductionText:
      "AmbitionHire is thrilled to present a specialized EdTech Proficiency Assessment Module, meticulously designed to identify candidates with the skills and knowledge essential for success in the dynamic field of Educational Technology (EdTech). This module is crafted to assess candidates for roles in instructional design, e-learning content development, educational technology support, and other EdTech-related functions, ensuring recruiters can confidently select candidates who demonstrate expertise in key EdTech competencies.",
    keyPersonalityTraits:
      "Instructional Design and Content Development: The module assesses candidates' ability to design effective instructional materials and develop engaging e-learning content, ensuring they possess the creativity and pedagogical expertise required for EdTech roles. Technology Integration in Education: Recruiters gain insights into candidates' familiarity with educational technologies, including Learning Management Systems (LMS), interactive tools, and digital resources, essential for roles involving tech-enabled education. Adaptability to New Educational Tools: By presenting scenarios reflective of the EdTech landscape, the module evaluates candidates' adaptability to new educational tools, ensuring they can stay current in a rapidly evolving technological environment. Communication and Facilitation Skills: Whether for virtual classrooms or online support, the module assesses candidates' communication and facilitation skills, ensuring they can effectively convey complex concepts and support learners in a digital environment.",
    benefits:
      "Instructional Design and Content Creation Roles: For positions involving the creation of instructional content and e-learning materials, the EdTech Proficiency module aids recruiters in identifying candidates with strong instructional design skills. EdTech Support and Integration Positions: Recruiters can leverage the module to assess candidates for roles involving the support and integration of educational technologies, ensuring they have the technical acumen required for these positions. Online Teaching and Facilitation: In roles focused on online teaching and facilitation, the module helps recruiters identify candidates with the communication skills and pedagogical expertise needed for effective virtual education. EdTech Operations and Leadership: For leadership roles in EdTech operations, product development, or management, the module assists recruiters in evaluating candidates for their strategic thinking, leadership skills, and ability to navigate the challenges of the EdTech landscape.",
  },
  {
    title: "Healthcare",
    type: "Domain",
    labelText: "Domain",
    numberOfQuestions: "120",
    introductionText:
      "AmbitionHire is pleased to introduce a specialized Healthcare Excellence Assessment Module designed to identify candidates with the skills and knowledge essential for success in the dynamic and crucial field of healthcare. This module is crafted to assess candidates for roles in healthcare administration, clinical positions, medical support, and other healthcare-related functions, ensuring recruiters can confidently select candidates who demonstrate expertise in key healthcare competencies.",
    keyPersonalityTraits:
      "Clinical Competence Assessment: The module evaluates candidates' clinical knowledge, ensuring they possess the necessary medical expertise for roles such as physicians, nurses, and other clinical positions. Patient Care and Empathy Evaluation: Recruiters gain insights into candidates' ability to provide compassionate patient care, assessing their empathy, communication skills, and commitment to the well-being of patients. Healthcare Administration and Management Skills: For roles in healthcare administration and management, the module aids recruiters in identifying candidates with strong organizational, leadership, and strategic thinking skills. Adherence to Regulatory Standards: By presenting scenarios reflective of healthcare regulations, the module assesses candidates' understanding of compliance and their ability to adhere to ethical and legal standards in healthcare.",
    benefits:
      "Clinical and Nursing Positions: For positions involving direct patient care, including physicians, nurses, and allied health professionals, the Healthcare Excellence module aids recruiters in identifying candidates with clinical expertise and empathy. Healthcare Administration and Management Roles: Recruiters can leverage the module to assess candidates for leadership positions in healthcare administration, ensuring they have the skills to manage healthcare facilities and teams effectively. Medical Support and Allied Health Positions: In roles related to medical support, such as medical assistants, radiologic technologists, or laboratory technicians, the module helps recruiters identify candidates with the technical skills required for these positions. Compliance and Regulatory Positions: For roles involving healthcare compliance, regulatory affairs, or quality assurance, the module assists recruiters in identifying candidates with a strong understanding of healthcare regulations and ethical standards.",
  },
  {
    title: "E-Commerce",
    type: "Domain",
    labelText: "Domain",
    numberOfQuestions: "120",
    introductionText:
      "AmbitionHire proudly presents a specialized E-Commerce Mastery Assessment Module tailored to identify candidates with the skills and knowledge essential for success in the dynamic and competitive field of e-commerce. This module is meticulously crafted to assess candidates for roles in e-commerce management, digital marketing, customer experience, and other e-commerce-related functions, ensuring recruiters can confidently select candidates who demonstrate expertise in key e-commerce competencies.",
    keyPersonalityTraits:
      "E-Commerce Strategy and Management: The module assesses candidates' understanding of e-commerce business models, market trends, and strategic planning, ensuring they possess the knowledge required to drive successful online retail operations. Digital Marketing Proficiency: Recruiters gain insights into candidates' digital marketing skills, including their ability to optimize online campaigns, utilize social media, and employ effective strategies for driving e-commerce traffic and sales. Customer Experience and User Interface Design: For roles focused on enhancing the online shopping experience, the module aids recruiters in identifying candidates with a strong sense of user interface design, usability, and customer-centric thinking. Data Analytics and Performance Metrics: By presenting scenarios reflective of e-commerce analytics, the module evaluates candidates' ability to analyze data, track performance metrics, and make informed decisions to optimize e-commerce operations.",
    benefits:
      "E-Commerce Management and Operations Roles: For positions involving the overall management of e-commerce platforms, the E-Commerce Mastery module aids recruiters in identifying candidates with strategic vision and operational expertise. Digital Marketing and E-Commerce Promotion Positions: Recruiters can leverage the module to assess candidates for roles in digital marketing and e-commerce promotion, ensuring they have the skills to drive online visibility and sales. User Experience and Interface Design Roles: In positions related to optimizing the user experience and interface design of e-commerce platforms, the module helps recruiters identify candidates with a strong design sensibility and customer-centric focus. Data Analytics and E-Commerce Insights Positions: For roles involving data analysis and the extraction of actionable insights for e-commerce strategy, the module assists recruiters in identifying candidates with analytical skills and a data-driven mindset.",
  },
  {
    title: "General Manufacturing",
    type: "Domain",
    labelText: "Domain",
    numberOfQuestions: "120",
    introductionText:
      "AmbitionHire introduces a General Manufacturing Excellence Assessment Module designed to identify candidates with the skills and knowledge essential for success in the dynamic and complex field of manufacturing. This module is crafted to assess candidates for roles in production management, quality control, process engineering, and other manufacturing-related functions, ensuring recruiters can confidently select candidates who demonstrate expertise in key manufacturing competencies.",
    keyPersonalityTraits:
      "Production Management and Efficiency: The module assesses candidates' understanding of production processes, resource optimization, and lean manufacturing principles, ensuring they possess the knowledge to drive efficient and cost-effective production. Quality Control and Assurance: Recruiters gain insights into candidates' ability to implement and manage quality control measures, ensuring products meet established standards and regulatory requirements. Process Engineering and Continuous Improvement: For roles focused on process optimization and continuous improvement, the module aids recruiters in identifying candidates with engineering expertise and a commitment to enhancing manufacturing processes. Safety Compliance and Risk Management: By presenting scenarios reflective of manufacturing safety and risk management, the module evaluates candidates' understanding of safety protocols, regulatory compliance, and risk mitigation in a manufacturing setting.",
    benefits:
      "Production Management and Operations Roles: For positions involving the overall management of manufacturing operations, the Manufacturing Excellence module aids recruiters in identifying candidates with strategic vision and operational expertise. Quality Control and Assurance Positions: Recruiters can leverage the module to assess candidates for roles in quality control and assurance, ensuring they have the skills to maintain high product quality standards. Process Engineering and Improvement Roles: In positions related to process engineering and continuous improvement, the module helps recruiters identify candidates with engineering skills and a focus on enhancing manufacturing efficiency. Safety Compliance and Risk Management Positions: For roles involving safety compliance and risk management in manufacturing, the module assists recruiters in identifying candidates with a strong understanding of safety protocols and risk mitigation strategies.",
  },
  {
    title: "Finance",
    type: "Domain",
    labelText: "Domain",
    numberOfQuestions: "120",
    introductionText:
      "AmbitionHire is delighted to introduce a specialized Financial Services Proficiency Assessment Module designed to identify candidates with the skills and knowledge essential for success in the dynamic and regulated field of financial services. This module is meticulously crafted to assess candidates for roles in banking, wealth management, investment advisory, and other financial services functions, ensuring recruiters can confidently select candidates who demonstrate expertise in key financial services competencies.",
    keyPersonalityTraits:
      "Financial Literacy and Product Knowledge: The module evaluates candidates' financial literacy, including their understanding of various financial products, investment instruments, and market dynamics. Customer Relationship Management: Recruiters gain insights into candidates' abilities to build and maintain customer relationships, ensuring they possess the interpersonal skills necessary for client-facing roles in financial services. Regulatory Compliance and Ethics: In the heavily regulated financial industry, the module assesses candidates' awareness of regulatory requirements and their commitment to ethical conduct and compliance standards. Risk Management and Decision-Making: By presenting scenarios reflective of financial operations, the module evaluates candidates' risk management skills, decision-making abilities, and their approach to financial problem-solving.",
    benefits:
      "Client-Facing Roles in Financial Advisory: For positions involving client interactions, financial advisory, and guiding clients in their financial decisions, the Financial Services Proficiency module aids recruiters in identifying candidates with a strong financial acumen and client-centric focus. Investment and Wealth Management Positions: Recruiters can leverage the module to assess candidates for roles in investment management, wealth management, and financial planning, ensuring they have the expertise to guide clients in their financial goals. Compliance and Risk Management Positions: In roles focused on risk management, compliance, and ensuring adherence to regulatory standards, the module assists recruiters in identifying candidates with a strong understanding of financial regulations. Financial Operations and Leadership Roles: For leadership roles in financial operations or management, the module helps recruiters evaluate candidates for their strategic thinking, leadership skills, and ability to oversee complex financial functions.",
  },

  // <<===============  Technical ===============>>

  {
    title: "Basic Computer Engineering",
    type: "Technical",
    labelText: "Technical",
    numberOfQuestions: "120",
    introductionText:
      "AmbitionHire introduces a Basic Computer Engineering Fundamentals Assessment Module designed to evaluate candidates on the essential skills and knowledge crucial for success in the field of computer engineering. This module is meticulously crafted to assess candidates for roles in software development, hardware design, system architecture, and other computer engineering-related functions, ensuring recruiters can confidently select candidates who demonstrate proficiency in key computer engineering competencies.",
    keyPersonalityTraits:
      "Programming Proficiency: The module assesses candidates' programming skills in languages such as C++, Java, or Python, ensuring they have the foundational knowledge to develop and maintain software applications. Computer Architecture Understanding: Recruiters gain insights into candidates' understanding of computer architecture, including processors, memory systems, and input/output subsystems, crucial for roles involving hardware design and system optimization. Algorithmic Problem-Solving: In the context of software development, the module evaluates candidates' ability to solve algorithmic problems efficiently, ensuring they possess the problem-solving skills essential for coding and software design. Operating System Concepts: By presenting scenarios reflective of operating system concepts, the module assesses candidates' knowledge of processes, memory management, file systems, and other fundamental aspects of operating systems.",
    benefits:
      "Software Development and Programming Roles: For positions involving software development, coding, and programming, the Computer Engineering Fundamentals module aids recruiters in identifying candidates with strong programming skills and algorithmic problem-solving abilities. Hardware Design and System Architecture Positions: Recruiters can leverage the module to assess candidates for roles in hardware design, system architecture, and optimization, ensuring they have a solid understanding of computer architecture principles. Embedded Systems and Firmware Development: In roles related to embedded systems and firmware development, the module helps recruiters identify candidates with the skills required for low-level programming and system integration. Operating System Development and Administration: For roles involving operating system development or administration, the module assists recruiters in identifying candidates with knowledge of core operating system concepts and functionalities.",
  },
  {
    title: "Networking",
    type: "Technical",
    labelText: "Technical",
    numberOfQuestions: "120",
    introductionText:
      "AmbitionHire is excited to present a specialized Networking Proficiency Assessment Module designed to evaluate candidates on the essential skills and knowledge crucial for success in the field of networking. This module is meticulously crafted to assess candidates for roles in network administration, system security, network engineering, and other networking-related functions, ensuring recruiters can confidently select candidates who demonstrate expertise in key networking competencies.",
    keyPersonalityTraits:
      "Network Configuration and Administration: The module assesses candidates' ability to configure and administer network devices, ensuring they have the skills required for efficient network management. Network Protocols Understanding: Recruiters gain insights into candidates' understanding of networking protocols such as TCP/IP, UDP, DNS, and DHCP, crucial for roles involving data communication and network troubleshooting. Network Security Knowledge: In the context of cybersecurity, the module evaluates candidates' knowledge of network security principles, including firewalls, VPNs, and intrusion detection systems, essential for safeguarding network infrastructure. Troubleshooting and Diagnostics Skills: By presenting scenarios reflective of network issues, the module assesses candidates' troubleshooting and diagnostics skills, ensuring they can identify and resolve networking challenges effectively.",
    benefits:
      "Network Administration and Management Roles: For positions involving the administration and management of computer networks, the Networking Proficiency module aids recruiters in identifying candidates with strong network configuration and maintenance skills. Network Engineering and Design Positions: Recruiters can leverage the module to assess candidates for roles in network engineering and design, ensuring they have a solid understanding of network architecture and protocols. Cybersecurity and Network Security Positions: In roles related to cybersecurity and network security, the module helps recruiters identify candidates with knowledge of security measures and the ability to implement and monitor security protocols. Network Support and Troubleshooting Roles: For positions involving network support and troubleshooting, the module assists recruiters in identifying candidates with strong diagnostic skills and the ability to address networking issues promptly.",
  },
  {
    title: "Backend - OOPS and Python",
    type: "Technical",
    labelText: "Technical",
    numberOfQuestions: "120",
    introductionText:
      "AmbitionHire is pleased to introduce a specialized Backend Development Proficiency Module designed to evaluate candidates on the essential Object-Oriented Programming (OOP) principles and Python skills crucial for success in backend development. This module is meticulously crafted to assess candidates for roles in backend engineering, server-side development, and API design, ensuring recruiters can confidently select candidates who demonstrate expertise in key backend development competencies.",
    keyPersonalityTraits:
      "Object-Oriented Programming (OOP) Concepts: The module assesses candidates' understanding of OOP principles such as encapsulation, inheritance, and polymorphism, ensuring they have a strong foundation in building modular and maintainable code. Python Programming Proficiency: Recruiters gain insights into candidates' Python programming skills, including their ability to write clean, efficient, and scalable code for backend development. Web Frameworks Knowledge: In the context of backend web development, the module evaluates candidates' knowledge of popular Python web frameworks such as Django or Flask, crucial for building robust and scalable web applications. Database Integration and Management: By presenting scenarios reflective of backend database interactions, the module assesses candidates' ability to integrate databases, write SQL queries, and manage data effectively.",
    benefits:
      "Backend Development and API Design Roles: For positions involving backend development and API design, the Backend Development Proficiency module aids recruiters in identifying candidates with strong OOP principles and Python programming skills. Web Application Development Positions: Recruiters can leverage the module to assess candidates for roles in web application development, ensuring they have the skills to build scalable and responsive backend systems. Database Integration and Management Roles: In positions related to database integration and management, the module helps recruiters identify candidates with knowledge of database systems and efficient data handling. Server-Side Development and Optimization: For roles involving server-side development and optimization, the module assists recruiters in identifying candidates with the skills to create efficient and performant backend systems.",
  },
  {
    title: "Frontend - HTML/CSS/Node/React/ViewJS/Java Script",
    type: "Technical",
    labelText: "Technical",
    numberOfQuestions: "120",
    introductionText:
      "AmbitionHire is thrilled to introduce a specialized Frontend Development Proficiency Module designed to evaluate candidates on the essential skills in HTML, CSS, Node.js, React, Vue.js, and JavaScript crucial for success in frontend development. This module is meticulously crafted to assess candidates for roles in building engaging user interfaces, responsive web design, and frontend development using popular technologies, ensuring recruiters can confidently select candidates who demonstrate expertise in key frontend development competencies.",
    keyPersonalityTraits:
      "HTML and CSS Mastery: The module assesses candidates' proficiency in HTML for structuring web content and CSS for styling, ensuring they can create visually appealing and responsive user interfaces. JavaScript Programming Skills: Recruiters gain insights into candidates' JavaScript programming skills, including their ability to create dynamic and interactive frontend components. Node.js and Server-Side JavaScript Knowledge: In the context of server-side development, the module evaluates candidates' understanding of Node.js, crucial for building scalable and efficient server-side applications. React and Vue.js Frameworks Proficiency: By presenting scenarios reflective of React and Vue.js development, the module assesses candidates' ability to build modern and modular frontend applications.",
    benefits:
      "Frontend Development and UI/UX Design Roles: For positions involving frontend development and UI/UX design, the Frontend Development Proficiency module aids recruiters in identifying candidates with strong skills in HTML, CSS, and JavaScript. Single Page Application (SPA) Development Positions: Recruiters can leverage the module to assess candidates for roles in developing Single Page Applications (SPAs) using React or Vue.js, ensuring they have the skills to create interactive and seamless user experiences. Node.js and Full Stack Development Roles: In positions related to full-stack development and server-side JavaScript using Node.js, the module helps recruiters identify candidates with knowledge of server-side technologies. Web Application Optimization: For roles involving frontend optimization and performance, the module assists recruiters in identifying candidates with the skills to create efficient and responsive frontend systems.",
  },
  {
    title: "Linux",
    type: "Technical",
    labelText: "Technical",
    numberOfQuestions: "120",
    introductionText:
      "AmbitionHire is pleased to present a specialized Linux Proficiency Assessment Module designed to evaluate candidates on essential skills and knowledge crucial for success in Linux system administration and operations. This module is meticulously crafted to assess candidates for roles in Linux administration, server management, and system optimization, ensuring recruiters can confidently select candidates who demonstrate expertise in key Linux competencies.",
    keyPersonalityTraits:
      "Linux Command Line Mastery: The module assesses candidates' proficiency in using the Linux command line, ensuring they have the skills to navigate, manage files, and execute commands efficiently. System Configuration and Administration: Recruiters gain insights into candidates' ability to configure and administer Linux systems, ensuring they can handle tasks such as user management, permissions, and system monitoring. Scripting and Automation Skills: In the context of system automation, the module evaluates candidates' scripting skills, including their ability to write scripts in languages like Bash, Python, or Perl for automating routine tasks. Server Security and Hardening: By presenting scenarios reflective of server security, the module assesses candidates' knowledge of Linux security practices, including server hardening, access control, and security auditing.",
    benefits:
      "Linux System Administration Roles: For positions involving Linux system administration and management, the Linux Proficiency module aids recruiters in identifying candidates with strong command line and system configuration skills. Server Operations and Optimization Positions: Recruiters can leverage the module to assess candidates for roles in server operations and optimization, ensuring they have the skills to maintain and optimize Linux servers for performance. DevOps and Automation Roles: In positions related to DevOps and automation, the module helps recruiters identify candidates with scripting and automation skills crucial for streamlining operational tasks. Linux Security and Compliance Positions: For roles involving Linux server security and compliance, the module assists recruiters in identifying candidates with knowledge of security best practices and server hardening.",
  },
  {
    title: "AWS",
    type: "Technical",
    labelText: "Technical",
    numberOfQuestions: "120",
    introductionText:
      "AmbitionHire is excited to introduce a specialized AWS (Amazon Web Services) Proficiency Assessment Module designed to evaluate candidates on essential skills and knowledge crucial for success in cloud computing and AWS services. This module is meticulously crafted to assess candidates for roles in cloud architecture, DevOps, system administration, and other AWS-related functions, ensuring recruiters can confidently select candidates who demonstrate expertise in key AWS competencies.",
    keyPersonalityTraits:
      "Cloud Architecture and Infrastructure Design: The module assesses candidates' proficiency in designing scalable and cost-effective cloud architectures using AWS services, ensuring they can architect solutions that meet business requirements. AWS Service Knowledge: Recruiters gain insights into candidates' knowledge of various AWS services, including compute, storage, database, networking, and machine learning, crucial for roles involving diverse cloud functionalities. Infrastructure as Code (IaC) Skills: In the context of DevOps practices, the module evaluates candidates' skills in using Infrastructure as Code tools such as AWS CloudFormation or Terraform for automating infrastructure deployment and management. Security and Compliance Practices: By presenting scenarios reflective of AWS security, the module assesses candidates' understanding of AWS security practices, identity and access management, and compliance with AWS security standards.",
    benefits:
      "Cloud Architecture and Solutions Design Roles: For positions involving cloud architecture and solutions design using AWS, the AWS Proficiency module aids recruiters in identifying candidates with strong knowledge of AWS services and infrastructure design. DevOps and Cloud Automation Positions: Recruiters can leverage the module to assess candidates for roles in DevOps and cloud automation, ensuring they have the skills to automate deployment, scaling, and management of AWS resources. AWS Operations and Administration Roles: In positions related to AWS operations and administration, the module helps recruiters identify candidates with proficiency in managing and optimizing AWS environments. Cloud Security and Compliance Positions: For roles involving AWS security and compliance, the module assists recruiters in identifying candidates with knowledge of security best practices, identity management, and compliance standards in AWS.",
  },
  {
    title: "UI/UX Designer",
    type: "Technical",
    labelText: "Technical",
    numberOfQuestions: "120",
    introductionText:
      "AmbitionHire is delighted to introduce a specialized UI/UX Design Proficiency Module designed to evaluate candidates on the essential skills and knowledge crucial for success in user interface (UI) and user experience (UX) design. This module is meticulously crafted to assess candidates for roles in creating visually appealing and user-centric designs, ensuring recruiters can confidently select candidates who demonstrate expertise in key UI/UX competencies.",
    keyPersonalityTraits:
      "UI Design Mastery: The module assesses candidates' proficiency in UI design, evaluating their ability to create visually appealing and intuitive user interfaces that align with industry best practices. UX Design Principles: Recruiters gain insights into candidates' understanding of UX design principles, including user research, wireframing, prototyping, and usability testing, crucial for delivering exceptional user experiences. Design Tools Proficiency: In the context of design tools, the module evaluates candidates' proficiency in industry-standard software such as Adobe XD, Sketch, Figma, or InVision, ensuring they can effectively translate design concepts into digital prototypes. Responsive and Mobile Design: By presenting scenarios reflective of responsive and mobile design, the module assesses candidates' ability to create designs that are responsive across various devices and screen sizes.",
    benefits:
      "UI/UX Design Roles: For positions involving both UI and UX design, the UI/UX Design Proficiency module aids recruiters in identifying candidates with strong skills in creating visually appealing designs and optimizing user experiences. User Research and Prototyping Positions: Recruiters can leverage the module to assess candidates for roles involving user research, prototyping, and usability testing, ensuring they have the skills to gather insights and iterate on designs. Design Collaboration and Communication Roles: In positions related to design collaboration and communication, the module helps recruiters identify candidates with proficiency in design tools and effective communication of design concepts. Responsive and Mobile Design Positions: For roles involving the creation of designs that are responsive and mobile-friendly, the module assists recruiters in identifying candidates with knowledge of designing for various devices and platforms.",
  },

  // <<===============  Language ===============>>

  {
    title: "Read & Repeat",
    type: "Language",
    labelText: "Language",
    numberOfQuestions: "180",
    introductionText:
      "The English Read and Repeat assessment module by AmbitionHire is a dynamic tool designed to evaluate candidates' language fluency through a combination of reading and speaking exercises. Candidates are presented with a series of diverse sentences tailored to assess grammar, pronunciation, and overall language proficiency. Using their device's microphone, candidates read each sentence aloud, allowing the module to capture and analyze their spoken responses. This module not only tests the candidate's ability to comprehend written content but also offers a real-time demonstration of their verbal communication skills, including pronunciation, intonation, and clarity.",
    keyPersonalityTraits:
      "Accurate Language Fluency Assessment: The module provides a reliable measure of a candidate's language fluency, going beyond traditional assessments. Recruiters gain insights into how well candidates can articulate and express themselves verbally in English. Real-world Communication Simulation: By incorporating spoken responses, the module simulates real-world communication scenarios, allowing recruiters to gauge how effectively candidates can convey ideas, respond to questions, and engage in verbal interactions. Pronunciation and Accent Evaluation: Recruiters can assess candidates' pronunciation, accents, and overall spoken language proficiency. This is particularly valuable for roles where clear verbal communication is a critical aspect, such as customer-facing positions or roles requiring collaboration with global teams. Enhanced Candidate Understanding: The module provides recruiters with a more holistic understanding of a candidate's language abilities. This insight goes beyond traditional written assessments, enabling recruiters to make more informed decisions regarding a candidate's overall communication competence.",
    benefits:
      "Client-Facing Roles: For positions requiring direct client interactions, the Read and Repeat module is beneficial in evaluating a candidate's ability to communicate clearly and professionally with clients or customers. Multinational Teams: When recruiting for roles in multinational teams, the module helps assess a candidate's spoken English proficiency, ensuring effective communication within a diverse and globalized work environment. Tele-sales or Customer Support: In roles where verbal communication is a primary function, such as tele-sales or customer support, the module aids recruiters in identifying candidates with strong spoken language skills. Leadership Positions: For leadership roles that involve presentations, public speaking, or team communication, the module provides valuable insights into a candidate's ability to articulate ideas with confidence and clarity.",
  },
  {
    title: "Listen & Repeat",
    type: "Language",
    labelText: "Language",
    numberOfQuestions: "180",
    introductionText:
      "AmbitionHire introduces the specialized Listen & Repeat Proficiency Module, designed to assess candidates' English language fluency through audio interactions. In this module, candidates receive audio files containing sentences in English, and they are prompted to record themselves speaking the sentences. This module is meticulously crafted to evaluate candidates' pronunciation, intonation, and overall English language proficiency, ensuring recruiters can confidently select candidates who demonstrate effective verbal communication skills.",
    keyPersonalityTraits:
      "Pronunciation and Accent Evaluation: The module assesses candidates' pronunciation accuracy and their ability to articulate English sentences clearly, providing recruiters with insights into their spoken language proficiency. Intonation and Fluency Assessment: Recruiters gain insights into candidates' intonation patterns and overall fluency in spoken English, ensuring they can effectively communicate and convey information. Verbal Communication Skills: In the context of spoken language, the module evaluates candidates' verbal communication skills, including their ability to express ideas and information coherently. Language Proficiency Benchmarking: By presenting a range of sentences with varying complexity, the module allows recruiters to benchmark candidates' language proficiency levels, aiding in the selection of candidates aligned with language requirements for specific roles.",
    benefits:
      "Customer-Facing Roles with Verbal Communication: For positions involving customer interactions and verbal communication, the Listen & Repeat module aids recruiters in identifying candidates with clear and effective spoken English. Telesales and Telecommunication Positions: Recruiters can leverage the module to assess candidates for roles requiring telephonic communication, ensuring they have the language proficiency necessary for effective conversations. Multilingual Team Collaboration: In positions involving collaboration with multilingual teams, the module helps recruiters identify candidates with strong English language skills, fostering effective communication within diverse work environments. Language Proficiency Benchmarking: For roles where specific language proficiency is a requirement, the module assists recruiters in benchmarking candidates against predefined language standards, streamlining the candidate selection process.",
  },
  {
    title: "Listen & Type",
    type: "Language",
    labelText: "Language",
    numberOfQuestions: "180",
    introductionText:
      "AmbitionHire presents the innovative Listen & Type Proficiency Module, designed to evaluate candidates' language skills through auditory comprehension and accurate transcription. In this module, candidates listen to audio passages in English and are prompted to type what they hear. This module is meticulously crafted to assess candidates' listening accuracy, language comprehension, and typing proficiency, ensuring recruiters can confidently select candidates who demonstrate effective written communication skills.",
    keyPersonalityTraits:
      "Listening Accuracy and Comprehension: The module assesses candidates' ability to accurately listen to and comprehend spoken English, providing insights into their auditory comprehension skills. Typing Proficiency and Speed: Recruiters gain insights into candidates' typing proficiency and speed as they transcribe audio passages, ensuring they can efficiently translate auditory information into written text. Written Communication Skills: In the context of written communication, the module evaluates candidates' ability to articulate and express ideas clearly and accurately through typing. Language Proficiency Benchmarking: By presenting a range of audio passages with varying complexity, the module allows recruiters to benchmark candidates' language proficiency levels, aiding in the selection of candidates aligned with language requirements for specific roles.",
    benefits:
      "Roles Requiring Strong Listening and Typing Skills: For positions involving tasks where strong listening and accurate typing skills are essential, such as transcription roles, the Listen & Type module aids recruiters in identifying candidates with the required proficiency. Written Communication-Centric Positions: Recruiters can leverage the module to assess candidates for roles where effective written communication is crucial, ensuring they can accurately transcribe and communicate information. Multilingual Team Collaboration: In positions involving collaboration with multilingual teams, the module helps recruiters identify candidates with strong English language skills, especially in written form. Language Proficiency Benchmarking: For roles where specific language proficiency is a requirement, the module assists recruiters in benchmarking candidates against predefined language standards, streamlining the candidate selection process.",
  },
  {
    title: "Passage",
    type: "Language",
    labelText: "Language",
    numberOfQuestions: "180",
    introductionText:
      "AmbitionHire introduces the Reading Comprehension Proficiency Module, designed to evaluate candidates' ability to comprehend and analyze written English passages. In this module, candidates read a carefully curated passage and are prompted to answer a series of questions based on the content. This module assesses candidates' reading comprehension, critical thinking, and analytical skills, enabling recruiters to confidently select candidates who demonstrate proficiency in understanding written information.",
    keyPersonalityTraits:
      "Reading Comprehension Skills: The module assesses candidates' ability to understand and extract information from written passages, providing insights into their reading comprehension skills. Critical Thinking and Analysis: Recruiters gain insights into candidates' critical thinking and analytical skills as they respond to questions that require deeper understanding and interpretation of the passage. Written Communication Proficiency: In the context of written responses, the module evaluates candidates' ability to articulate their thoughts and convey information coherently, providing a snapshot of their written communication proficiency. Content Understanding and Retention: By presenting questions that probe candidates' understanding of specific details and overarching themes, the module allows recruiters to gauge how well candidates comprehend and retain information from the passage.",
    benefits:
      "Roles Requiring Strong Reading and Analysis Skills: For positions involving tasks where strong reading, analysis, and interpretation skills are essential, such as roles in research, analysis, or content comprehension, the Reading Comprehension module aids recruiters in identifying candidates with the required proficiency. Content-Centric Positions: Recruiters can leverage the module to assess candidates for roles where the ability to comprehend and analyze written content is crucial, ensuring they can effectively engage with and interpret informational materials. Research and Documentation Roles: In positions involving research and documentation, the module helps recruiters identify candidates with strong reading comprehension and analytical skills, essential for gathering and synthesizing information. Language Proficiency Benchmarking: For roles where specific language proficiency is a requirement, the module assists recruiters in benchmarking candidates against predefined language standards, streamlining the candidate selection process.",
  },
  {
    title: "Grammar",
    type: "Language",
    labelText: "Language",
    numberOfQuestions: "180",
    introductionText:
      "AmbitionHire presents the Grammar Mastery Proficiency Module, tailored to assess candidates' command over English grammar through sentence completion exercises. In this module, candidates encounter sentences with blanks and are tasked with filling them in with the most suitable and grammatically correct words. This module meticulously evaluates candidates' grammatical accuracy, vocabulary, and language precision, enabling recruiters to confidently select candidates who demonstrate excellence in English grammar.",
    keyPersonalityTraits:
      "Grammatical Accuracy: The module assesses candidates' proficiency in selecting the most grammatically appropriate words to complete sentences, providing insights into their mastery of English grammar rules. Vocabulary Precision: Recruiters gain insights into candidates' vocabulary and language precision as they choose words that seamlessly fit into the context of the sentences, showcasing their ability to use language accurately. Sentence Structure Understanding: In the context of completing sentences, the module evaluates candidates' understanding of sentence structure, ensuring they can construct grammatically sound and coherent sentences. Language Proficiency Benchmarking: By presenting sentences with varying complexity, the module allows recruiters to benchmark candidates' language proficiency levels, aiding in the selection of candidates aligned with language requirements for specific roles.",
    benefits:
      "Roles Requiring Strong Grammar Skills: For positions where strong grammar skills are essential, such as roles involving content creation, editing, or proofreading, the Grammar Mastery module aids recruiters in identifying candidates with a high level of grammatical proficiency. Written Communication-Centric Positions: Recruiters can leverage the module to assess candidates for roles where precise written communication is crucial, ensuring they can articulate ideas in a grammatically correct and polished manner. Editorial and Copywriting Roles: In positions involving editorial tasks and copywriting, the module helps recruiters identify candidates with a keen eye for grammatical details, essential for creating high-quality written content. Language Proficiency Benchmarking: For roles where specific language proficiency is a requirement, the module assists recruiters in benchmarking candidates against predefined language standards, streamlining the candidate selection process.",
  },
  {
    title: "Free Speech",
    type: "Language",
    labelText: "Language",
    numberOfQuestions: "180",
    introductionText:
      "AmbitionHire introduces the Free Speech Expression Module, a dynamic assessment designed to evaluate candidates' ability to articulate thoughts and ideas verbally. In this module, candidates receive a given topic and are tasked with speaking about it for approximately half a minute to one minute. This module assesses candidates' verbal communication skills, clarity of expression, and their capacity to convey information persuasively, allowing recruiters to confidently select candidates who excel in spontaneous verbal communication.",
    keyPersonalityTraits:
      "Verbal Communication Skills: The module assesses candidates' proficiency in conveying information verbally, providing insights into their ability to articulate thoughts and ideas clearly and coherently. Spontaneous Expression: Recruiters gain insights into candidates' ability to think on their feet and express ideas spontaneously, showcasing their capacity to communicate effectively in unscripted scenarios. Persuasive Communication: In the context of speaking about a given topic, the module evaluates candidates' ability to present information persuasively, ensuring they can engage an audience and convey ideas convincingly. Presentation Confidence: By allowing candidates to freely express themselves on a given topic, the module helps recruiters gauge candidates' confidence in public speaking and their overall comfort with verbal expression.",
    benefits:
      "Roles Requiring Strong Verbal Communication: For positions where strong verbal communication is paramount, such as client-facing roles, sales, or roles involving presentations, the Free Speech Expression module aids recruiters in identifying candidates with effective verbal communication skills. Public-Facing Positions: Recruiters can leverage the module to assess candidates for roles that involve public speaking or interaction with diverse audiences, ensuring they can confidently and effectively communicate ideas. Leadership and Team Collaboration Roles: In positions involving leadership or collaborative teamwork, the module helps recruiters identify candidates with the ability to express ideas clearly and lead discussions. Language Proficiency Benchmarking: For roles where specific language proficiency is a requirement, the module assists recruiters in benchmarking candidates against predefined language standards, streamlining the candidate selection process.",
  },
  {
    title: "Error Identification",
    type: "Language",
    labelText: "Language",
    numberOfQuestions: "180",
    introductionText:
      "AmbitionHire introduces the Error Identification Proficiency Module, designed to assess candidates' keenness for grammatical accuracy and language precision. In this module, candidates are presented with sentences containing errors, and they are tasked with selecting the correct version from a set of four options. This module meticulously evaluates candidates' grammatical awareness, language proficiency, and their ability to identify and rectify errors, allowing recruiters to confidently select candidates who excel in maintaining linguistic precision.",
    keyPersonalityTraits:
      "Grammatical Awareness: The module assesses candidates' proficiency in recognizing and rectifying grammatical errors, providing insights into their keenness for grammatical accuracy. Language Precision: Recruiters gain insights into candidates' precision in language use as they choose correct versions of sentences, showcasing their understanding of grammar rules and language nuances. Error Correction Skills: In the context of identifying errors, the module evaluates candidates' ability to spot and correct linguistic inaccuracies, ensuring they can maintain high standards of written communication. Language Proficiency Benchmarking: By presenting sentences with varying complexity, the module allows recruiters to benchmark candidates' language proficiency levels, aiding in the selection of candidates aligned with language requirements for specific roles.",
    benefits:
      "Roles Requiring Strong Language Proficiency: For positions where strong language proficiency is essential, such as content creation, editing, or roles involving written communication, the Error Identification module aids recruiters in identifying candidates with a high level of linguistic precision. Editing and Proofreading Positions: Recruiters can leverage the module to assess candidates for roles involving editing and proofreading, ensuring they possess the skills to identify and correct grammatical errors in written content. Content-Creation Roles: In positions involving content creation, the module helps recruiters identify candidates with a keen eye for language precision, essential for creating high-quality written material. Language Proficiency Benchmarking: For roles where specific language proficiency is a requirement, the module assists recruiters in benchmarking candidates against predefined language standards, streamlining the candidate selection process.",
  },
  {
    title: "Fill in the Missing Phrase",
    type: "Language",
    labelText: "Language",
    numberOfQuestions: "180",
    introductionText:
      "AmbitionHire presents the Fill in the Missing Phrase Proficiency Module, a targeted assessment designed to evaluate candidates' lexical precision and contextual understanding. In this module, candidates are provided with English sentences containing blanks, and they are tasked with selecting the most suitable word or phrase to fill the gap from a set of four options. This module meticulously evaluates candidates' vocabulary, language comprehension, and their ability to select contextually appropriate words, enabling recruiters to confidently select candidates with a nuanced understanding of language usage.",
    keyPersonalityTraits:
      "Vocabulary and Lexical Precision: The module assesses candidates' proficiency in selecting the most suitable words or phrases to complete sentences, providing insights into their vocabulary and lexical precision. Contextual Understanding: Recruiters gain insights into candidates' ability to comprehend the context of sentences, ensuring they can choose words or phrases that align with the overall meaning and tone of the sentence. Language Nuances: In the context of completing sentences, the module evaluates candidates' awareness of language nuances, ensuring they can choose words that fit seamlessly into the given sentence structure. Language Proficiency Benchmarking: By presenting sentences with varying complexity, the module allows recruiters to benchmark candidates' language proficiency levels, aiding in the selection of candidates aligned with language requirements for specific roles.",
    benefits:
      "Roles Requiring Strong Lexical Skills: For positions where strong lexical skills are essential, such as roles involving content creation, copywriting, or written communication, the Fill in the Missing Phrase module aids recruiters in identifying candidates with a rich vocabulary. Content-Creation and Copywriting Positions: Recruiters can leverage the module to assess candidates for roles involving content creation and copywriting, ensuring they possess the skills to select words or phrases that enhance the overall impact of written content. Marketing and Branding Roles: In positions involving marketing and branding, the module helps recruiters identify candidates with a keen understanding of language nuances, essential for crafting compelling and brand-aligned messaging. Language Proficiency Benchmarking: For roles where specific language proficiency is a requirement, the module assists recruiters in benchmarking candidates against predefined language standards, streamlining the candidate selection process.",
  },
  {
    title: "Jumbled Words",
    type: "Language",
    labelText: "Language",
    numberOfQuestions: "180",
    introductionText:
      "AmbitionHire introduces the Sentence Reconstruction Proficiency Module, a challenging assessment designed to evaluate candidates' grammatical and syntactical prowess. In this module, candidates are presented with a jumble of words and are tasked with reconstructing them into a grammatically and syntactically correct English sentence. This module meticulously assesses candidates' understanding of sentence structure, language composition, and their ability to arrange words logically, enabling recruiters to confidently select candidates who exhibit a high level of language proficiency.",
    keyPersonalityTraits:
      "Sentence Structure Mastery: The module assesses candidates' proficiency in understanding and reconstructing sentence structures, providing insights into their grasp of grammatical and syntactical principles. Language Composition Skills: Recruiters gain insights into candidates' ability to compose sentences coherently, ensuring they can arrange words logically to convey meaning effectively. Syntax Understanding: In the context of reconstructing sentences, the module evaluates candidates' awareness of syntax rules, ensuring they can create grammatically sound and syntactically correct English sentences. Language Proficiency Benchmarking: By presenting sentences with varying complexity, the module allows recruiters to benchmark candidates' language proficiency levels, aiding in the selection of candidates aligned with language requirements for specific roles.",
    benefits:
      "Roles Requiring Strong Language Composition Skills: For positions where strong language composition skills are essential, such as content creation, editing, or roles involving written communication, the Sentence Reconstruction module aids recruiters in identifying candidates with a keen sense of language structure. Editing and Proofreading Positions: Recruiters can leverage the module to assess candidates for roles involving editing and proofreading, ensuring they possess the skills to construct grammatically and syntactically correct sentences. Content-Creation and Copywriting Roles: In positions involving content creation and copywriting, the module helps recruiters identify candidates with the ability to craft well-structured and grammatically sound sentences. Language Proficiency Benchmarking: For roles where specific language proficiency is a requirement, the module assists recruiters in benchmarking candidates against predefined language standards, streamlining the candidate selection process.",
  },

  // <<===============  Psychometric ===============>>

  {
    title: "Basic Personality Traits",
    type: "Psychometric",
    labelText: "Psychometric",
    numberOfQuestions: "180",
    introductionText:
      "AmbitionHire is proud to introduce the Basic Psychometric Personality Assessment Module, a comprehensive tool designed to delve into fundamental aspects of candidates' personalities. This module aims to assess behavioral tendencies and attitudes, offering valuable insights into core characteristics that influence job fit. The assessment covers a range of traits, including Extrovert vs. Introvert, Practical vs. Impractical, Optimistic vs. Pessimistic, Industrious vs. Expedient, and Openness vs. Close-Mindedness.",
    keyPersonalityTraits:
      "Extrovert vs. Introvert: Evaluates candidates' social orientation, determining whether they thrive in social settings and draw energy from interactions (Extrovert) or prefer quieter, more reflective environments (Introvert). Practical vs. Impractical: Assesses candidates' approach to problem-solving and decision-making, indicating whether they tend to be pragmatic and realistic (Practical) or more inclined towards imaginative and unconventional ideas (Impractical). Optimistic vs. Pessimistic: Gauges candidates' outlook on life and challenges, revealing whether they exhibit a positive and hopeful attitude (Optimistic) or tend towards a more cautious and pessimistic perspective (Pessimistic). Industrious vs. Expedient: Examines candidates' work ethic and approach to tasks, indicating whether they are diligent, hardworking, and methodical (Industrious) or prefer efficiency and shortcuts (Expedient). Openness vs. Close-Mindedness: Assesses candidates' receptiveness to new ideas, experiences, and perspectives, revealing whether they are open-minded, curious, and adaptable (Openness) or more inclined towards tradition and familiarity (Close-Mindedness).",
    benefits:
      "Enhanced Job Fit: The module provides recruiters with a nuanced understanding of candidates' personalities, aiding in identifying individuals whose traits align with the specific requirements and culture of the organization. Team Dynamics: By uncovering key personality traits, recruiters can strategically build teams with diverse yet complementary characteristics, fostering a collaborative and effective work environment. Customized Coaching and Development: Insights gained from the assessment can inform personalized coaching and development plans, helping individuals maximize their potential and contribute positively to the organization. Cultural Alignment: Understanding candidates' fundamental attitudes and behaviors allows recruiters to gauge their potential alignment with the organization's values and culture, ensuring a harmonious fit. Informed Decision-Making: Recruiters can make more informed hiring decisions by considering not just technical skills but also the personality traits that contribute to success in specific roles and within the organizational context.",
  },
  {
    title: "Specific Personality Traits",
    type: "Psychometric",
    labelText: "Psychometric",
    numberOfQuestions: "180",
    introductionText:
      "AmbitionHire is excited to present the Specific Personality Traits Psychometric Assessment Module, a specialized tool meticulously crafted to evaluate targeted personality traits crucial for success in the professional landscape. This module offers in-depth insights into candidates' strengths and areas for growth in specific competencies that significantly impact their professional journey. The assessment encompasses traits such as Stress Tolerance, Motivation, Resilience, Leadership, Perseverance & Grit, and Collaboration/Teamwork.",
    keyPersonalityTraits:
      "Stress Tolerance: Evaluates candidates' ability to manage and thrive in high-pressure situations, providing insights into their resilience and composure in challenging environments. Motivation: Assesses candidates' drive and enthusiasm in pursuing professional goals, revealing their intrinsic motivation and commitment to achieving success. Resilience: Gauges candidates' capacity to bounce back from setbacks and navigate challenges, indicating their resilience and adaptability in the face of adversity. Leadership: Examines candidates' leadership potential, identifying individuals who exhibit the qualities necessary to guide and inspire others towards common goals. Perseverance & Grit: Assesses candidates' determination and persistence in overcoming obstacles, providing insights into their ability to stay focused on long-term objectives. Collaboration/Teamwork: Evaluates candidates' aptitude for working effectively in a team, showcasing their ability to communicate, cooperate, and contribute to collective success.",
    benefits:
      "Tailored Development Plans: The module equips recruiters with a nuanced understanding of candidates' strengths and areas for improvement in targeted competencies, enabling the creation of personalized development plans. Strategic Team Formation: By uncovering specific competencies, recruiters can strategically assemble teams with complementary strengths, fostering a collaborative and high-performing work environment. Leadership Identification: Identifying candidates with inherent leadership qualities aids in selecting individuals who can effectively lead teams and contribute to organizational growth. Employee Engagement: Insights gained from the assessment can inform strategies to enhance employee engagement by aligning individuals with roles that leverage their strengths and foster professional fulfillment. Succession Planning: Understanding candidates' competencies enables recruiters to identify potential leaders and contributors, facilitating effective succession planning for key positions.",
  },
  {
    title: "IQ Test",
    type: "Psychometric",
    labelText: "Psychometric",
    numberOfQuestions: "180",
    introductionText:
      "AmbitionHire introduces the IQ Test Module, a sophisticated tool designed to measure candidates' cognitive abilities across diverse domains. This comprehensive assessment evaluates candidates' problem-solving skills and intellectual capabilities, providing recruiters with a detailed view of their cognitive strengths. The module covers a range of traits including Naturalistic, Pattern Completion, Logical, Existential, Interpersonal, Linguistic, Logical Sequence, Intra Personal, Spatial Intelligence, Analogies, and Series Completion.",
    keyPersonalityTraits:
      "AmbitionHire introduces the IQ Test Module, a sophisticated tool designed to measure candidates' cognitive abilities across diverse domains. This comprehensive assessment evaluates candidates' problem-solving skills and intellectual capabilities, providing recruiters with a detailed view of their cognitive strengths. The module covers a range of traits including Naturalistic, Pattern Completion, Logical, Existential, Interpersonal, Linguistic, Logical Sequence, Intra Personal, Spatial Intelligence, Analogies, and Series Completion.",
    benefits:
      "Holistic Cognitive Profiling: The module provides recruiters with a holistic view of candidates' cognitive strengths, allowing for a more comprehensive understanding of their problem-solving and intellectual capabilities. Strategic Talent Placement: By uncovering specific cognitive traits, recruiters can strategically place individuals in roles that align with their cognitive strengths, maximizing their potential contribution to the organization. Skill Development Strategies: Insights gained from the assessment can inform tailored skill development strategies, enabling recruiters to focus on enhancing specific cognitive abilities in employees. Task Assignment Optimization: Understanding candidates' cognitive strengths allows recruiters to optimize task assignments, ensuring individuals are assigned responsibilities that align with their natural aptitudes. Informed Decision-Making: Recruiters can make more informed decisions by considering not only technical skills but also the cognitive abilities that contribute to success in specific roles and within the organizational context.",
  },
];

export const labelColors = {
  General: { textColor: "blue-[#8CB56C]", bg: "bg-[#F2FCEA]" },
  Domain: { textColor: "text-[#FF7604]", bg: "bg-[#FFF4EB]" },
  Language: { textColor: "text-[#B654BD]", bg: "bg-[#FAF2FA]" },
  Technical: { textColor: "text-[#D22222]", bg: "bg-[#FCEEEE]" },
  Psychometric: { textColor: "text-[#D22222]", bg: "bg-[#FCEEEE]" },
};

export default labelColors;
