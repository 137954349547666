// Hire component
import React, { useEffect, useState } from "react";
import { Hire_Card } from "../../../ui/cards";
import UserInterface from "./userInterface";
import QuestionBank from "./questionBank";
import Customize from "./customize";
import CustomerSupport from "./customerSupport";
import ATS from "./ATS";
import up from "../../../asset/home/chevron-up.png";
import down from "../../../asset/home/chevron-down.png";
import { motion } from "framer-motion";
import { CurasolButton } from "../../../ui/buttons";
function Hire() {
  const card_data = [
    {
      title: "Massive Question Bank",
      description:
        "Choose from over 40 modules with each module containing 100+ questions",
      component: QuestionBank,
    },
    {
      title: "Intuitive User Interface",
      description:
        "Seamlessly create and manage your jobs using our user-friendly platform",
      bg: "#F0FAFC",
      component: UserInterface,
    },
    {
      title: "Customizable Product",
      description:
        "Customization options to fit your organization’s design theme",
      component: Customize,
    },
    {
      title: "24/7 Customer Support",
      description:
        "Here for you to answer/solve your queries anytime of the day",
      component: CustomerSupport,
    },
    {
      title: "ATS Integration",
      description:
        "Put your recruitment process on auto-pilot with Ambition Hire ATS integration",
      component: ATS,
    },
  ];

  const [selectedCardIndex, setSelectedCardIndex] = useState(0);
  const [intervalId, setIntervalId] = useState(null);
  const handleCardClick = (index) => {
    clearInterval(intervalId); // Clear the existing interval
    setSelectedCardIndex(index);
    setNewInterval(); // Set a new interval with the updated selected card index
  };

  const handleUpClick = () => {
    clearInterval(intervalId); // Clear the existing interval
    const newIndex =
      (selectedCardIndex - 1 + card_data.length) % card_data.length;
    setSelectedCardIndex(newIndex);
    setNewInterval(); // Set a new interval with the updated selected card index
  };

  const handleDownClick = () => {
    clearInterval(intervalId); // Clear the existing interval
    const newIndex = (selectedCardIndex + 1) % card_data.length;
    setSelectedCardIndex(newIndex);
    setNewInterval(); // Set a new interval with the updated selected card index
  };

  const setNewInterval = () => {
    const newIntervalId = setInterval(() => {
      setSelectedCardIndex((prevIndex) => (prevIndex + 1) % card_data.length);
    }, 20000);
    setIntervalId(newIntervalId);
  };

  useEffect(() => {
    setNewInterval(); // Set the initial interval
    // Clear the interval on component unmount to avoid memory leaks
    return () => clearInterval(intervalId);
  }, [card_data.length]);

  return (
    <div className="wrapper mb-10 sm:mx-[119px] xl:mx-auto">
      <h2 className="section_heading mt-[100px]">
        Why should you choose Ambition Hire?
      </h2>
      <div className="flex mt-[80px] gap-[24px] flex-col lg:flex-row">
        {/* Left part */}
        <div className="flex flex-col gap-y-5 order-2 lg:order-2">
          {card_data.map((card, index) => (
            <div
              className={`${
                selectedCardIndex === index ? "" : "hidden lg:block"
              }`}
            >
              <Hire_Card
                key={card.title}
                title={card.title}
                description={card.description}
                count={index + 1}
                onClick={() => handleCardClick(index)}
                isSelected={selectedCardIndex === index}
              />
            </div>
          ))}
        </div>

        {/* right Part , render here selected menu component*/}
        {selectedCardIndex !== null && (
          <div className="flex-1 p-0 order-2 lg:order-2 ">
            {React.createElement(card_data[selectedCardIndex].component)}
          </div>
        )}

        <CurasolButton
          length={card_data.length}
          setCurrentIndex={setSelectedCardIndex}
        />
      </div>
    </div>
  );
}

export default Hire;
