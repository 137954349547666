import React, { useState } from 'react'
import { Offer_Box } from '../../../../ui/cards';
import main1 from '../../../../asset/home/candidateInvitation/main1.png';
import main2 from '../../../../asset/home/candidateInvitation/main2.png';
import { motion } from 'framer-motion';
export default function CandidateInvitation() {
    return (
        <>
            {/* tab text */}
            <div className='flex-1'>
                <h4 className='text-blue-light text-[16px] lg:text-[24px] font-500'>A powerful tool to address the sourcing challenges faced by the recruiters</h4>
                <div className='mt-[24px] lg:mt-[48px] flex flex-col gap-4 lg:gap-6'>
                    <Offer_Box title="Batch Upload Performance" desc="With a single click, reach out to a multitude of candidates. Extend invitations to thousands at once for seamless participation in your job assessments" />
                    <Offer_Box title="QR code Generation In-sights" desc="Propagate your job QR code across your social media platforms, allowing candidates to effortlessly scan and apply or complete assessments" />
                </div>
            </div>
            {/* tab images */}
            <motion.div
                initial={{ x: 1000 }}
                animate={{ x: 0 }}
                transition={{ type: 'spring', stiffness: 100, damping: 30, mass: 5}}
                className='flex-1 hidden sm:block overflow-hidden'>
                <img src={main1} className='lg:translate-x-[60px]' />
                <img src={main2} className='t translate-y-[-120px] lg:translate-y-[-95px] scale-75 lg:scale-100' />
            </motion.div>
        </>
    )
};
