// Label.js
import React from 'react';
import { labelColors } from '../../data/questionLibraryData';
import { motion } from 'framer-motion';
function QuestionLabel({ type, labelText }) {
  const { textColor, bg: bgColor } = labelColors[type];
  return (
    <motion.span
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      transition={{ duration: .1 }}
      className={`${textColor} ${bgColor} py-[6px] lg:py-2 px-4 rounded-[32px] font-700 leading-[21.6px] text-[12px] lg:text-[16px] text-center whitespace-nowrap w-full sm:w-auto`}>
      {labelText}
    </motion.span>
  );
}

export default QuestionLabel;
