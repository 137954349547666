// PageLayout.js
import React from 'react';
import Header from '../../components/header';
import ContactUs from '../../components/contactUs';
import Footer from '../../components/footer';
import { RevealAnimation } from './index'
const PageLayout = ({ children }) => (
    <>
        {/* <Header /> */}
        {children}
        <RevealAnimation>
            <ContactUs />
        </RevealAnimation>
        <RevealAnimation>
            <Footer />
        </RevealAnimation>
    </>
);

export default PageLayout;
