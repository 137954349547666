import React from 'react'
function ChevronRight({color}) {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="chevron-right">
    <path id="Icon" d="M12 24L20 16L12 8" stroke={color} stroke-width="2.66667" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    </svg>    
  )
}

export default ChevronRight