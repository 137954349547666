import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReactDOM from "react-dom";
import { RxCross2 } from "react-icons/rx";
import { motion, useAnimation } from "framer-motion";
import {
  MdKeyboardArrowDown,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import { ProductIcons } from "../../asset";
function Sidebar({
  isSidebarOpen,
  toggleSideOpening,
  activeClass,
  inactiveClass,
  isActive,
}) {
  const controls = useAnimation();
  const [isProductOpen, setIsProductOpen] = useState(false);
  useEffect(() => {
    // Trigger animation when isSidebarOpen changes
    if (isSidebarOpen) {
      controls.start({
        x: 0,
        opacity: 1,
      });
    } else {
      controls.start({
        x: 1000,
        opacity: 0,
      });
    }
  }, [isSidebarOpen, controls]);

  return ReactDOM.createPortal(
    <motion.div
      animate={controls} // Use animate prop instead of initial and animate in ReactDOM.createPortal
      transition={{
        duration: 0.1,
        ease: "easeInOut",
      }}
      className={`${
        isSidebarOpen ? "fixed" : "hidden"
      } top-0 right-0 w-[300px] xxs:w-[250px] h-screen bg-white transition-transform transform z-[100] px-5`}
    >
      <div>
        <RxCross2
          className="text-secondary h-6 w-6  relative left-[200px] top-[30px]"
          onClick={toggleSideOpening}
        />
      </div>
      <ul className="flex flex-col items-center mt-16">
        <li
          className={`${
            isActive("/about") ? activeClass : inactiveClass
          } border-b w-full  py-2 font-700 leading-[21.6px]`}
        >
          <Link to={"/about"}>About</Link>
        </li>
        <li
          className={`${
            isActive("/pricing") ? activeClass : inactiveClass
          } border-b w-full  py-2 font-700 leading-[21.6px]`}
        >
          <Link to={"/pricing"}>Pricing</Link>
        </li>
        <li
          className={`${
            isActive("/products") ? activeClass : inactiveClass
          } w-full flex flex-col`}
        >
          <div
            onClick={() => setIsProductOpen(!isProductOpen)}
            className="flex gap-2"
          >
            <span className=" font-700 leading-[21.6px]">Products</span>{" "}
            <MdKeyboardArrowDown
              className={`h-6 2-6 duration-200 ease ${
                isProductOpen ? "transform rotate-180 " : ""
              }`}
            />
          </div>
          {isProductOpen && (
            <div className="flex flex-col text-sm mt-2">
              <ul className="flex flex-col gap-1 overflow-hidden">
                <li className="p-2 font-500 flex items-center gap-2">
                  <ProductIcons.Camera />
                  <Link to={"/proctoring"}>Proctoring</Link>
                </li>
                <li className="p-2 font-500 flex items-center gap-2">
                  <ProductIcons.Resume />
                  <Link to={"/resume-parser"}>Resume Parser</Link>
                </li>
                <li className="p-2 font-500 flex items-center gap-2">
                  <ProductIcons.Laptop />
                  <Link to={"/video-interview-platform"}>
                    Video Interview Platform
                  </Link>
                </li>
                <li className="p-2 font-500 flex items-center gap-2">
                  <ProductIcons.Check />
                  <Link to={"/online-assessment-platform"}>
                    Online Assessment Platform
                  </Link>
                </li>
              </ul>
              <div>
                <h5 className="p-2 font-700 leading-[21.6px]">
                  Assessment Library
                </h5>
                <ul className="flex flex-col gap-1 ">
                  <li className="p-2 font-500 flex items-center gap-2">
                    <ProductIcons.Resume2 />
                    <Link to={"/questions-library?type=General"}>General</Link>
                  </li>
                  <li className="p-2 font-500 flex items-center gap-2">
                    <ProductIcons.Language />
                    <Link to={"/questions-library?type=Language"}>
                      Language
                    </Link>
                  </li>
                  <li className="p-2 font-500 flex items-center gap-2">
                    <ProductIcons.Psycho />
                    <Link to={"/questions-library?type=Psychometric"}>
                      Psychomteric
                    </Link>
                  </li>
                  <li className="p-2 font-500 flex items-center gap-2">
                    <ProductIcons.All />
                    <Link
                      to={"/questions-library"}
                      className="flex items-center gap-2  font-500"
                    >
                      View all <MdOutlineKeyboardArrowRight />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </li>
        {/* Add more list items with the same structure */}
      </ul>
    </motion.div>,
    document.getElementById("sidebar-portal")
  );
}

export default Sidebar;
