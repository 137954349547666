import React from 'react'
import styles from './style.module.css'    
function Gradient_Box({data, text}) {
    const word = text.split(' ');
  return (
    <div className={`${styles.gradient_bg} font-krona rounded-[32px] flex flex-col gap-5 pt-10  w-full mx-auto px-5`}>
        <h3 className=' text-[24px] md:text-[32px] text-primary text-center leading-[40px]'>{data}</h3>
        <p className='text-[16px] md:text-[20px] text-center leading-[25px]'>{word[0]} <br></br> {word[1]}</p>
    </div>
  )
}

export default Gradient_Box;