import React, { useEffect, useState } from 'react';
import styles from './style.module.css';
import noise from '../../../asset/home/Noise.png';
import { Product_Tab } from '../../../ui/tabs';
import Assessment from './assessment';
import CandidateInvitation from './condidateInvitation';
import Proctoring from './proctoring';
import Result from './result';
import ResumeParser from './resumeParser';
import OneWayInterview from './oneWay';
import { motion } from 'framer-motion';
import NavBar from '../../nav';
export default function Products({ nav = false }) {
    const tabs = [
        {
            name: "Assessments",
            component: Assessment
        },
        {
            name: "Proctoring & Anti-Cheat",
            component: Proctoring
        },
        {
            name: "Result Analytics",
            component: Result
        },
        {
            name: "Candidate Invitation",
            component: CandidateInvitation
        },
        {
            name: "Resume Parser",
            component: ResumeParser
        },
        {
            name: "One-Way Video Interview",
            component: OneWayInterview
        },
    ];

    const [activeIndex, setActiveIndex] = useState(0);
    const [intervalId, setIntervalId] = useState(null);

    const handleTabClick = (index) => {
        // Clear the existing interval
        clearInterval(intervalId);
        // Set the active index
        setActiveIndex(index);
        // Set a new interval with the updated active index
        const newIntervalId = setInterval(() => {
            // Increment the activeIndex, and loop back to 0 if it exceeds the array length
            setActiveIndex((prevIndex) => (prevIndex + 1) % tabs.length);
        }, 10000);
        // Save the new interval ID
        setIntervalId(newIntervalId);
    };

    useEffect(() => {
        // Set the initial interval
        const initialIntervalId = setInterval(() => {
            // Increment the activeIndex, and loop back to 0 if it exceeds the array length
            setActiveIndex((prevIndex) => (prevIndex + 1) % tabs.length);
        }, 20000);
        // Save the initial interval ID
        setIntervalId(initialIntervalId);

        // Clear the interval on component unmount to avoid memory leaks
        return () => clearInterval(initialIntervalId);
    }, [tabs.length]);

    return (
        <div className={`mix-blend-overlay border relative`}>
            <div className={`absolute -z-10 opacity-85 ${styles.gradient_bg} h-full w-full top-0 left-0`}></div>
            <section className='wrapper'>
                {nav && <div className='mt-9'>
                    <NavBar bgColor={"bg-blue-light"} textColor={"bg-blue-light"} color={"#495F96"} />
                </div>}
                {/* Section Heading */}
                <h2 className='section_heading mt-24'>Products we offer</h2>

                {/* Tabs */}
                <div className=' grid sm:flex grid-cols-2 place-content-center md:grid-cols-5 gap-4 lg:gap-6 mt-12 justify-center flex-wrap flex-row' >
                    {tabs.map((tab, index) => (
                        <Product_Tab
                            key={index}
                            name={tab.name}
                            isActive={index === activeIndex}
                            handleClick={() => handleTabClick(index)}
                        />
                    ))}
                </div>

                {/* Tab content */}
                {activeIndex !== null &&
                    <motion.div
                        className='flex mt-12 mb-8 flex-col sm:flex-row'>
                        {React.createElement(tabs[activeIndex].component)}
                    </motion.div>
                }

            </section>
            <img src={noise} className="object-cover w-full h-full object-fill opacity-[20%] absolute top-0 -z-10 " />
        </div>
    );
}
