import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const BASE_URL = "https://prod-internal-dashboard.ambitionhire.ai";

export async function getBlogs() {
  try {
    const res = await axios.get(`${BASE_URL}/v1/blog/get-all/`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    console.log(res);
    return res.data;
  } catch (error) {
    console.error("Error fetching blogs:", error);
    return [];
  }
}

export async function getBlogById(record_id) {
  try {
    const res = await axios.get(`${BASE_URL}/v1/blog/get/${record_id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res.data;
  } catch (error) {
    console.error("Error fetching blog details:", error);
    toast.error(
      error?.response?.data?.detail || "An unexpected error occurred."
    );
    return null; // return null in case of error
  }
}

export async function ContactAPI(formData) {
  try {
    const response = await axios.post(`${BASE_URL}/v1/query/create/`, formData);

    // Check the response status or any other conditions based on your API
    toast.success("Form submitted successfully!");

    return response.data;
  } catch (error) {
    toast.error("An unexpected error occurred. Please try again later.");
    throw error;
  }
}

// Function to handle quiz demo form submission
export async function quizDemoAPI(formData) {
  try {
    // Make a POST request to your API endpoint
    console.log(formData);
    const response = await axios.post(`${BASE_URL}/v1/query/demo`, formData);
    console.log(response);
    // Check the response status or any other conditions based on your API
    toast.success("Quiz Demo form submitted successfully!");
    return response.data;
  } catch (error) {
    // Handle any errors that occur during the request
    console.error("Error submitting Quiz Demo form:", error);

    // Show an error toast
    toast.error("An unexpected error occurred. Please try again later.");
  }
}
