import React, { useEffect, useState } from "react";
import { Modal } from "../../ui/wrappers";
import { ChevronRight } from "../../asset/icons";
import { RxCross2 } from "react-icons/rx";
import { QuestionLabel } from "../../ui/buttons";
import { motion } from "framer-motion";
import { FaArrowLeftLong } from "react-icons/fa6";
function QuestionLibraryModal({ data, onClose, onNavigate }) {
  const [chevron, setChevron] = useState(1);
  const handleChevronClick = (direction) => {
    onNavigate(direction);
    setChevron(direction);
  };
  const buttonVariants = {
    press: { scale: 0.95 },
    hover: { scale: 1.1 },
  };
  return (
    <Modal onClose={onClose}>
      <div className="flex flex-col  max-w-[782px] max-h-[70vh] lg:max-h-[65vh]  bg-white h-full w-full p-4 lg:p-6 sm:rounded-[24px]">
        <div>
          <div className="flex justify-between">
            <div className="flex">
              <h2 className="text-[18px] lg:text-[22px] font-krona tracking-[-0.9px] lg:tracking-[-1.1px]">
                {data?.title}
              </h2>
            </div>
            <div className="flex gap-4 lg:gap-6">
              <div className="flex gap-2">
                <motion.div
                  variants={buttonVariants}
                  whileTap="press"
                  whileHover={"hover"}
                >
                  <button
                    onClick={() => handleChevronClick(-1)}
                    className="rotate-180 cursor-pointer"
                  >
                    <ChevronRight
                      color={chevron === -1 ? "#000000" : "#858585"}
                    />
                  </button>
                </motion.div>
                <motion.div
                  variants={buttonVariants}
                  whileTap="press"
                  whileHover={"hover"}
                >
                  <button
                    onClick={() => handleChevronClick(1)}
                    className="cursor-pointer"
                  >
                    <ChevronRight
                      color={chevron === 1 ? "#000000" : "#858585"}
                    />
                  </button>
                </motion.div>
              </div>
              <motion.div
                variants={buttonVariants}
                whileTap="press"
                whileHover={"hover"}
                className="hidden sm:block"
              >
                <button onClick={onClose} className="cursor-pointer">
                  <RxCross2 className="text-[#858585] h-8 w-8" />
                </button>
              </motion.div>
            </div>
          </div>
          <div className="flex gap-4 mt-4">
            <QuestionLabel type={data.type} labelText={data.labelText} />
            {/* <p className="text-[12px] lg:text-[16px]  font-500 lg:font-700 leading-[21.6px] text-[#555555] px-4 py-[6px] lg:py-2 border rounded-[32px] text-center">
              {data.numberOfQuestions || "00"} Questions
            </p> */}
          </div>
        </div>
        <div className="overflow-y-scroll scrollbar-hide">
          <div className="mt-8 text-[14px] lg:text-[16px] font-500 text-ellipsis">
            {data?.introductionText}
          </div>
          <div className="mt-8 flex flex-col">
            <h4 className="text-[16px] font-700">
              How it benefits recruiters:
            </h4>
            <div className="text-[14px] lg:text-[16px] font-500 mt-3">
              {data?.keyPersonalityTraits
                ?.split(/[\.\!\?]/)
                .slice(0, -1)
                .map((benefit, index) => (
                  <div key={index} className="flex gap-1">
                    <p>{index + 1}. </p>
                    <p>{benefit.trim()}.</p>
                  </div>
                ))}
            </div>
          </div>
          <div className="mt-8 flex flex-col">
            <h4 className="text-[16px] font-700">When to use:</h4>
            <div className="text-[14px] lg:text-[16px] font-500 mt-3">
              {data?.benefits
                ?.split(/[\.\!\?]/)
                .slice(0, -1)
                .map((benefit, index) => (
                  <div key={index} className="flex gap-1">
                    <p>{index + 1}. </p>
                    <p>{benefit.trim()}.</p>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default QuestionLibraryModal;
