import React, { useEffect, useState } from 'react';
import offer1 from '../../../../asset/home/offer1.png'
import offer2 from '../../../../asset/home/offer2.png'
import offer3 from '../../../../asset/home/offer3.png'
import { Offer_Box } from '../../../../ui/cards';
import { motion } from 'framer-motion';
import {  useNavigate } from 'react-router-dom';

export default function Assessment() {
    const scrollToTop = () => {
        window.scrollTo(0, 0);
      };  
      const navigate = useNavigate();
    
      const handleClick =  () => {
          navigate('/online-assessment-platform');
          scrollToTop();
        };
        const [zIndex, setZIndex] = useState(0);
    useEffect(() => {
        const intervalId = setInterval(() => {
            setZIndex(prevZIndex => (prevZIndex === 0 ? 1 : 0));
        }, 2000);

        // Clear the interval on component unmount to avoid memory leaks
        return () => clearInterval(intervalId);
    }, []);
    const containerVariants = {
        initial: { opacity: 0 },
        animate: { opacity: 1, transition: { type: 'spring', stiffness: 500, damping: 10, staggerChildren: .5 } },
    };
    const cardVariant1 = {
        initial: { opacity: 0, x: 1000 },
        animate: { opacity: 1, x: 0, transition: { type: 'spring', stiffness: 100, damping: 20, mass: 2.5 } },
    };
    const cardVariant2 = {
        initial: { opacity: 0, x: -1000 },
        animate: { opacity: 1, x: 0, transition: { type: 'spring', stiffness: 100, damping: 20, mass: 2.5 } },
    };

    const getZIndexClass = () => {
        return zIndex === 0 ? 'relative z-[5]' : 'relative z-40';
    };
    return (
        <>
            {/* tab text */}
            <div className='flex-1'>
                <h4 className='text-blue-light text-[16px] lg:text-[24px] leading-[21.6px] lg:leading-[32.4px] font-500'>Explore a wide range of assessments tailored to your
                    industry and expertise
                <span
                className=" hover:underline  text-[16px] lg:text-[24px] font-semibold"
                onClick={handleClick}
              >
                {" "}
                View All
              </span>{" "}</h4>
                <div className='flex flex-col md:flex-row mt-6 lg:mt-[48px] gap-4 lg:gap-6'>
                    <Offer_Box title="CTQ/Screening" desc="This assessment offers recruiters set simple screening questions with a Yes/No answer type" />
                    <Offer_Box title="Functional" desc="Functional assessment supports all types of questions whether free-text or MCQs. Recruiters can choose modules from Ambition Hire’s endless library or can create their own as well" />
                </div>
                <div className='mt-4 lg:mt-6 flex flex-col gap-4 lg:gap-6'>
                    <Offer_Box title="English" desc="To legitimately test job-seeker’s English language proficiency, we offer our recruiters with a ton of modules ranging from Listen & Repeat to Grammar and many more" />
                    <Offer_Box title="Psychometric" desc="Providing recruiters with a powerful tool to gain deeper insights into candidates' personality traits, cognitive abilities, and behavioral tendencies." />
                </div>
            </div>
            {/* tab images */}
            <motion.div
                variants={containerVariants}
                initial="initial"
                animate="animate"
                className='flex-1 mvisible overflow-hidden'>
                <motion.div variants={cardVariant1} className={getZIndexClass()}>
                    <img src={offer1} alt='tab_image' />
                </motion.div>
                <motion.div variants={cardVariant2}>
                    <img src={offer2} alt='tab_image' className='transform translate-x-[20px] lg:translate-x-[50px] translate-y-[-83px] z-20 relative' />
                </motion.div>
                <motion.div variants={cardVariant1} className={getZIndexClass()}>
                    <img src={offer3} alt='tab_image' className='transform translate-x-[-10px] lg:translate-x-[10px] translate-y-[-170px] z-10 relative' />
                </motion.div>
            </motion.div>
        </>
    )
}
