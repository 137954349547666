import React from 'react'

function Linkedin() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="uil:linkedin">
                <path id="Vector" d="M20.4686 2.00014H3.52859C3.33817 1.9975 3.14909 2.03239 2.97216 2.10282C2.79522 2.17326 2.6339 2.27786 2.49739 2.41065C2.36089 2.54344 2.25188 2.70182 2.17659 2.87675C2.1013 3.05167 2.0612 3.23972 2.05859 3.43014V20.5701C2.0612 20.7606 2.1013 20.9486 2.17659 21.1235C2.25188 21.2985 2.36089 21.4568 2.49739 21.5896C2.6339 21.7224 2.79522 21.827 2.97216 21.8975C3.14909 21.9679 3.33817 22.0028 3.52859 22.0001H20.4686C20.659 22.0028 20.8481 21.9679 21.025 21.8975C21.202 21.827 21.3633 21.7224 21.4998 21.5896C21.6363 21.4568 21.7453 21.2985 21.8206 21.1235C21.8959 20.9486 21.936 20.7606 21.9386 20.5701V3.43014C21.936 3.23972 21.8959 3.05167 21.8206 2.87675C21.7453 2.70182 21.6363 2.54344 21.4998 2.41065C21.3633 2.27786 21.202 2.17326 21.025 2.10282C20.8481 2.03239 20.659 1.9975 20.4686 2.00014ZM8.08859 18.7401H5.08859V9.74014H8.08859V18.7401ZM6.58859 8.48014C6.17486 8.48014 5.77806 8.31578 5.48551 8.02323C5.19295 7.73067 5.02859 7.33388 5.02859 6.92014C5.02859 6.5064 5.19295 6.10961 5.48551 5.81705C5.77806 5.5245 6.17486 5.36014 6.58859 5.36014C6.80829 5.33522 7.03077 5.35699 7.24147 5.42402C7.45217 5.49105 7.64633 5.60183 7.81125 5.7491C7.97617 5.89637 8.10812 6.07682 8.19846 6.27862C8.28881 6.48043 8.33551 6.69904 8.33551 6.92014C8.33551 7.14124 8.28881 7.35985 8.19846 7.56166C8.10812 7.76346 7.97617 7.94391 7.81125 8.09118C7.64633 8.23845 7.45217 8.34923 7.24147 8.41626C7.03077 8.48329 6.80829 8.50505 6.58859 8.48014ZM18.9086 18.7401H15.9086V13.9101C15.9086 12.7001 15.4786 11.9101 14.3886 11.9101C14.0513 11.9126 13.7228 12.0184 13.4474 12.2133C13.1721 12.4082 12.9631 12.6828 12.8486 13.0001C12.7703 13.2352 12.7364 13.4827 12.7486 13.7301V18.7301H9.74859V9.73014H12.7486V11.0001C13.0211 10.5272 13.4175 10.1377 13.895 9.87334C14.3726 9.60902 14.9132 9.47999 15.4586 9.50014C17.4586 9.50014 18.9086 10.7901 18.9086 13.5601V18.7401Z" fill="black" />
            </g>
        </svg>

    )
}

export default Linkedin;