import { GradientBorder, GradientSection } from "../../ui/wrappers";
import { Icons } from "../../asset";
function VideoInterviewSection3({ gradient, color }) {
  const features = [
    "High-definition video and audio quality",
    "Screen-sharing and virtual whiteboard capabilities",
    "Integrated note-taking and candidate scoring tools Support",
    "Customizable branding and interview environments",
    "Robust reporting and analytics",
  ];

  const advancedFeatures = [
    "Automated interview scheduling and reminders",
    "Collaborative interviewing with multiple panel members-sharing and virtual whiteboard capabilities",
    "Customizable interview templates and question banks",
    "Real-time candidate evaluation and scoring",
    "Comprehensive reporting and analytics",
  ];

  return (
    <GradientSection
      sectionHeading={"Features of Ambition Hire’s Video Interview Platform"}
    >
      <p className="my-8 text-center text-sm md:text-[22px] font-500 leading-[18.9px] md:leading-[34px] text-primary-light px-10">
        In addition to our virtual interview platforms, we also offer a
        comprehensive virtual interview software solution. This powerful tool
        seamlessly integrates with your existing applicant tracking system (ATS)
        and HR workflows, providing a centralized hub for scheduling,
        conducting, and managing virtual interviews
      </p>
      <div className="mt-8 sm:mt-[64px] lg:mt-24 flex flex-col gap-6 mb-32">
        {/* Features */}
        {features?.map((item, index) => (
          <div
            key={index}
            className="rounded-[16px] border py-6 px-8"
            style={{ background: "#3ABDB50D" }}
          >
            <div className="text-base md:text-2xl leading-[21.6px] md:leading-[32.3px] text-primart-light font-700 flex  gap-4">
              <div className="h-6 w-6 mt-2">
                <Icons.Asterisk color={color} />
              </div>
              <p>{item}</p>
            </div>
          </div>
        ))}

        {/* Advanced Features */}
        <h2 className="text-xl md:text-[32px] leading-[25px] md:leading-10 tracking-[-0.05em] text-center font-krona mt-48">
          Some Advanced Features
        </h2>
        <p className="text-sm md:text-xl font-500 leading-[18.9px] sm:leading-10 text-center mt-6 mb-10">
          In addition to our virtual interview platforms, we also offer a
          comprehensive virtual interview software solution. This powerful tool
          seamlessly integrates with your existing applicant tracking system
          (ATS) and HR workflows, providing a centralized hub for scheduling,
          conducting, and managing virtual interviews
        </p>
        {advancedFeatures?.map((item, index) => (
          <div
            key={index}
            className="rounded-[16px] border py-6 px-8"
            style={{ background: "#3ABDB50D" }}
          >
            <div className="text-base md:text-2xl leading-[21.6px] md:leading-[32.3px] text-primart-light font-700 flex  gap-4">
              <div className="h-6 w-6">
                <Icons.CircleCheck color={color} />
              </div>
              <p>{item}</p>
            </div>
          </div>
        ))}

        {/* Join Box */}
        <div className="mt-32 mb-20">
          <GradientBorder
            gradient={
              "linear-gradient(93.24deg, #3ABDB5 46.36%, rgba(219, 219, 219, 0) 105.44%)"
            }
            radius={16}
          >
            <div className="h-full w-full py-6 md:py-12 px-4 md:px-8">
              <h2 className="text-center text-xl md:text-[32px] font-krona tracking-[-0.05em] leading-[25px] md:leading-10 rounded-[16px]">
                Join the Future of Hiring!
              </h2>
              <p className="font-500 text-sm md:text-xl leading-6 md:leading-[34px] text-center text-satoshi mt-6 md:mt-8">
                Embrace the future of hiring by leveraging the power of our
                video interview platforms and software solutions. Contact us
                today to schedule a personalized demo and discover how our
                cutting-edge technology can transform your interview process,
                enabling you to attract and secure top talent from around the
                world
              </p>
            </div>
          </GradientBorder>
        </div>
      </div>
    </GradientSection>
  );
}

export default VideoInterviewSection3;
