import React from 'react'
import styles from './style.module.css'
import noise from '../../asset/home/Noise.png'
import { ContactUsButton, QuizButton } from '../../ui/buttons'

function ContactUs() {
    return (
        <div className={`wrapper my-20 mx-8 xl:mx-auto py-[32px] px-[16px] lg:px-0 lg:py-[48px] relative flex items-center justify-center flex-col rounded-[24px] overflow-hidden `}>
            <div className={`absolute -z-10 opacity-85 ${styles.gradient_bg} h-full w-full top-0 left-0`}></div>

            <h2 className='section_heading text-primary text-[20px] lg:text-[42px] tracking-normal leading-[25px] lg:leading-[52.5px] text-center'>Revolutionizing Recruitment</h2>
            <p className='text-[16px] lg:text-[22px] mt-6 lg:mt-4 font-500 text-center leading-[21.6px] lg:leading-[29.7px]'>Discover the Power of Ambition Hire</p>

            <div className='flex flex-col sm:flex-row justify-center gap-4 lg:gap-6 items-center text-center mt-6 lg:mt-12'>
                <ContactUsButton link="/contact"  text={"Contact Us"} borderColor="border-primary" textColor={"text-primary"} py={'py-[12px]'} />
                <QuizButton link={"#"} text={"Take a Quick Demo"} bgColor={"bg-primary"} textColor={"text-primary"} py={'py-[12px]'} />
            </div>
            <img src={noise} className="object-cover w-full h-full  opacity-[20%] absolute top-0 -z-10 " />
        </div>
    )
}

export default ContactUs;