import React from 'react'

function CrossCircle({color}) {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="action" opacity="0.48">
                <path id="primary-shape" fill-rule="evenodd" clip-rule="evenodd" d="M18.3327 9.99984C18.3327 14.6023 14.6018 18.3332 9.99935 18.3332C5.39685 18.3332 1.66602 14.6023 1.66602 9.99984C1.66602 5.39734 5.39685 1.6665 9.99935 1.6665C14.6018 1.6665 18.3327 5.39734 18.3327 9.99984ZM7.47435 7.47484C7.59154 7.3578 7.75039 7.29205 7.91602 7.29205C8.08164 7.29205 8.24049 7.3578 8.35768 7.47484L9.99935 9.1165L11.641 7.47484C11.7595 7.36444 11.9162 7.30433 12.0781 7.30719C12.24 7.31005 12.3945 7.37564 12.509 7.49015C12.6235 7.60466 12.6891 7.75915 12.692 7.92107C12.6949 8.08299 12.6347 8.23969 12.5243 8.35817L10.8827 9.99984L12.5243 11.6415C12.6347 11.76 12.6949 11.9167 12.692 12.0786C12.6891 12.2405 12.6235 12.395 12.509 12.5095C12.3945 12.624 12.24 12.6896 12.0781 12.6925C11.9162 12.6953 11.7595 12.6352 11.641 12.5248L9.99935 10.8832L8.35768 12.5248C8.2392 12.6352 8.0825 12.6953 7.92058 12.6925C7.75866 12.6896 7.60418 12.624 7.48966 12.5095C7.37515 12.395 7.30956 12.2405 7.3067 12.0786C7.30385 11.9167 7.36395 11.76 7.47435 11.6415L9.11602 9.99984L7.47435 8.35817C7.35731 8.24098 7.29157 8.08213 7.29157 7.9165C7.29157 7.75088 7.35731 7.59203 7.47435 7.47484Z" fill={color} />
            </g>
        </svg>

    )
}

export default CrossCircle;