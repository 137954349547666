import React from "react";

export default function Laptop({ color }) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" fill="#FBFAFC" />
      <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" stroke="#CDB2DA" />
      <g clip-path="url(#clip0_651_41175)">
        <path
          d="M13.333 22H18.6663M15.9997 19.3333V22M12.533 19.3333H19.4663C20.5864 19.3333 21.1465 19.3333 21.5743 19.1153C21.9506 18.9236 22.2566 18.6176 22.4484 18.2413C22.6663 17.8135 22.6663 17.2534 22.6663 16.1333V13.2C22.6663 12.0799 22.6663 11.5198 22.4484 11.092C22.2566 10.7157 21.9506 10.4097 21.5743 10.218C21.1465 10 20.5864 10 19.4663 10H12.533C11.4129 10 10.8529 10 10.425 10.218C10.0487 10.4097 9.74274 10.7157 9.55099 11.092C9.33301 11.5198 9.33301 12.0799 9.33301 13.2V16.1333C9.33301 17.2534 9.33301 17.8135 9.55099 18.2413C9.74274 18.6176 10.0487 18.9236 10.425 19.1153C10.8529 19.3333 11.4129 19.3333 12.533 19.3333Z"
          stroke="#1E1B1E"
          stroke-opacity="0.8"
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_651_41175">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(8 8)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
