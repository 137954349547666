import React, { useState } from "react";
import logo from "../../asset/home/logo.svg";
import { Menu, TrialIcon } from "../../asset/icons";
import { Link, useLocation } from "react-router-dom";
import { HiOutlineMenuAlt1 } from "react-icons/hi";
import Sidebar from "./sidebar";
import {
  MdKeyboardArrowDown,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import { ProductIcons } from "../../asset";
function NavBar({ bgColor, color, textColor }) {
  const [isProductOpen, setIsProductOpen] = useState(false);
  const location = useLocation();
  const isActive = (path) => {
    return location.pathname === path;
  };
  const activeClass = `border-b-[2px] border-[#7D4996] px-4 py-2 text-${bgColor} font-500 whitespace-nowrap`;
  const activeClassSidebar = `${textColor} px-4 py-2 font-500 whitespace-nowrap`;
  const inactiveClass = `px-4 py-2 font-500 whitespace-nowrap`;

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSideOpening = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const handleQuestionLibraryLinkClick = () => {
    setIsProductOpen(false);
    window.scrollTo(0, 1200);
  };

  let timer;
  const handleMouseLeave = () => {
    timer = setTimeout(() => {
      setIsProductOpen(false);
    }, 2000);
  };

  return (
    <nav className="flex lg:justify-between items-center">
      <Sidebar
        isSidebarOpen={isSidebarOpen}
        toggleSideOpening={toggleSideOpening}
        activeClass={activeClassSidebar}
        inactiveClass={inactiveClass}
        isActive={isActive}
        color={color}
      />

      <Link
        to={"/"}
        className="cursor-pointer flex lg:gap-[12px] h-[26.32px] items-center"
      >
        <img src={logo} alt="Logo" className="w-[36px] " />
        <span className="hidden lg:inline-block font-poppins text-[22px] font-500 whitespace-nowrap ">
          Ambition Hire
        </span>
      </Link>

      <div className="xss:ml-6 lg:ml-0">
        <ul className="flex text-[14px] lg:text-[18px] font-500 xs:gap-6 lg:gap-[48px] leading-[18.9px] lg:leading-[24.3px]">
          <li className={isActive("/about") ? activeClass : inactiveClass}>
            <Link to={"/about"}>About</Link>
          </li>
          <li
            className={`${
              isActive("/products") ? activeClass : inactiveClass
            } hidden sm:inline-block relative`}
          >
            <div
              className="flex gap-2 items-center cursor-pointer"
              // onClick={() => setIsProductOpen(!isProductOpen)}
              onMouseEnter={() => setIsProductOpen(true)}
              onMouseLeave={() => handleMouseLeave()}
            >
              <span>Products</span>{" "}
              <MdKeyboardArrowDown
                className={`h-6 2-6 duration-200 ease ${
                  isProductOpen ? "transform rotate-180 " : ""
                }`}
              />
            </div>

            {isProductOpen && (
              <div
                className="flex gap-4 lg:gap-8 rounded-b-[12px] p-4 lg:p-8 bg-white border-b-[4px] border-[#7D4996] absolute z-50 top-12 -right-80 xl:-right-56"
                onMouseEnter={() => {
                  clearInterval(timer);
                  setIsProductOpen(true);
                }}
                onMouseLeave={() => {
                  setIsProductOpen(false);
                }}
              >
                <div>
                  <h5 className="p-4 font-700 leading-[21.6px]">Products</h5>
                  <ul className="flex flex-col gap-1 lg:gap-3">
                    <li
                      className="p-4 font-500 flex items-center gap-4"
                      onClick={() => setIsProductOpen(false)}
                    >
                      <ProductIcons.Camera color={color} />
                      <Link to={"/proctoring"}>Proctoring</Link>
                    </li>
                    <li
                      className="p-4 font-500 flex items-center gap-4"
                      onClick={() => setIsProductOpen(false)}
                    >
                      <ProductIcons.Resume color={color} />
                      <Link to={"/resume-parser"}>Resume Parser</Link>
                    </li>
                    <li
                      className="p-4 font-500 flex items-center gap-4"
                      onClick={() => setIsProductOpen(false)}
                    >
                      <ProductIcons.Laptop color={color} />
                      <Link to={"/video-interview-platform"}>
                        Video Interview Platform
                      </Link>
                    </li>
                    <li
                      className="p-4 font-500 flex items-center gap-4"
                      onClick={() => setIsProductOpen(false)}
                    >
                      <ProductIcons.Check color={color} />
                      <Link to={"/online-assessment-platform"}>
                        Online Assessment Platform
                      </Link>
                    </li>
                  </ul>
                </div>
                <div>
                  <h5 className="p-4 font-700 leading-[21.6px]">
                    Assessment Library
                  </h5>
                  <ul className="flex flex-col gap-1 lg:gap-3">
                    <li
                      className="p-4 font-500 flex items-center gap-4"
                      onClick={handleQuestionLibraryLinkClick}
                    >
                      <ProductIcons.Resume2 color={color} />
                      <Link to={"/questions-library?type=General"}>
                        General
                      </Link>
                    </li>
                    <li
                      className="p-4 font-500 flex items-center gap-4"
                      onClick={handleQuestionLibraryLinkClick}
                    >
                      <ProductIcons.Language color={color} />
                      <Link to={"/questions-library?type=Language"}>
                        Language
                      </Link>
                    </li>
                    <li
                      className="p-4 font-500 flex items-center gap-4"
                      onClick={handleQuestionLibraryLinkClick}
                    >
                      <ProductIcons.Psycho color={color} />
                      <Link to={"/questions-library?type=Psychometric"}>
                        Psychomteric
                      </Link>
                    </li>
                    <li
                      className="p-4 font-500 flex items-center gap-4"
                      onClick={handleQuestionLibraryLinkClick}
                    >
                      <ProductIcons.All color={color} />
                      <Link
                        to={"/questions-library"}
                        className="flex items-center gap-2  font-500"
                      >
                        View all <MdOutlineKeyboardArrowRight />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            )}
          </li>
          {/* <li
            className={`${
              isActive("/questions-library") ? activeClass : inactiveClass
            } hidden sm:inline-block`}
          >
            <Link to={"/questions-library"}>Questions Library</Link>
          </li> */}
          <li className={isActive("/pricing") ? activeClass : inactiveClass}>
            <Link to={"/pricing"}>Pricing</Link>
          </li>
        </ul>
      </div>

      <div className="ml-auto lg:ml-0 flex items-center gap-4">
        <Link to={"/quiz-demo"}>
          <div className={`relative text-[18px] hidden lg:block`}>
            <TrialIcon color={color} />
          </div>
          <div
            className={`flex items-center gap-2 xs:gap-4 relative text-[14px] font-700 text-primary lg:hidden`}
          >
            <span className="whitespace-nowrap">Free Trial</span>
          </div>
        </Link>
        <div className="sm:hidden ">
          <HiOutlineMenuAlt1
            className="text-secondary h-5 w-5 xs:h-8 xs:w-8 cursor-pointer"
            onClick={toggleSideOpening}
          />
        </div>
      </div>
    </nav>
  );
}

export default NavBar;
