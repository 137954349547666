import React from 'react'

export default function ArrowNarrowLeft() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="arrow-narrow-left">
                <path id="Icon" d="M20 12H4M4 12L10 18M4 12L10 6" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </g>
        </svg>

    )
}
