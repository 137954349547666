import React from "react";

export default function Check({ color }) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" fill="#FBFAFC" />
      <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" stroke="#CDB2DA" />
      <g clip-path="url(#clip0_695_17930)">
        <path
          d="M22.6663 15.3911V16.0045C22.6655 17.4421 22.2 18.8409 21.3392 19.9923C20.4785 21.1438 19.2685 21.9861 17.8899 22.3937C16.5113 22.8013 15.0378 22.7524 13.6893 22.2542C12.3408 21.756 11.1894 20.8352 10.407 19.6292C9.62452 18.4232 9.25287 16.9965 9.34746 15.562C9.44205 14.1275 9.99781 12.762 10.9319 11.6692C11.8659 10.5763 13.1282 9.81472 14.5305 9.4979C15.9327 9.18107 17.3999 9.32602 18.713 9.91113M22.6663 10.6673L15.9997 17.3407L13.9997 15.3407"
          stroke="#1E1B1E"
          stroke-opacity="0.8"
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_695_17930">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(8 8)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
