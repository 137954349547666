import React, { useState } from "react";
import { NavLink, useLocation, useParams } from "react-router-dom";
import { GradientSection, PageLayout } from "../../ui/wrappers";
import { ArrowNarrowLeft, ChevronRight } from "../../asset/icons";
import { motion } from "framer-motion";
import { QuestionLabel } from "../../ui/buttons";
import { useMedia } from "../../utils/useMedia";
function QuestionDetails() {
  const { title } = useParams();
  const { state: data } = useLocation();
  console.log(data);
  const [chevron, setChevron] = useState(1);
  const [currentIndex, setCurrentIndex] = useState(data.index);
  const [details, setDetails] = useState(data.filteredData[currentIndex]);
  function handleModalNavigation(direction) {
    const newIndex =
      (currentIndex + direction + data?.filteredData.length) %
      data?.filteredData.length;
    setCurrentIndex(newIndex);
    setDetails(data.filteredData[newIndex]);
  }
  const handleChevronClick = (direction) => {
    handleModalNavigation(direction);
    setChevron(direction);
  };
  const buttonVariants = {
    press: { scale: 0.95 },
    hover: { scale: 1.1 },
  };
  return (
    <PageLayout>
      <GradientSection nav={true}>
        <div className="-mt-3">
          <div className="flex justify-between items-center">
            <NavLink to={-1}>
              <ArrowNarrowLeft />
            </NavLink>
            <div className="flex justify-center flex-1 overflow-x-scroll scrollbar-hide">
              <h2 className="text-[18px] font-krona tracking-[-0.9px]">
                {details.title}
              </h2>
            </div>
            <div className="flex">
              <motion.div
                variants={buttonVariants}
                whileTap="press"
                whileHover={"hover"}
              >
                <button
                  onClick={() => handleChevronClick(-1)}
                  className="rotate-180 cursor-pointer"
                >
                  <ChevronRight
                    color={chevron === -1 ? "#000000" : "#858585"}
                  />
                </button>
              </motion.div>
              <motion.div
                variants={buttonVariants}
                whileTap="press"
                whileHover={"hover"}
              >
                <button
                  onClick={() => handleChevronClick(1)}
                  className="cursor-pointer"
                >
                  <ChevronRight color={chevron === 1 ? "#000000" : "#858585"} />
                </button>
              </motion.div>
            </div>
          </div>
          <div className="flex gap-4 mt-4 w-full justify-between">
            <QuestionLabel type={details.type} labelText={details.labelText} />

            {/* <p className="text-[12px] lg:text-[16px]  font-500 lg:font-700 leading-[21.6px] text-[#555555] px-4 py-[6px] lg:py-2 border rounded-[32px] text-center w-full">
              {details.numberOfQuestions || "00"} Questions
            </p> */}
          </div>
          <div className="mt-6">
            <div className="text-[14px] font-500">
              {details?.introductionText}
            </div>
            <div className="mt-6 flex flex-col">
              <h4 className="text-[16px] font-700">
                How it benefits recruiters:
              </h4>
              <div className="text-[14px] lg:text-[16px] font-500 mt-3">
                {data?.keyPersonalityTraits
                  ?.split(/[\.\!\?]/)
                  .slice(0, -1)
                  .map((benefit, index) => (
                    <div key={index} className="flex gap-1">
                      <p>{index + 1}. </p>
                      <p>{benefit.trim()}.</p>
                    </div>
                  ))}
              </div>
            </div>
            <div className="mt-6 flex flex-col">
              <h4 className="text-[16px] font-700">When to use:</h4>
              <div className="text-[14px] lg:text-[16px] font-500 mt-3">
                {details?.benefits
                  ?.split(/[\.\!\?]/)
                  .slice(0, -1)
                  .map((benefit, index) => (
                    <div key={index} className="flex gap-1">
                      <p>{index + 1}. </p>
                      <p>{benefit.trim()}.</p>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </GradientSection>
    </PageLayout>
  );
}

export default QuestionDetails;
