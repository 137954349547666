import React, { useState } from 'react';
import { BsUpload } from 'react-icons/bs';
import defaultImage from '../../asset/freeTrail/defaultImage.png';
import UploadIcon from '../../asset/icons/uploadIcon';
import { useField, useFormikContext } from 'formik';

function InputImage({ id }) {
    const { setFieldValue } = useFormikContext();
    const [image, setImage] = useState(null);
    const [isHovered, setIsHovered] = useState(false);
    const fileInputRef = React.createRef();

    const handleImageChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onload = (e) => {
                setImage(e.target.result);
                setFieldValue(id, file);
            };

            reader.readAsDataURL(file);
        }
    };

    const handleFileSelect = (event) => {
        event.preventDefault();
        fileInputRef.current.click();
    };

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const [field, meta] = useField(id);


    return (
        <div>
            <input
                type="file"
                ref={fileInputRef}
                accept="image/*"
                className="hidden"
                onChange={handleImageChange}
            />
            <button
                className="relative text-white rounded-[6px] border-[1px] w-[96px] h-[96px] overflow-hidden"
                onClick={handleFileSelect}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                {image ? (
                    <img src={image} alt="Uploaded Image" className="w-full h-full object-cover rounded-[6px] border-[1px]" />
                ) : (
                    <div className="w-full h-full relative">
                        <img src={defaultImage} alt="Default Image" className="w-full h-full object-cover rounded-[6px] border-[1px]" />
                        {isHovered && (
                            <div className="box1 absolute inset-0 flex items-center justify-center bg-black bg-opacity-30 rounded-[6px]">
                                <div className='h-8 w-8 bg-white grid place-content-center rounded-[4px]'>
                                    <UploadIcon />
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </button>
            <div className='text-[12px] h-4'>
                {meta.touched && meta.error && (
                    <span className="font-500 leading-[16.2px]  text-red-500">
                        {meta.error}
                    </span>
                )}
            </div>
        </div>
    );
}

export default InputImage;
