import React from "react";
import { GradientSection } from "../../ui/wrappers";
import blogImage from "../../asset/blog/blog.png";

function BlogMain({ gradient, color, blog }) {
  return (
    <GradientSection
      nav={true}
      color={color}
      bgColor={color}
      gradient={gradient}
      textColor={"text-azure"}
    >
      <div className="flex flex-col items-center relative pb-32 ">
        {/* Headings */}
        <h1 className="text-[24px] lg:text-[48px] font-krona tracking-[-0.05em] text-center ">
          {blog ? (blog.title ? blog.title : "No Title") : "Loading..."}
        </h1>
        <div className="mt-6">
          {blog && blog.file_url ? (
            <img
              src={blog.file_url}
              alt={blog.title}
              className="w-full object-fill"
            />
          ) : (
            <img
              src={blogImage}
              alt="Default blog"
              className=" w-full object-fill"
            />
          )}
        </div>

        {/* Trust Box */}
      </div>
    </GradientSection>
  );
}

export default BlogMain;
