import React, { useState } from 'react';
import up from '../../asset/home/chevron-up.png';
import down from '../../asset/home/chevron-down.png';
import { motion } from 'framer-motion';

function CurasolButton({ length, setCurrentIndex }) {
  const [clickedButton, setClickedButton] = useState(null);

  const handleUpClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + length) % length);
    setClickedButton('up');
  };

  const handleDownClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % length);
    setClickedButton('down');
  };

  return (
    <div className='flex justify-end lg:justify-normal  gap-3 md:gap-4 order-1 lg:order-3'>
      <motion.button
        whileHover={{ scale: 1.1 }}
        onClick={handleUpClick}
        className={`rounded-[12px] grid place-content-center w-[38px] h-[40px] ${clickedButton === 'up' ? 'bg-[#F5F5F5]' : 'bg-white  border-[1px] border-[#DDDDDD]'
          }`}
      >
        <img src={up} alt="Up Arrow" className='-rotate-90' />
      </motion.button>
      <motion.button
        whileHover={{ scale: 1.1 }}
        onClick={handleDownClick}
        className={`rounded-[12px] grid place-content-center w-[38px] h-[40px] ${clickedButton === 'down' ? 'bg-[#F5F5F5]' : 'bg-white border-[1px] border-[#DDDDDD]'
          }`}
      >
        <img src={down} alt="Down Arrow" className='-rotate-90' />
      </motion.button>
    </div>
  );
}

export default CurasolButton;
