import React from "react";

const GdprPolicy = () => {
  return (
    <div className="max-w-4xl mx-auto p-4">
      <h1 className="text-center font-900 text-2xl my-10 underline">
        GDPR Policy
      </h1>
      <h2 className="text-2xl font-bold mb-4">
        Effective date: December 17, 2018
      </h2>
      <p className="mb-4">
        Ambition Hire Private Limited ("us", "we", or "our") operates the{" "}
        <a href="https://ambitionhire.ai" className="text-blue-500">
          https://ambitionhire.ai
        </a>{" "}
        website (hereinafter referred to as the "Service"). This page informs
        you of our policies regarding the collection, use, and disclosure of
        personal data when you use our Service and the choices you have
        associated with that data. The policy also includes your rights as a
        data subject that you may exercise in certain circumstances which may
        include the law of respective land governing the data privacy of the
        data subject.
      </p>
      <p className="mb-4">
        We use your data to provide and improve the Service. By using the
        Service, you agree to the collection and use of information in
        accordance with this policy.
      </p>
      <p className="mb-4">
        Unless otherwise defined in this Privacy Policy, the terms used in this
        Privacy Policy have the same meanings as in our Terms and Conditions.
      </p>
      <h3 className="text-xl font-bold mt-10 mb-4">Definitions</h3>
      <p className="mb-4">
        <span className="font-bold">Service:</span> The{" "}
        <a href="https://ambitionhire.ai" className="text-blue-500">
          https://ambitionhire.ai
        </a>{" "}
        website, any subdomains of the said website is operated by Ambition Hire
        Private Limited
      </p>
      <p className="mb-4">
        <span className="font-bold">Personal Data:</span> Data about a living
        individual who can be identified from that data (or from those and other
        information either in our possession or likely to come into our
        possession).
      </p>
      <p className="mb-4">
        <span className="font-bold">Usage Data:</span> Data collected
        automatically either generated by the use of the Service or from the
        Service infrastructure itself (for example, the duration of a page
        visit).
      </p>
      <p className="mb-4">
        <span className="font-bold">Cookies:</span> Small files stored on your
        device (computer or mobile device).
      </p>
      <p className="mb-4">
        <span className="font-bold">Data Controller:</span> Data Controller
        means the natural or legal person who (either alone or jointly or in
        common with other persons) determines the purposes for which and the
        manner in which any personal information is, or are to be, processed.
      </p>
      <p className="mb-4">
        <span className="font-bold">
          Data Processors (or Service Providers):
        </span>{" "}
        Data Processor (or Service Provider) means any natural or legal person
        who processes the data on behalf of the Data Controller. For the purpose
        of this Privacy Policy, we are the Data Processor of your Personal Data.
      </p>
      <p className="mb-4">
        <span className="font-bold">Data Subject (or User) :</span> Data Subject
        is any living individual who is using our Service and is the subject of
        Personal Data.
      </p>
      <div className="my-10">
        <h3 className="font-bold text-xl mb-4">
          Information Collection and Use
        </h3>
        <p>
          We collect several different types of information for various purposes
          to provide and improve our Service to you.
        </p>
      </div>
      <div className="my-10">
        <h3 className="font-bold text-xl mb-4">Types of Data Collected</h3>
        <p>
          Personal Data: <br></br> While using our Service, we may ask you to
          provide us with certain personally identifiable information that can
          be used to contact or identify you ("Personal Data"). Personally
          identifiable information may include, but is not limited to:
        </p>
        <div className="p-5 my-10 rounded h-full w-full border">
          <table className="">
            <thead>
              <tr>
                <th>Data Element</th>
                <th>Purpose</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="px-10 py-3">First name and last name</td>
                <td>User Identification</td>
              </tr>
              <tr>
                <td className="px-10 py-3">Email address</td>
                <td>User Identification and contact purposes</td>
              </tr>
              <tr>
                <td className="px-10 py-3">Phone number</td>
                <td>User Identification and contact purposes</td>
              </tr>
              <tr>
                <td className="px-10 py-3">
                  Address, State, Province, ZIP/Postal code, City ( if
                  applicable )
                </td>
                <td>
                  User Identification and better job positioning and enablement
                </td>
              </tr>
              <tr>
                <td className="px-10 py-3">Video Interviewsr</td>
                <td>Interview analysis and cognitive analysis</td>
              </tr>
              <tr>
                <td className="px-10 py-3">Audio Interviews</td>
                <td>Interview analysis</td>
              </tr>
              <tr>
                <td className="px-10 py-3">Cookies and Usage Data</td>
                <td>Interview proctoring and user data analysis</td>
              </tr>
            </tbody>
          </table>
        </div>
        <p className="my-4">
          We may use your Personal Data to contact you on behalf of the our
          clients (Companies looking out for candidates for suitable jobs in
          their organization) with information that may be of interest to you.
          We may also use your Personal Data to contact you with newsletters
          that may be of interest to you. You may opt opt-out of receiving any,
          or all, of these communications from us by following the unsubscribe
          link or the instructions provided in any email we send.
        </p>
      </div>
      <div className="my-10">
        <h3 className="font-bold text-xl mb-4">Usage Data</h3>
        <p>
          We may also collect information that your browser sends whenever you
          visit our Service or when you access the Service by or through a
          mobile device ("Usage Data"). This Usage Data may include information
          such as your computer's Internet Protocol address (e.g. IP address),
          browser type, browser version, the pages of our Service that you
          visit, the time and date of your visit, the time spent on those pages,
          unique device identifiers and other diagnostic data. When you access
          the Service with a mobile device, this Usage Data may include
          information such as the type of mobile device you use, your mobile
          device unique ID, the IP address of your mobile device, your mobile
          operating system, the type of mobile Internet browser you use, unique
          device identifiers and other diagnostic data.
        </p>
      </div>
      <div className="my-10">
        <h3 className="font-bold text-xl mb-4">Tracking & Cookies Data</h3>
        <p>
          We use cookies and similar tracking technologies to track the activity
          on our Service and we hold certain information. Cookies are files with
          a small amount of data which may include an anonymous unique
          identifier. Cookies are sent to your browser from a website and stored
          on your device. Other tracking technologies are also used such as
          beacons, tags, and scripts to collect and track information and to
          improve and analyse our Service. You can instruct your browser to
          refuse all cookies or to indicate when a cookie is being sent.
          However, if you do not accept cookies, you may not be able to use some
          portions of our Service. Examples of Cookies we use:
        </p>
        <ul className="mt-5">
          <li>
            <span className="font-bold">Session Cookies. </span>We use Session
            Cookies to operate our Service.
          </li>
          <li>
            <span className="font-bold">Preference Cookies. </span> We use
            Preference Cookies to remember your preferences and various
            settings.
          </li>
          <li>
            <span className="font-bold">Security Cookies. </span> We use
            Security Cookies for security purposes.
          </li>
        </ul>
      </div>
      <div className="my-10">
        <h3 className="font-bold text-xl mb-4">Use of Data</h3>
        <p className="my-4">
          Ambition Hire Private Limited uses the collected data for various
          purposes:
        </p>
        <ul className="list-disc pl-4 mb-4">
          <li>To provide and maintain our Service</li>
          <li>To notify you about changes to our Service</li>
          <li>
            To allow you to participate in interactive features of our Service
            when you choose to do so
          </li>
          <li>To provide customer support</li>
          <li>
            To gather analysis or valuable information so that we can improve
            our Service
          </li>
          <li>To monitor the usage of our Service</li>
          <li>To detect, prevent and address technical issues</li>
          <li>
            To provide you with news, special offers and general information
            about other goods, services and events which we offer that are
            similar to those that you have already purchased or enquired about
            unless you have opted not to receive such information
          </li>
        </ul>
        <p>
          Processing Personal Data under the General Data Protection Regulation
          (GDPR) If you are from the European Economic Area (EEA), Ambition Hire
          Private Limited will adhere to the respective data privacy laws and
          regulations as applicable in the EEA. legal basis for Our collecting
          and using the personal information described in this Privacy Policy
          depends on the Personal Data we collect and the specific context in
          which we collect it. Ambition Hire Private Limited may process your
          Personal Data because:
        </p>
        <ul className="list-disc pl-4 mb-4 my-4">
          <li>We need to perform a contract with you</li>
          <li>You have given us permission to do so</li>
          <li>
            The processing is in our legitimate interests and it is not
            overridden by your rights
          </li>
          <li>To comply with the law</li>
        </ul>
      </div>
      <div className="my-10">
        <h3 className="font-bold text-xl mb-4">
          Your Data Protection Rights under the General Data Protection
          Regulation (GDPR)
        </h3>
        <p>
          If you are a resident of the European Economic Area (EEA), you have
          certain data protection rights as per Regulation (EU) 2016/679 of The
          European Parliament and of The Council, 27 April 2016 General Data
          Protection Regulation (GDPR). Ambition Hire Private Limited aims to
          take reasonable steps to comply with the GDPR in full letter and
          spirit and allow you all the disclosures/rectifications as are
          required in relation to one’s personal information. You will be
          further allowed to correct, amend, delete or limit the use of your
          Personal Data.
          <br></br>
          If you wish to be informed about what Personal Data we hold about you
          and if you want it to be removed/amend, from our systems, any / whole
          of your personal data then please contact us at
          customersupport@ambitionhire.ai In certain circumstances, you have the
          following data protection rights as per GDPR:
        </p>
        <ul className="list-disc pl-4 my-4">
          <li>
            The right to access, update or delete the information we have on
            you. Whenever made possible, you can access, update or request
            deletion of your Personal Data directly within your account settings
            section. If you are unable to perform these actions yourself, please
            contact us to assist you.
          </li>
          <li>
            The right of rectification. You have the right to have your
            information rectified if that information is inaccurate or
            incomplete.
          </li>
          <li>
            The right to object. You have the right to object to our processing
            of your Personal Data.
          </li>
          <li>
            The right of restriction. You have the right to request that we
            restrict the processing of your personal information.
          </li>
          <li>
            The right to data portability. You have the right to be provided
            with a copy of the information we have on you in a structured,
            machine-readable and commonly used format.
          </li>
          <li>
            The right to withdraw consent. You also have the right to withdraw
            your consent at any time where Ambition Hire Private Limited relied
            on your consent to process your personal information.
          </li>
        </ul>
        <p>
          Please note that the above rights are not exhaustive and are subject
          to the GDPR. Further please note that we may ask you to verify your
          identity before responding to such requests. You have the right to
          complain to a Data Protection Authority about our collection and use
          of your Personal Data. For more information, please contact your local
          data protection authority in the European Economic Area (EEA).
        </p>
      </div>
      <div className="my-10">
        <h3 className="font-bold text-xl mb-4">Retention of Data</h3>
        <p>
          Ambition Hire Private Limited will retain your Personal Data only for
          as long as it is necessary for the purposes set out in this Privacy
          Policy. We will retain and use your Personal Data to the extent
          necessary to comply with our legal obligations as well (for example,
          if we are required to retain your data to comply with applicable
          laws), resolve disputes and enforce our legal agreements and policies.
          <br></br>
          Ambition Hire Private Limited will also retain Usage Data for internal
          analysis purposes. Usage Data is generally retained for a shorter
          period of time, except when this data is used to strengthen the
          security or to improve the functionality of our Service, or we are
          legally obligated to retain this data for longer periods
        </p>
      </div>
      <div className="my-10">
        <h3 className="font-bold text-xl mb-4">Transfer of Data</h3>
        <p>
          Your information, including Personal Data, may be transferred to — and
          maintained on — computers located outside of your state, province,
          country or other governmental jurisdiction where the data protection
          laws may differ from those of your jurisdiction..
          <br></br>
          If you are located outside India and choose to provide information to
          us, please note that we transfer the data, including Personal Data, to
          India and process it there.
          <br></br>
          Your consent to this Privacy Policy followed by your submission of
          such information represents your agreement to that transfer.
          <br></br>
          Ambition Hire Private Limited will take all the steps reasonably
          necessary to ensure that your data is treated securely and in
          accordance with this Privacy Policy and no transfer of your Personal
          Data will take place to an organisation or a country unless there are
          adequate controls in place including the security of your data and
          other personal information.
        </p>
      </div>
      <div className="my-10">
        <h3 className="font-bold text-xl mb-4">Disclosure of Data</h3>
        <p>
          <span className="font-bold mt-4">Business Transaction</span> <br></br>{" "}
          If Ambition Hire Private Limited is involved in a merger, acquisition
          or asset sale, your Personal Data may be transferred. We will provide
          notice before your Personal Data is transferred and becomes subject to
          a different Privacy Policy.
          <br></br>
          <span className="font-bold mt-4">Disclosure for Law Enforcement</span>
          <br></br> Under certain circumstances, Ambition Hire Private Limited
          may be required to disclose your Personal Data, if required to do so
          by any law or in response to valid requests by public authorities
          (including but not limited toe.g. a court or a government agency).
          <br></br>
          <span className="font-bold mt-4">Legal Requirements</span> Ambition
          Hire Private Limited may disclose your Personal Data in the good faith
          belief that such action is necessary to:
        </p>
        <ul className="list-disc pl-4 m-4">
          <li>To comply with a legal obligation</li>
          <li>
            To protect and defend the rights or property of Ambition Hire
            Private Limited
          </li>
          <li>
            To prevent or investigate possible wrongdoing in connection with the
            Service.
          </li>
          <li>
            To protect the personal safety of users of the Service or the
            public.
          </li>
          <li>To protect against legal liability.</li>
          <li>
            In execution of an order of a government authority including the
            court of law, Income tax authorities, etc.
          </li>
        </ul>
      </div>
      <div className="my-10">
        <h3 className="font-bold text-xl mb-4">Security of Data</h3>
        <p>
          The security of your data is important to us but remember that no
          method of transmission over the Internet or method of electronic
          storage is 100% secure. While we strive to use commercially acceptable
          means to protect your Personal Data, we cannot guarantee its absolute
          security.
        </p>
      </div>
      <div className="my-10">
        <h3 className="font-bold text-xl mb-4">Service Providers</h3>

        <p>
          We may employ third party companies and individuals to facilitate our
          Service ("Service Providers"), provide the Service on our behalf,
          perform Service-related services or assist us in analysing how our
          Service is used. These third parties have access to your Personal Data
          only to perform these tasks on our behalf and are obligated not to
          disclose or use it for any other purpose.
        </p>
      </div>
      <div className="my-10">
        <h3 className="font-bold text-xl mb-4">Analytics</h3>

        <p>
          We may use third-party Service Providers to monitor and analyse the
          use of our Service.
        </p>
        <ul className="list-disc pl-4 m-4">
          <li>
            <h5 className="text-lg font-bold">Google Analytics</h5>
            <p>
              Google Analytics is a web analytics service offered by Google that
              tracks and reports website traffic. Google uses the data collected
              to track and monitor the use of our Service. This data is shared
              with other Google services. Google may use the collected data to
              contextualise and personalise the ads of its own advertising
              network. For more information on the privacy practices of Google,
              please visit the Google Privacy & Terms web page:
              https://policies.google.com/privacy?hl=en
            </p>
          </li>
          <li>
            <h5 className="text-lg font-bold">Firebase</h5>
            <p>
              Firebase is an analytics service provided by Google Inc. You may
              opt-out of certain Firebase features through your mobile device
              settings, such as your device advertising settings or by following
              the instructions provided by Google in their Privacy Policy:
              https://policies.google.com/privacy?hl=en We also encourage you to
              review the Google's policy for safeguarding your data:
              https://support.google.com/analytics/answer/6004245. For more
              information on what type of information Firebase collects, please
              visit the Google Privacy & Terms web page:
              https://policies.google.com/privacy?hl=en
            </p>
          </li>
        </ul>
      </div>
      <div className="my-10">
        <h3 className="font-bold text-xl mb-4">Links to Other Sites</h3>

        <p>
          Our Service may contain links to other sites that are not operated by
          us. If you click a third party link, you will be directed to that
          third party's site. We strongly advise you to review the Privacy
          Policy of every site you visit. We have no control over and assume no
          responsibility for the content, privacy policies or practices of any
          third party sites or services.
        </p>
      </div>
      <div className="my-10">
        <h3 className="font-bold text-xl mb-4">Children's Privacy</h3>

        <p>
          Our Service does not address anyone under the age of 18 ("Children").
          We do not knowingly collect personally identifiable information from
          anyone under the age of 18. If you are a parent or guardian and you
          are aware that your Child child has provided us with Personal Data,
          please contact us. If we become aware that we have collected Personal
          Data from children without verification of parental consent, we take
          steps to remove that information from our servers.
        </p>
      </div>
      <div className="my-10">
        <h3 className="font-bold text-xl mb-4">
          Changes to This Privacy Policy
        </h3>

        <p>
          We may update our Privacy Policy from time to time. We will notify you
          of any changes by posting the new Privacy Policy on this page. We will
          let you know via email and/or a prominent notice on our Service, prior
          to the change becoming effective and update the "effective date" at
          the top of this Privacy Policy.
          <br></br>
          You are advised to review this Privacy Policy periodically for any
          changes. Changes to this Privacy Policy are effective when they are
          posted on this page.
        </p>
      </div>
      <div className="my-10">
        <h3 className="font-bold text-xl mb-4">Revoking Consent</h3>

        <p>
          If you wish to revoke your consent at any given point in time for the
          above mentioned purposes or wish to remove your data from our servers,
          you can reach out to us by email: customersupport@ambitionhire.ai
          Please mention your username (country code and phone number) and the
          website address from which you would like us to remove your consent or
          data.
        </p>
      </div>
      <div className="my-10">
        <h3 className="font-bold text-xl mb-4">Contact Us</h3>

        <p>
          If you have any questions about this Privacy Policy, please contact
          us:
        </p>
        <ul className="list-disc pl-4 m-4">
          <li>By email: customersupport@ambitionhire.ai</li>
        </ul>
      </div>
    </div>
  );
};

export default GdprPolicy;
