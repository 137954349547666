import React, { useEffect, useRef } from 'react'
import { motion, useAnimation, useInView } from 'framer-motion'
function RevealAnimation({ children }) {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });
    const mainControls=useAnimation();
    useEffect(() => {
        if(isInView){
            mainControls.start("visible");
        }
    }, [isInView]);

    return (
        <div className='w-full' ref={ref}>
            <motion.div
                variants={{
                    hidden: { opacity: 0, y: 200 },
                    visible: { opacity: 1, y: 0 },
                }}
                initial="hidden"
                animate={mainControls}
                transition={{ duration: 0.5 }}
            >
                {children}
            </motion.div>
        </div>
    )
}

export default RevealAnimation;