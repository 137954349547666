import React from "react";

export default function Camera({ color }) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" fill="#FBFAFC" />
      <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" stroke="#CDB2DA" />
      <g clip-path="url(#clip0_651_41162)">
        <path
          d="M22.8921 17.0405L22.0285 16.465L22.3638 16.1298C22.4961 15.9975 22.5618 15.8133 22.5431 15.6274C22.5245 15.4415 22.4238 15.2735 22.2688 15.1704L12.9181 8.93662C12.7777 8.84327 12.6065 8.81081 12.4409 8.84571C12.2761 8.88142 12.1332 8.98207 12.0439 9.12494L8.92705 14.112C8.75011 14.3944 8.82722 14.7654 9.10075 14.9553L13.8191 18.2216V20.676H10.7022V20.0527C10.7022 19.7085 10.423 19.4293 10.0788 19.4293C9.73469 19.4293 9.45546 19.7085 9.45546 20.0527V22.5462C9.45546 22.8903 9.73469 23.1695 10.0788 23.1695C10.423 23.1695 10.7022 22.8903 10.7022 22.5462V21.9228H14.4425C14.7866 21.9228 15.0658 21.6435 15.0658 21.2994V19.0852L17.2047 20.5657C17.3118 20.6396 17.436 20.6761 17.5594 20.6761C17.7201 20.6761 17.88 20.6136 18.0001 20.4934L18.5115 19.9821L20.3305 21.1947C20.4335 21.2637 20.5537 21.2995 20.6762 21.2995C20.7217 21.2995 20.768 21.2946 20.8126 21.284C20.979 21.2467 21.1227 21.1428 21.2103 20.9967L23.0805 17.8798C23.2517 17.5949 23.1689 17.2248 22.8921 17.0405ZM17.4862 19.2442L14.7914 17.3781C14.7906 17.3781 14.7906 17.3773 14.7906 17.3773L10.2995 14.2685L12.7606 10.3302L20.9449 15.7864L17.4862 19.2442ZM20.478 19.7945L19.4107 19.0826L21.1298 17.3635L21.7061 17.7474L20.478 19.7945Z"
          fill="#1E1B1E"
          fill-opacity="0.8"
        />
      </g>
      <defs>
        <clipPath id="clip0_651_41162">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(8 8)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
