import React from 'react'
import whatsapp from '../../../../asset/home/whatsapp.png'
import { motion, MotionConfig } from 'framer-motion'
import atsSmall from '../../../../asset/home/smallScreen/atsSmall.png'
function ATS() {
  return (
    <MotionConfig
      transition={{ duration: .5 }}
    >
      <motion.div
        initial={{ opacity: 0, scale: 0 }}
        animate={{ opacity: 1, scale: 1 }}
        className='min-h-[60vh]'
      >
        <h3 className='hidden lg:block text-[20px] md:text-[24px] tracking-[-1.2px] text-[#496896] font-krona'>ATS Integration</h3>
        <p className='hidden lg:block text-[16px] mt-[14px] leading-[21.6px] font-500'>Elevate your ATS to its full potential with Ambition Hire. Integrate effortlessly and unlock up to 90% automated hiring workflow for a more streamlined and effective recruitment process</p>
        <motion.div
          initial={{ opacity: 0, scale: 0.5, x: -1000 }}
          animate={{ opacity: 1, scale: 1 ,x:0}}
          transition={{ type: 'spring', stiffness: 100, damping: 10, mass: 2.5  }}
          className='mt-5 max-h-[425px]  overflow-hidden'>
          <img src={atsSmall} className='h-full w-full object-contain' />
        </motion.div>
        {/* <div className='flex w-full mt-5 gap-x-5'>
        <div className='flex  flex-col relative'>
          <img src={chat1} className='mt-10' />
          <img src={chat2} className='transform translate-x-[100px]' />
          <img src={chatline1} className='w-[15px] h-[73px] transform translate-x-[350px] translate-y-[80px] absolute' />
          <img src={chatline2} className='w-[15px] h-[73px] transform translate-x-[80px] translate-y-[140px] absolute' />
        </div>
      </div> */}
      </motion.div>
    </MotionConfig>
  )
}

export default ATS;