import React from "react";
import { PageLayout, RevealAnimation } from "../../ui/wrappers";
import ProductsMain from "../../components/products/productsMain";
import { Helmet } from "react-helmet";

function ProductsPage() {
  return (
    <PageLayout>
      {/* <ProductsMain/> */}
      <Helmet>
        <title>Online hiring tools from resume parser to proctoring.</title>
        <meta
          name="description"
          content="Variety of online assessments, aimed at covering the largest amount of job seekers across industries and functional domains."
        />
        <link rel="canonical" href="https://ambitionhire.ai/products" />
      </Helmet>
      <RevealAnimation>
        <ProductsMain />
      </RevealAnimation>
    </PageLayout>
  );
}

export default ProductsPage;
