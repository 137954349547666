import React, { useState } from 'react'
import { Offer_Box } from '../../../../ui/cards';
import main1 from '../../../../asset/home/oneWay/image.png';
import { motion } from 'framer-motion';
import {  useNavigate } from 'react-router-dom';

export default function OneWayInterview() {
    const scrollToTop = () => {
        window.scrollTo(0, 0);
      };  
      const navigate = useNavigate();
    
      const handleClick =  () => {
          navigate('/video-interview-platform');
          scrollToTop();
        };
    return (
        <>
            {/* tab text */}
            <div className='flex-1'>
                <h4 className='text-blue-light text-[16px] lg:text-[24px] font-500'>One-way video interview analyzes all of the below mentioned aspects based on factors such as eye movement, choice of words, speech, blink count, and facial tension                  
                <span
                className=" hover:underline  text-[16px] lg:text-[24px] font-semibold "
                onClick={handleClick}
              >
                {" "}
                View Details
              </span>{" "}
            </h4>
                <div className='mt-[24px] lg:mt-[48px] flex flex-col gap-4 lg:gap-6'>
                    <Offer_Box title="Cognitive Analysis" desc="This evaluates the candidate's mental processes, including their ability to think, reason, and problem-solve." />
                    <Offer_Box title="Fluency" desc="Fluency assesses how smoothly and articulately a candidate speaks. It examines the flow of their speech, their use of language, and their ability to communicate ideas clearly and without unnecessary pauses or hesitations." />
                    <Offer_Box title="Confidence Index" desc="The confidence index measures the candidate's self-assurance and poise during the interview. It looks at their body language, tone of voice, and overall demeanour to gauge how confident they are in their responses and their ability to handle pressure." />
                    <Offer_Box title="Facial Tension" desc="Facial tension analysis identifies signs of stress, anxiety, or discomfort in a candidate's facial expressions." />
                </div>
            </div>
            {/* tab images */}
            <motion.div
                initial={{ x: 1000 }}
                animate={{ x: 0 }}
                transition={{ type: 'spring', stiffness: 100, damping: 30, mass: 5}}
                className='flex-1 hidden sm:block overflow-hidden'>
                <img src={main1} className='lg:translate-x-[60px]' />
            </motion.div>
        </>
    )
};
