import React from "react";

export default function Resume2({ color }) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" fill="#FBFAFC" />
      <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" stroke="#CDB2DA" />
      <g clip-path="url(#clip0_651_41309)">
        <path
          d="M17.3337 15.334H13.3337M14.667 18.0007H13.3337M18.667 12.6673H13.3337M21.3337 12.534V19.4673C21.3337 20.5874 21.3337 21.1475 21.1157 21.5753C20.9239 21.9516 20.618 22.2576 20.2416 22.4493C19.8138 22.6673 19.2538 22.6673 18.1337 22.6673H13.867C12.7469 22.6673 12.1868 22.6673 11.759 22.4493C11.3827 22.2576 11.0767 21.9516 10.885 21.5753C10.667 21.1475 10.667 20.5874 10.667 19.4673V12.534C10.667 11.4139 10.667 10.8538 10.885 10.426C11.0767 10.0497 11.3827 9.74372 11.759 9.55197C12.1868 9.33398 12.7469 9.33398 13.867 9.33398H18.1337C19.2538 9.33398 19.8138 9.33398 20.2416 9.55197C20.618 9.74372 20.9239 10.0497 21.1157 10.426C21.3337 10.8538 21.3337 11.4139 21.3337 12.534Z"
          stroke="#1E1B1E"
          stroke-opacity="0.8"
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_651_41309">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(8 8)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
