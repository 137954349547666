import React from "react";
import { PageLayout } from "../../ui/wrappers";
import Founders from "../../components/about/founders";
import Strategies from "../../components/about/strategies";
import AboutMain from "../../components/about/aboutMain";
import { RevealAnimation } from "../../ui/wrappers";
import { Helmet } from "react-helmet";
function AboutPage() {
  return (
    <PageLayout>
      <Helmet>
        <title>
          A recruitment and comprehensive online assessment platform.
        </title>
        <meta
          name="description"
          content="Revolutionize your recruitment process with our wide range of assessments and proctoring-enabled platform"
        />
        <link rel="canonical" href="https://ambitionhire.ai/about" />
      </Helmet>
      <RevealAnimation>
        <AboutMain />
      </RevealAnimation>
      <RevealAnimation>
        <Strategies />
      </RevealAnimation>
      {/* <RevealAnimation>
                <Founders />
            </RevealAnimation> */}
    </PageLayout>
  );
}

export default AboutPage;
