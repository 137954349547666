import React from 'react';
import Avatar from '../../../ui/avatar';
import founder1 from '../../../asset/about/founder1.png'
import founder2 from '../../../asset/about/founder2.png';
import { GradientSection } from '../../../ui/wrappers';
function Founders() {
  const gradient = "linear-gradient(115deg, rgba(58, 189, 181, 0.08) 17.08%, rgba(255, 255, 255, 0.08) 39.71%, rgba(58, 189, 181, 0.16) 63.92%)"
  const boxStyle = {
    background: gradient,
  };
  return (
    <div className='overflow-hidden' id='founders'>
      <GradientSection gradient={gradient}>
        <div className=''>
          <h2 className='section_heading '>Our Founders</h2>
          <div className='flex items-center justify-center flex-col md:flex-row gap-6 mt-[48px] lg:mt-[111px]'>
            <Avatar name="Yashwant Singh" position="Co-Founder and CEO" avatar={founder1} />
            <Avatar name="Anmol Duggal" position="Co-Founder, Chief - Product and Architecture" avatar={founder2} />
          </div>
        </div>
      </GradientSection>
    </div>
  );
}

export default Founders;
