import React from 'react'
import { PageLayout, RevealAnimation } from '../../ui/wrappers'
import ContactForm from '../../components/contact/contactForm';

function ContactPage() {
  return (
    <PageLayout>
      <RevealAnimation>
        <ContactForm />
      </RevealAnimation>
    </PageLayout>
  )
}

export default ContactPage;