import { GradientBorder, GradientSection } from "../../ui/wrappers";
import { Icons } from "../../asset";
function ProctoringSection3({ gradient, color }) {
  const data = [
    {
      title: "Reliability",
      text: " - Trusted by institutions worldwide, our proctoring tool delivers consistent results and enhances assessment integrity with every use",
    },
    {
      title: "Cost-Effectiveness",
      text: " - We offer competitive pricing plans tailored to suit budgets of all sizes. Say goodbye to exorbitant proctoring fees and hello to cost-effective exam security",
    },
    {
      title: "Ongoing Support",
      text: " - Our dedicated support team is available round-the-clock to address any queries or technical issues promptly. We're committed to ensuring a seamless experience for our clients",
    },
  ];

  return (
    <GradientSection
      sectionHeading={"Why Choose Ambition Hire’s Proctoring Tool?"}
    >
      <p className="my-8 text-center text-sm sm:text-[22px] font-500 leading-[18.9px] sm:leading-[34px] text-primary-light px-10">
        Our proctoring tools utilize advanced technologies like AI, facial
        recognition, and screen monitoring to uphold integrity. AI algorithms
        analyze behavior, while facial recognition verifies identity
      </p>
      <div className="mt-8 sm:mt-[64px] lg:mt-24 flex flex-col gap-6">
        {data?.map((item, index) => (
          <div
            key={index}
            className="rounded-[16px] border py-6 px-8 text-primary-light text-base  sm:text-2xl leading-[21.6px] sm:leading-[32.4px]"
            style={{ background: "#8079C80D" }}
          >
            <div className="flex gap-4 font-700 ">
              <div className="h-6 w-6 mt-2">
                <Icons.Asterisk color={color} />
              </div>
              <p>
                <span className="font-700">{item?.title}</span>{" "}
                <span className="font-500">{item?.text}</span>
              </p>
            </div>
          </div>
        ))}
      </div>
    </GradientSection>
  );
}

export default ProctoringSection3;
