import React from "react";
import { PageLayout, RevealAnimation } from "../../ui/wrappers";
import QuestionLibraryMain from "../../components/questionLibrary/questionLibraryMain";
import QuestionBank from "../../components/questionLibrary/questionBank";
import { Helmet } from "react-helmet";

function QuestionLibraryPage() {
  const gradient =
    "linear-gradient(115deg, rgba(140, 181, 108, 0.18) 17.08%, rgba(255, 255, 255, 0.08) 39.71%, rgba(140, 181, 108, 0.24) 63.92%)";
  return (
    <PageLayout>
      <Helmet>
        <title>Diverse Assessment Modules and Question Banks</title>
        <meta
          name="description"
          content="Vast question banks covering industries, functional domains, and job roles. Each module addresses the specific skills and knowledge relevant to the job role."
        />
        <link
          rel="canonical"
          href="https://ambitionhire.ai/questions-library"
        />
      </Helmet>
      <RevealAnimation>
        <QuestionLibraryMain gradient={gradient} />
      </RevealAnimation>
      <RevealAnimation>
        <QuestionBank gradient={gradient} />
      </RevealAnimation>
    </PageLayout>
  );
}

export default QuestionLibraryPage;
