import React from 'react'
import right from '../../asset/general/arrow-right.svg'
import { FaArrowRight } from "react-icons/fa6";
import { motion } from 'framer-motion';
import { useMedia } from '../../utils/useMedia';
function LearnMoreButton({ link, bgColor, smallText }) {
    const { isMobile } = useMedia();
    return (
        <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            transition={{ duration: .1 }}
            className='flex gap-2 items-center justify-center sm:justify-start'>
            <span className='font-krona text-[12px] lg:text-[14px] tracking-[[-0.05em] leading-[15px] lg:leading-[17.5px] text-[#656565]'>
                {isMobile ? smallText : "Learn More"}
            </span>
            <p className={`p-1 text-white ${bgColor} rounded-full`}>
                <FaArrowRight />
            </p>
        </motion.div>
    )
}

export default LearnMoreButton;