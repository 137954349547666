import React, { useState } from "react";
import logo from "../../asset/home/logo.svg";
import heart from "../../asset/general/heart-flat-icon.svg";
import { FaTwitterSquare, FaPinterest } from "react-icons/fa";
import { Facebook, Instagram, Linkedin } from "../../asset/icons";
import { Link, useNavigate } from "react-router-dom";
import { getBlogs } from "../../service/api";

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    const blogs = await getBlogs();
    if (blogs.details && blogs.details.length > 0) {
      // Sort blogs by created_at in descending order and get the latest blog
      const latestBlog = blogs.details.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      )[0];
      const latestBlogId = latestBlog.id;
      scrollToTop();
      navigate(`/blog/${latestBlogId}`);
    }
    setLoading(false);
  };

  return (
    <div className="wrapper mx-[40px] xl:mx-auto grid lg:grid-cols-[2fr_1.3fr_1.3fr_1fr] place-content-center gap-x-6 pt-20 pb-24 gap-y-8 lg:gap-y-0">
      {/* Logo */}
      <div className="flex flex-col justify-between gap-6">
        <div className="flex items-center gap-4 ">
          <img src={logo} alt="Ambition Hire Logo" />
          <p className="text-[20px] lg:text-[32px] whitespace-nowrap text-primary font-500 leading-[48px] font-poppins">
            Ambition Hire
          </p>
        </div>
        <p className="text-secondary-color text-[16px] font-400 leading-[21.6px] satoshi text-gray-light">
          With a variety of assessments, aimed at covering the largest amount of
          job seekers across industries and functional domains.
        </p>

        <p className="hidden lg:flex items-center gap-2 text-[18px] font-500 leading-[24.3px] text-secondary whitespace-nowrap">
          <span>Made in BHARAT</span>
          <img src={heart} alt="heart" className="h-5 w-5" />
        </p>
        <p className="hidden lg:block text-[12px] font-400 leading-[16.2px] text-secondary-color satoshi text-gray-light mt-auto">
          Copyright © 2023 Ambition Hire Private Limited. All rights reserved.
        </p>
      </div>
      {/* Company */}
      <div className="flex flex-col gap-y-6">
        <div
          className="text-[16px] leading-[20px] tracking-normal font-krona border-b h-12 grid items-center"
          style={{ borderColor: "text-gray-light" }}
        >
          Company
        </div>
        <div className="flex flex-col gap-4 font-500 text-[16px] leading-[21.6px]">
          <Link to={"/about"} className="cursor-pointer " onClick={scrollToTop}>
            About
          </Link>
          <Link to={"#"} className="cursor-pointer " onClick={scrollToTop}>
            Careers
          </Link>
          <Link to={"/about"}>About</Link>

          <Link
            className="cursor-pointer "
            onClick={handleClick}
            disabled={loading}
          >
            Blog
          </Link>

          <Link
            to={"/contact"}
            className="cursor-pointer "
            onClick={scrollToTop}
          >
            Contact Us
          </Link>
          <Link to={"/faqs"} className="cursor-pointer" onClick={scrollToTop}>
            FAQs
          </Link>
        </div>
      </div>
      {/* Legal */}
      <div className="flex flex-col gap-y-6">
        <div
          className="text-[16px] leading-[20px] tracking-normal  font-krona border-b h-12 grid items-center"
          style={{ borderColor: "text-gray-light" }}
        >
          Legal
        </div>
        <div className="flex flex-col gap-4 font-500 text-[16px] leading-[21.6px]">
          <Link
            to={"/terms&conditions"}
            className="cursor-pointer"
            onClick={scrollToTop}
          >
            Terms of Use
          </Link>
          <Link
            to={"/privacyPolicy"}
            className="cursor-pointer"
            onClick={scrollToTop}
          >
            Privacy Policy
          </Link>
          <Link
            to={"/GDPR_Policy"}
            className="cursor-pointer"
            onClick={scrollToTop}
          >
            GDPR Policy
          </Link>
        </div>
      </div>

      {/* Social */}
      <div className="flex flex-col gap-y-6">
        <div
          className="text-[16px] leading-[20px] tracking-normal font-krona border-b h-12 grid items-center "
          style={{ borderColor: "text-gray-light" }}
        >
          Follow us
        </div>
        <div className="flex gap-7 font-500 text-[16px] leading-[21.6px] items-center">
          <Link
            to={"https://www.linkedin.com/company/ambitionhire/about/"}
            target="_blank"
          >
            <Linkedin />
          </Link>
          <Link
            to={"https://www.facebook.com/profile.php?id=61552755518622"}
            target="_blank"
          >
            <Facebook />
          </Link>
          <Link
            to={"https://www.instagram.com/ambitionhire.ai"}
            target="_blank"
          >
            <Instagram />
          </Link>
          <Link
            to={"https://twitter.com/AmbitionHire"}
            target="_blank"
            className=""
          >
            <FaTwitterSquare className="h-[20px] w-[20px]" />
          </Link>
          <Link
            to={"https://in.pinterest.com/ambitionhire/"}
            target="_blank"
            className=""
          >
            <FaPinterest className="h-[20px] w-[20px]" />
          </Link>
        </div>
      </div>
      <div className="lg:hidden text-center">
        <p className="text-[14px] font-500 leading-[18.9px] text-secondary whitespace-nowrap flex items-center gap-2">
          <span>Made in BHARAT</span>
          <img src={heart} alt="heart" className="h-5 w-5" />
        </p>
        <p className="text-[12px] font-400 leading-[16.2px] text-gray-light mt-4">
          Copyright © 2023 Ambition Hire. All rights reserved.
        </p>
      </div>
    </div>
  );
};

export default Footer;
