import React from "react";

export default function All({color}) {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" fill="#FBFAFC"/>
    <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" stroke="#CDB2DA"/>
    <g clip-path="url(#clip0_651_41326)">
    <path d="M21.6663 13.3333V18.8C21.6663 19.9201 21.6663 20.4802 21.4484 20.908C21.2566 21.2843 20.9506 21.5903 20.5743 21.782C20.1465 22 19.5864 22 18.4663 22H13.533C12.4129 22 11.8529 22 11.425 21.782C11.0487 21.5903 10.7427 21.2843 10.551 20.908C10.333 20.4802 10.333 19.9201 10.333 18.8V13.3333M10.3997 10H21.5997C21.973 10 22.1597 10 22.3023 10.0727C22.4278 10.1366 22.5298 10.2386 22.5937 10.364C22.6663 10.5066 22.6663 10.6933 22.6663 11.0667V12.2667C22.6663 12.64 22.6663 12.8267 22.5937 12.9693C22.5298 13.0948 22.4278 13.1968 22.3023 13.2607C22.1597 13.3333 21.973 13.3333 21.5997 13.3333H10.3997C10.0263 13.3333 9.83962 13.3333 9.69701 13.2607C9.57157 13.1968 9.46959 13.0948 9.40567 12.9693C9.33301 12.8267 9.33301 12.64 9.33301 12.2667V11.0667C9.33301 10.6933 9.33301 10.5066 9.40567 10.364C9.46959 10.2386 9.57157 10.1366 9.69701 10.0727C9.83962 10 10.0263 10 10.3997 10ZM14.3997 15.6667H17.5997C17.973 15.6667 18.1597 15.6667 18.3023 15.7393C18.4278 15.8032 18.5298 15.9052 18.5937 16.0307C18.6663 16.1733 18.6663 16.36 18.6663 16.7333V17.2667C18.6663 17.64 18.6663 17.8267 18.5937 17.9693C18.5298 18.0948 18.4278 18.1968 18.3023 18.2607C18.1597 18.3333 17.973 18.3333 17.5997 18.3333H14.3997C14.0263 18.3333 13.8396 18.3333 13.697 18.2607C13.5716 18.1968 13.4696 18.0948 13.4057 17.9693C13.333 17.8267 13.333 17.64 13.333 17.2667V16.7333C13.333 16.36 13.333 16.1733 13.4057 16.0307C13.4696 15.9052 13.5716 15.8032 13.697 15.7393C13.8396 15.6667 14.0263 15.6667 14.3997 15.6667Z" stroke="#1E1B1E" stroke-opacity="0.8" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
    <clipPath id="clip0_651_41326">
    <rect width="16" height="16" fill="white" transform="translate(8 8)"/>
    </clipPath>
    </defs>
    </svg>
    
  );
}
