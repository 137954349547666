import React from "react";
import { GradientBorder, GradientSection } from "../../ui/wrappers";
import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa6";

function VideoInterviewMain({ gradient, color }) {
  return (
    <GradientSection
      nav={true}
      color={color}
      bgColor={color}
      gradient={gradient}
      textColor={"text-light-green"}
    >
      <div className="flex flex-col relative">
        {/* Headings */}
        <h1 className="text-[24px] lg:text-[48px] font-krona tracking-[-0.05em]  max-w-[781px]">
          The Best Video Interview Platform from Ambition Hire
        </h1>
        <div className="mt-6">
          <div className="text-[14px] md:text-[22px] font-500 leading-[18.9px]  md:leading-[34px] mt-4 lg:mt-6 text-[#505050]">
            <p>
              In today's fast-paced and globalized job market, finding the right
              talent can be a daunting task. Traditional in-person interviews
              often pose logistical challenges, limiting your ability to connect
              with top candidates from diverse locations. That's where our
              <Link
                className=" hover:underline text-blue-800"
                to="/video-interview-platform"
              >
                {" "}
                video interview platforms
              </Link>{" "}
              come into play, revolutionizing the way you conduct interviews and
              streamlining your entire hiring process.
            </p>

            <p className="mt-5">
              Virtual interview platforms are cutting-edge software solutions
              that enable you to conduct seamless, face-to-face interviews with
              candidates remotely. By leveraging the power of video conferencing
              technology, our{" "}
              <Link
                className=" hover:underline text-blue-800"
                to="/video-interview-platform"
              >
                {" "}
                virtual interview platforms
              </Link>{" "}
              eliminate geographical barriers, allowing you to connect with top
              talent from across the globe without the need for costly travel
              expenses
            </p>
          </div>
        </div>
        {/* Buttons */}
        <div className="mt-6 mb-12 lg:mb-24 lg:mt-[64px] flex flex-col   sm:flex-row gap-4 lg:gap-6">
          <Link
            to={"/quiz-demo"}
            className="flex gap-2 lg:gap-[18px] items-center justify-center bg-light-green px-[32px] py-[12px] lg:py-4 rounded-[128px] w-full lg:w-auto"
          >
            <span className="text-[13px] lg:text-[18px] font-krona text-white">
              Schedule a Quick Demo
            </span>
            <p className="bg-white rounded-full grid place-content-center p-[5.455px] lg:p-[7.273px]">
              <FaArrowRight className={`text-[20px] text-light-green`} />
            </p>
          </Link>
          {/* <Link to={'/quiz-demo'} className='font-krona text[14px] lg:text-[18px] text-light-green border-[3px] border-light-green px-[40px] py-[12px] lg:py-[20px] rounded-[128px] whitespace-nowrap'>
            Explore Assesst LIbrary
          </Link> */}
        </div>

        {/* Trust Box */}
        <div
          className="relative top-32 lg:top-48 border py-12 px-8 flex flex-col gap-8 rounded-[16px] bg-white mb-12 lg:mb-24"
          style={{ boxShadow: "0px 16px 36px 0px #5A5A5A14" }}
        >
          <h3 className="text-xl lg:text-[32px] font-krona tracking-[-0.05em] leading-6 lg:leading-10 text-center">
            Tailored Solutions for Your Unique Needs
          </h3>
          <p className="text-sm lg:text-xl leading-[18.9px] lg:leading-[34px] text-center text-primary-light">
            At <Link
                className=" hover:underline text-blue-800"
                to="/"
              >
                {" "}
                Ambition Hire
              </Link>{" "}, we recognize that every organization has unique
            requirements when it comes to video interviews. That's why we offer
            a range of customizable video interview platforms designed to meet
            your specific needs. Whether you're a small startup or a large
            enterprise, we have a solution that fits your budget, technical
            requirements, and hiring workflow
          </p>
        </div>
      </div>
    </GradientSection>
  );
}

export default VideoInterviewMain;
