import React from 'react';

const GradientBorder = ({ gradient, radius, borderWidth, left, right, top, bottom, children }) => {
    const boxStyle = {
        position: 'relative',
    };
    const beforeStyle = {
        content: '""',
        position: 'absolute',
        inset: 0,
        borderRadius: radius || '50px',
        padding: `${borderWidth || '2px'}`,
        background: gradient || 'linear-gradient(112.56deg, #C3BFEB 0%, #FFFFFF 100%)',
        WebkitMask: `
          linear-gradient(#fff 0 0) content-box,
          linear-gradient(#fff 0 0)
        `,
        WebkitMaskComposite: 'xor',
        maskComposite: 'exclude',
        pointerEvents: 'none',
    };

    if (!left) {
        beforeStyle.paddingLeft  = '0px';
    }

    if (!right) {
        beforeStyle.paddingRight = '0px';
    }

    // if (!top) {
    //     beforeStyle.paddingTop = '0px';
    // }

    // if (!bottom) {
    //     beforeStyle.paddingBottom = '0px';
    // }

    return (
        <div style={boxStyle} className='h-full'>
            <div style={beforeStyle}></div>
            {children}
        </div>
    );
};

export default GradientBorder;
