import React, { useEffect, useState } from 'react';
import resume1 from '../../../../asset/home/resumeParser/Group 8085.png';
import resume2 from '../../../../asset/home/resumeParser/Group 8086.png';
import resume3 from '../../../../asset/home/resumeParser/Group 8087.png';
import { Offer_Box } from '../../../../ui/cards';
import { motion } from 'framer-motion';
import {  useNavigate } from 'react-router-dom';

export default function ResumeParser() {
    const scrollToTop = () => {
        window.scrollTo(0, 0);
      };  
      const navigate = useNavigate();
    
      const handleClick =  () => {
          navigate('/resume-parser');
          scrollToTop();
        };
    const [zIndex, setZIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setZIndex(prevZIndex => (prevZIndex === 0 ? 1 : 0));
        }, 2000);

        // Clear the interval on component unmount to avoid memory leaks
        return () => clearInterval(intervalId);
    }, []);
    const containerVariants = {
        initial: { opacity: 0 },
        animate: { opacity: 1, transition: { type: 'spring', stiffness: 500, damping: 10, staggerChildren: .4 } },
    };
    const cardVariant1 = {
        initial: { opacity: 0, x: 1000 },
        animate: { opacity: 1, x: 0, transition: { type: 'spring', stiffness: 100, damping: 25, mass: 5 } },
    };
    const cardVariant2 = {
        initial: { opacity: 0, x: -1000 },
        animate: { opacity: 1, x: 0, transition: { type: 'spring', stiffness: 100, damping: 25, mass: 5 } },
    };

    const getZIndexClass = () => {
        return zIndex === 0 ? 'relative z-[5]' : 'relative z-40';
    };
    return (
        <>
            {/* tab text */}
            <div className='flex-1'>
                <h4 className='text-blue-light text-[16px] lg:text-[24px] leading-[21.6px] lg:leading-[32.4px] font-500'>Make informed decisions on candidate invitations for assessments, optimizing credit utilization and significantly reducing costs.  
                <span
                className=" hover:underline  text-[16px] lg:text-[24px] font-semibold "
               onClick={handleClick}
              >
                {" "}
                 View Details
              </span>{" "}</h4>
                
                <div className='flex flex-col mt-6 lg:mt-[48px] gap-4 lg:gap-6'>
                    <Offer_Box title="Parse Single Resume w/ Single Job" desc="Recruiters can effortlessly upload a single resume, choose a single job from their listings. The system then provides a percentage score to the candidate based on their suitability for that role" />
                    <Offer_Box title="Parse Single Resume w/ Multiple Jobs" desc="Select multiple jobs to get the best fit role amongst the jobs selected for the candidate" />
                    <Offer_Box title="Parse Multiple Resumes w/ Single Job" desc="Upload multiple resumes, choose a single job to obtain a stack ranking, assigning a percentage score to each candidate for the specific role" />
                    <Offer_Box title="Parse Multiple Resumes w/ Multiple Jobs" desc="Upload multiple candidates, choose multiple jobs and let the system provide a stack ranking, assigning a percentage score to each candidate based on their suitability for specific roles" />
                </div>
            </div>
            {/* tab images */}
            <motion.div
                variants={containerVariants}
                initial="initial"
                animate="animate"
                className='flex-1 mvisible overflow-hidden'>
                <motion.div variants={cardVariant1} className={getZIndexClass()}>
                    <img src={resume1} alt='tab_image' />
                </motion.div>
                <motion.div variants={cardVariant2}>
                    <img src={resume2} alt='tab_image' className='transform translate-x-[20px] lg:translate-x-[50px]  z-20 relative' />
                </motion.div>
                <motion.div variants={cardVariant1} className={getZIndexClass()}>
                    <img src={resume3} alt='tab_image' className='transform translate-x-[-10px] lg:translate-x-[10px]  z-10 relative' />
                </motion.div>
            </motion.div>
        </>
    )
}
