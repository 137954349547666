import React from 'react';
import { motion } from 'framer-motion';
import { useField, useFormikContext } from 'formik';

function Select({ id, title, options }) {
    const { setFieldValue } = useFormikContext();
    const [field, meta] = useField(id);

    // Set the initial value to the first option if no value is present
    React.useEffect(() => {
        if (!field.value && options.length > 0) {
            setFieldValue(id, options[0].name);
        }
    }, [field.value, id, options, setFieldValue]);

    const handleOptionClick = (name, event) => {
        event.preventDefault();
        setFieldValue(id, name);
    };

    return (
        <div className='space-y-3 w-full'>
            <h3 className='text-[12px] font-bold'>{title}</h3>
            <div className='flex gap-4 w-full overflow-x-scroll scrollbar-hide'>
                {
                    options.map((option) => (
                        <div className='flex items-center p-1' key={option?.name}>
                            <motion.button
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.8 }}
                                transition={{ duration: .05 }}
                                className={`rounded-[128px] px-8 py-3 font-700 leading-normal text-[14px] ${field.value === option.name ? 'bg-violet text-white border-violet' : 'border-violet'} font-satoshi text-[14px] whitespace-nowrap transition-all duration-200 ease-in-out border-2`}
                                onClick={(event) => handleOptionClick(option?.name, event)}
                            >
                                {option.name}
                            </motion.button>
                        </div>
                    ))
                }
            </div>
            {/* {meta.touched && meta.error && (
                <span className="font-500 leading-[16.2px] text-red-500">
                    {meta.error}
                </span>
            )} */}
        </div>
    );
}

export default Select;
