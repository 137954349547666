import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="bg-gray-100 min-h-screen">
      <div className="max-w-4xl mx-auto p-4 flex flex-col gap-10 py-20">
        <h1 className="text-3xl font-900 underline text-center">
          Privacy Policy
        </h1>
        <div>
          <h2 className="text-2xl font-bold mb-4 text-center">Introduction</h2>
          <p className="mb-4">
            'AMBITION HIRE PRIVATE LIMITED' (hereinafter referred to as,
            “AmbitionHire” or “the Company”) is committed to protecting the
            integrity and privacy of personal information provided to Ambition
            Hire's platform (the “Service”). This policy describes the types of
            information Ambition Hire collects, and the ways in which AMBITION
            HIRE uses and discloses that information.
          </p>
        </div>
        <div>
          <h2 className="text-2xl font-bold mb-4 text-center">
            Cookies and Other Tracking Technologies
          </h2>
          <p className="mb-4">
            Some of our web pages utilize "cookies" and other tracking
            technologies. A "cookie" is a small text file that may be used, for
            example, to collect information about activity on the web site. Some
            cookies and other technologies may serve to recall Personal
            Information previously indicated by a Web user. Most browsers allow
            you to control cookies, including whether or not to accept them and
            how to remove them. You may set most browsers to notify you if you
            receive a cookie, or you may choose to block cookies with your
            browser, but please note that if you choose to erase or block your
            cookies, you will need to re-enter your original user ID and
            password to gain access to certain parts of the web site. Tracking
            technologies may record information such as internet domain and host
            names; internet protocol (IP) addresses; browser software and
            operating system types; clickstream patterns; and dates and times
            that our site is accessed. Our use of cookies and other tracking
            technologies allows us to improve our web site and your web
            experience. We may also analyze information that does not contain
            Personal Information for trends and statistics.
          </p>
        </div>
        <div>
          <h2 className="text-2xl font-bold mb-4 text-center">
            THIRD PARTY SERVICES
          </h2>
          <p className="mb-4">
            THE SERVICE CONTAINS REFERENCES AND LINKS TO WEBSITES AND SERVICES
            PROVIDED BY THIRD PARTIES, INCLUDING EMPLOYERS. ANY PERSONALLY
            IDENTIFIABLE INFORMATION YOU PROVIDE ON THIRD PARTY SITES OR
            SERVICES (INCLUDING COMMUNICATIONS BETWEEN CANDIDATES OR EMPLOYEES
            AND EMPLOYERS THROUGH THE SERVICE) IS PROVIDED DIRECTLY TO THAT
            THIRD PARTY AND IS SUBJECT TO THAT THIRD PARTY’S POLICIES GOVERNING
            PRIVACY AND SECURITY. WE ARE NOT RESPONSIBLE FOR THE CONTENT OR
            PRIVACY AND SECURITY PRACTICES AND POLICIES OF THIRD PARTY SITES OR
            SERVICES TO WHICH LINKS ARE DISPLAYED ON THE SERVICE. WE ENCOURAGE
            YOU TO LEARN ABOUT THIRD PARTIES’ PRIVACY AND SECURITY POLICIES
            BEFORE PROVIDING THEM WITH PERSONALLY IDENTIFIABLE INFORMATION.
          </p>
        </div>
        <div>
          <h2 className="text-2xl font-bold mb-4 text-center">DATA SECURITY</h2>
          <p className="mb-4">
            AMBITION HIRE is not an information technology security service
            provider. While we do employ certain limited physical, managerial,
            and technical safeguards designed to help improve the integrity and
            security of our systems and the data stored on our systems, no
            physical or technical system, process, transmission, or storage
            mechanism is completely secure or immune from outages, losses,
            attacks, circumvention, design or implementation flaws, and human
            error. Therefore, we cannot and do not ensure or warrant that
            information you transmit to AMBITION HIRE will not be lost or
            compromised, either during or after transmission
            <br></br> If AMBITION HIRE learns of a security systems breach, then
            we may attempt to notify you electronically so that you can take
            appropriate protective steps. AMBITION HIRE may post a notice
            through the Service if a security breach occurs. Depending on where
            you live, you may have a legal right to receive notice of a security
            breach in writing.
          </p>
        </div>
        <div>
          <h2 className="text-2xl font-bold mb-4 text-center">
            INFORMATION SHARING & DISCLOSURE
          </h2>
          <ul className="list-disc pl-4 m-4">
            <li>
              AMBITION HIRE does not rent, sell, or share personal information
              about you with other people (save with your consent) or
              non-affiliated companies except to provide products or services
              you've requested, when we have your permission, or under the
              following circumstances
              <ul className="pl-4 list-disc m-4">
                <li>
                  We provide the information to trusted partners who work on
                  behalf of or with AMBITION HIRE under confidentiality
                  agreements. These companies may use your personal information
                  to help AMBITION HIRE communicate with you about offers from
                  AMBITION HIRE and our marketing partners. However, these
                  companies do not have any independent right to share this
                  information.
                </li>
                <li>
                  We respond to subpoenas, court orders, or legal process, or to
                  establish or exercise our legal rights or defend against legal
                  claims;
                </li>
                <li>
                  We believe it is necessary to share information in order to
                  investigate, prevent, or take action regarding illegal
                  activities, suspected fraud, situations involving potential
                  threats to the physical safety of any person, violations of
                  AMBITION HIRE’s terms of use, or as otherwise required by law.
                </li>
                <li>
                  We transfer information about you if AMBITION HIRE is acquired
                  by or merged with another company. In this event, AMBITION
                  HIRE will notify you before information about you is
                  transferred and becomes subject to a different privacy policy.
                </li>
              </ul>
            </li>
            <li>
              AMBITION HIRE does not provide any personal information to the
              advertiser.
            </li>
          </ul>
        </div>
        <div>
          <h2 className="text-2xl font-bold mb-4 text-center">
            CHANGES TO THIS PRIVACY POLICY
          </h2>
          <p className="mb-4">
            AMBITION HIRE reserves the right to update, change or modify this
            policy at any time. The policy shall come to effect from the date of
            such update, change or modification.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
