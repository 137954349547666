import React from "react";
import { GradientSection, RevealAnimation } from "../../ui/wrappers";
import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa6";
import Footer from "../../components/footer";
import { BsLinkedin, BsFacebook, BsInstagram, BsTwitter } from "react-icons/bs";
function ThanksPage() {
  const color = "#189C82";
  const gradient = `linear-gradient(115deg, rgba(24, 156, 130, 0.18) 17.08%, rgba(255, 255, 255, 0.08) 39.71%, rgba(24, 156, 130, 0.24) 63.92%)`;
  return (
    <div>
      <GradientSection
        nav={true}
        color={"#189C82"}
        bgColor={"bg-teal"}
        gradient={gradient}
        textColor={"text-[#189C82]"}
      >
        <div className="flex flex-col items-center justify-center">
          {/* Headings */}
          <h1 className="text-[24px] lg:text-[48px] font-krona tracking-[-2.4px] text-center max-w-[781px]">
            Thanks for contacting Ambition Hire. We will get in touch with you
            soon.
          </h1>
          <div className="text-[14px] lg:text-[22px] font-500 leading-[22px] lg:leading-normal mt-4 lg:mt-6 text-[#505050]">
            <p>Explore our social media handles for more updates.</p>
            <div class="flex gap-5 justify-between flex-wrap mt-5">
              <Link
                to={"https://www.linkedin.com/company/ambitionhire/about/"}
                target="_blank"
              >
                <BsLinkedin className="h-6 w-6 lg:h-12 lg:w-12" />
              </Link>
              <Link
                to={"https://www.facebook.com/profile.php?id=61552755518622"}
                target="_blank"
              >
                <BsFacebook className="h-6 w-6 lg:h-12 lg:w-12" />
              </Link>
              <Link
                to={"https://www.instagram.com/ambitionhire_"}
                target="_blank"
              >
                <BsInstagram className="h-6 w-6 lg:h-12 lg:w-12" />
              </Link>
              <Link
                to={"https://twitter.com/AmbitionHire"}
                target="_blank"
                className=""
              >
                <BsTwitter className="h-6 w-6 lg:h-12 lg:w-12" />
              </Link>
            </div>
          </div>

          {/* Buttons */}
          <div className="mt-6 lg:mt-[64px] flex flex-col items-center sm:items-start   sm:flex-row gap-4 lg:gap-6 lg:pb-20">
            <Link
              to={"https://zcal.co/ambitionhire"}
              className="flex gap-2 lg:gap-[18px] items-center justify-center bg-teal px-[32px] py-[12px] lg:py-4 rounded-[128px] w-full"
            >
              <span className="text-[13px] lg:text-[18px] font-krona text-white">
                Book Your Slot Now
              </span>
              <p className="bg-white rounded-full grid place-content-center p-[5.455px] lg:p-[7.273px]">
                <FaArrowRight className={`text-[20px] text-teal`} />
              </p>
            </Link>
          </div>

          <div></div>
        </div>
      </GradientSection>
      <RevealAnimation>
        <Footer />
      </RevealAnimation>
    </div>
  );
}

export default ThanksPage;
