import React from "react";
import styles from "./style.module.css";
import noise from "../../../asset/home/Noise.png";
import NavBar from "../../nav";
import trial from "../../../asset/about/trial.svg";
import { motion } from "framer-motion";
import star from "../../../asset/about/star.svg";
import { AboutBox, AboutTextBox } from "../../../ui/cards";
import { QuizButton, ContactUsButton } from "../../../ui/buttons";
function AboutMain() {
  const color = "#20A19A";
  const inlineStyle = {
    scalingElement: {
      animation: "scaleUpDown 2s infinite alternate",
    },
    "@keyframes scaleUpDown": {
      "0%": { transform: "scale(1)" },
      "100%": { transform: "scale(1.2)" },
    },
  };

  return (
    <section className={`mix-blend-overlay border relative`} id="aboutMain">
      <div
        className={`absolute -z-10 opacity-85 ${styles.gradient_bg} h-full w-full top-0 left-0`}
      ></div>

      <div className="wrapper mt-9 ">
        {/* navbar */}
        <NavBar
          bgColor={"bg-[#3ABDB5]"}
          trailLogo={trial}
          color={color}
          textColor={"text-[#3ABDB5]"}
        />

        <main className="my-20">
          <h2 className="section_heading text-[20px] sm:text-[24px] lg:text-[48px] leading-[25px] lg:leading-[60px] text-center sm:text-left tracking-[-0.05em]">
            Get to know about us
          </h2>
          <p className="text-[#555555] text-[14px] lg:text-[22px] leading-[22px] lg:leading-[29.7px] font-500 mt-6">
            Ambition Hire is a recruitment and a comprehensive assessment
            platform that offers an array of assessments, carefully crafted to
            cater to job seekers from diverse industries and functional domains,
            all while maintaining an easy-to-use interface. For job seekers, We
            provide real-time application status updates, reducing uncertainty
            and enhancing their experience.
            <br></br>
            <br></br>
            Meanwhile, for recruiters, our platform offers unparalleled
            flexibility, proctoring and anti-cheat solutions. Recruiters can
            also customize the platform's appearance to align seamlessly with
            their organization's branding. We're committed to offering 24/7
            customer support to address queries and provide assistance whenever
            needed.
          </p>
          <div className="flex flex-col lg:flex-row mt-6 gap-6">
            <div className="flex-1 flex flex-col">
              <div className="mt-8">
                <h3 className="text-[14px] lg:text-[22px] text-center sm:text-left leading-[18.9px] lg:leading-[29.7px] font-700 lg:text-[#555555]">
                  Join us in shaping the future of recruitment and career
                  success.
                </h3>
                <div className="flex flex-col sm:flex-row gap-4  lg:gap-6 items-center text-center mt-4 lg:mt-10 ">
                  <QuizButton
                    link={"#"}
                    text={"Take a Quick Demo"}
                    bgColor={"bg-light-green"}
                    textColor={"text-light-green"}
                  />
                  <ContactUsButton
                    link="/contact"
                    text={"Contact Us"}
                    borderColor="border-light-green"
                    textColor={"text-light-green"}
                  />
                </div>
              </div>
              {/* <motion.div
                className="hidden lg:block mt-auto"
                animate={{
                  scale: [1, 1.1, 1],
                }}
                transition={{
                  repeat: Infinity,
                  duration: 2,
                  repeatType: "reverse", 
                  ease:'linear'
                }}
              >
                <AboutTextBox />
              </motion.div> */}
            </div>
            <div className="flex flex-col xs:flex-row lg:flex-col gap-4 lg:gap-6 justify-center sm:justify-start">
              <AboutBox
                text={
                  "What is our strategy and how do we deliver our promises?"
                }
                smallText={"Our Strategy"}
                link={"strategies"}
              />
              <div className="opacity-0">
                <AboutBox
                  text={"Meet our founders"}
                  link={"founders"}
                  smallText={"Our Founders"}
                />
              </div>
            </div>
            {/* <div className="hidden sm:block lg:hidden">
              <AboutTextBox />
            </div> */}
          </div>
        </main>
      </div>
      <img
        src={noise}
        className="w-full h-full object-fill opacity-[20%] absolute top-0 -z-10 "
      />
    </section>
  );
}

export default AboutMain;
