import React from 'react'
import { GradientSection } from '../../ui/wrappers';
import { ContactUsButton, QuizButton } from '../../ui/buttons';
import { PlayCircle } from '../../asset/icons';
import main1 from '../../asset/faqs/main1.png';
import main2 from '../../asset/faqs/main2.png';
import { FaArrowRight } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
function FAQMain({ gradient }) {
    const color = "#3883C8";
    const containerVariants = {
        initial: { opacity: 0 },
        animate: { opacity: 1, transition: { staggerChildren: .4 } },
    };
    const cardVariant1 = {
        initial: { opacity: 0, x: 1000 },
        animate: { opacity: 1, x: 0, transition: { type: 'spring', stiffness: 100, damping: 20, mass: 5 } },
    };
    const cardVariant2 = {
        initial: { opacity: 0, x: -1000 },
        animate: { opacity: 1, x: 20, y: -50, transition: { type: 'spring', stiffness: 100, damping: 20, mass: 5 } },
    };
    return (
        <GradientSection gradient={gradient} nav={true} color={color} bgColor={"bg-[#3883C8]"} textColor={`text-[#3883C8]`}>
            <div className='flex flex-col-reverse md:flex-row mt-8 lg:mt-[100px]'>
                {/* Text    */}
                <div className='flex-1 pb-[42px] max-w-[681px] -mt-16 sm:mt-0'>
                    <h1 className='font-krona text-[24px] lg:text-[48px] tracking-normal leading-normal lg:leading-[60px] text-center sm:text-left'>Frequently Asked Questions</h1>
                    <div className='mt-6 font-500 text-[16px] lg:text-[22px] leading-[29.7px] text-[#555555] text-center sm:text-left'>
                        <p>
                            Explore our Frequently Asked Questions to find quick and comprehensive answers.
                        </p>
                        <p className='mt-[13px] lg:mt-[16px]'>
                            From account setup to assessment details, we've got you covered. Navigate with confidence as you discover the ins and outs of AmbitionHire
                        </p>
                    </div>

                    {/* Buttons */}
                    <div className='mt-6 lg:mt-[64px] flex flex-col items-center sm:items-start gap-4 lg:gap-6'>
                        <Link to={'/contact'} className='flex gap-2 lg:gap-[18px] items-center justify-center bg-sky-blue px-[32px] py-[12px] lg:py-4 rounded-[128px] w-full max-w-[272px] lg:max-w-[350px]'>
                            <span className='text-[13px] lg:text-[18px] font-krona text-white'>Contact Us</span>
                            <p className='bg-white rounded-full grid place-content-center p-[5.455px] lg:p-[7.273px]'>
                                <FaArrowRight className={`text-[20px] text-sky-blue`} />
                            </p>
                        </Link>
                        <Link to={'/quiz-demo'} className='font-krona text[14px] text-center lg:text-[18px] text-sky-blue border-[3px] border-sky-blue px-[40px] py-[12px] lg:py-[20px] rounded-[128px] whitespace-nowrap w-full max-w-[272px] lg:max-w-[350px]'>
                            Take a Quick Demo
                        </Link>
                    </div>
                    <div className='mt-8 lg:mt-[24px] flex gap-2 items-center justify-center sm:justify-start font-700 text-[14px] lg:text-[18px] text-sky-blue leading-[24.3px]'>
                        <PlayCircle color={color} />
                        <a href='#' className=' '>Check out our Product Summary</a>
                    </div>
                </div>

                {/* Images */}
                <motion.div
                    variants={containerVariants}
                    initial="initial"
                    animate="animate"
                    className='flex-1 grid place-content-center'>
                    <motion.div variants={cardVariant1} className=''>
                        <img src={main1} className='object-cover' />
                    </motion.div>
                    <motion.div variants={cardVariant2} className='translate-y-[-105px] scale-95 lg:scale-100 sm:translate-x-[20px] lg:translate-x-[20px]'>
                        <img src={main2} />
                    </motion.div>
                </motion.div>
            </div>
        </GradientSection>
    )
}

export default FAQMain;