import All from "./all";
import Check from "./check";
import Camera from "./camera";
import Language from "./language";
import Laptop from "./laptop";
import Psycho from "./psycho";
import Resume from "./resume";
import Resume2 from "./resume2";
const ProductIcons= { All, Check ,Language,Laptop,Psycho,Resume,Resume2,Camera};
export default ProductIcons;
