import React from 'react'

function ScrollDown() {
    return (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="chevron-down-double">
                <path id="Icon" d="M4.66602 9.16667L7.99935 12.5L11.3327 9.16667M4.66602 4.5L7.99935 7.83333L11.3327 4.5" stroke="black" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
            </g>
        </svg>

    )
}

export default ScrollDown