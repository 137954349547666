import React, { useEffect, useState } from 'react';
import hire1 from '../../../../asset/home/hire1.png';
import hire2 from '../../../../asset/home/hire2.png';
import userInterface1 from '../../../../asset/home/smallScreen/userInterface1.png';
import { MotionConfig, motion } from 'framer-motion';

function UserInterface() {
  const [zIndex, setZIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setZIndex(prevZIndex => (prevZIndex === 0 ? 1 : 0));
    }, 2000);
    // Clear the interval on component unmount to avoid memory leaks
    return () => clearInterval(intervalId);
  }, []);
  const containerVariants = {
    initial: { opacity: 0 },
    animate: { opacity: 1, transition: { type: 'spring', stiffness: 500, damping: 20, staggerChildren: 0.5 } },
  };

  const imgVariants = {
    initial: { opacity: 0, x: -1000 },
    animate: { opacity: 1, x: 0, transition: { type: 'spring', stiffness: 100, damping: 20, mass:5 } },
  };

  const getZIndexClass = () => {
    return zIndex === 0 ? 'relative z-[5]' : 'relative z-40';
  };

  return (
    <MotionConfig
      transition={{ duration: 1 }}
    >
      <motion.div
        variants={containerVariants}
        initial="initial"
        animate="animate"
        className='lg:mt-[24px]'>
        <h3 className='lvisible text-[20px] md:text-[24px] tracking-[-1.2px] text-blue-light font-krona'>Intuitive User Interface</h3>
        <p className='lvisible text-[16px] mt-[14px] font-500 leading-[21.px]'>Offering you a design that prioritizes ease of use, recruiters can seamlessly navigate through the platform to effortlessly create, customize, and manage job postings and assessments.</p>
        <motion.div className='lg:mt-[24px]' >
          <motion.div variants={imgVariants} className='relative z-10'>
            <motion.img
              src={hire1}
              className='lvisible object-fill'
            />
            <motion.img
              variants={imgVariants}
              src={userInterface1}
              className='lg:hidden object-fill'
            />
          </motion.div>
          <motion.div variants={imgVariants} className={getZIndexClass()}>
            <motion.img
              src={hire2}
              className='lvisible transform md:translate-x-[30px] md:translate-y-[-100px] xl:translate-x-[230px] xl:translate-y-[-216px] object-fill'
            />
            <motion.img
              src={userInterface1}
              className='lg:hidden transform translate-x-[50px] translate-y-[-145px] object-fill'
            />
          </motion.div>
        </motion.div>
      </motion.div>
    </MotionConfig>
  );
}

export default UserInterface;
