import React from "react";

export default function Resume({ color }) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" fill="#FBFAFC" />
      <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" stroke="#CDB2DA" />
      <g clip-path="url(#clip0_651_41011)">
        <path
          d="M17.3337 15.334H13.3337M14.667 18.0007H13.3337M18.667 12.6673H13.3337M21.3337 15.0007V12.534C21.3337 11.4139 21.3337 10.8538 21.1157 10.426C20.9239 10.0497 20.618 9.74372 20.2416 9.55197C19.8138 9.33398 19.2538 9.33398 18.1337 9.33398H13.867C12.7469 9.33398 12.1868 9.33398 11.759 9.55197C11.3827 9.74372 11.0767 10.0497 10.885 10.426C10.667 10.8538 10.667 11.4139 10.667 12.534V19.4673C10.667 20.5874 10.667 21.1475 10.885 21.5753C11.0767 21.9516 11.3827 22.2576 11.759 22.4493C12.1868 22.6673 12.7469 22.6673 13.867 22.6673H15.667M22.667 22.6673L21.667 21.6673M22.3337 20.0007C22.3337 21.2893 21.289 22.334 20.0003 22.334C18.7117 22.334 17.667 21.2893 17.667 20.0007C17.667 18.712 18.7117 17.6673 20.0003 17.6673C21.289 17.6673 22.3337 18.712 22.3337 20.0007Z"
          stroke="#1E1B1E"
          stroke-opacity="0.8"
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_651_41011">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(8 8)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
