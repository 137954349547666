import React from 'react'
import { GradientSection } from '../../ui/wrappers';
import {Accordion} from '../../ui/cards';
import { faqsQuestionsAnswers } from '../../data/faqsData';
function FAQsQuestion() {

  return (
    <GradientSection>
        <Accordion data={faqsQuestionsAnswers}/>
    </GradientSection>
  )
}

export default FAQsQuestion;