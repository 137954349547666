import React from "react";

export default function Language({ color }) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" fill="#FBFAFC" />
      <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" stroke="#CDB2DA" />
      <g clip-path="url(#clip0_651_41370)">
        <path
          d="M16.6084 19.3333H21.391M16.6084 19.3333L15.333 22M16.6084 19.3333L18.5185 15.3393C18.6725 15.0175 18.7494 14.8566 18.8547 14.8057C18.9463 14.7615 19.0531 14.7615 19.1446 14.8057C19.2499 14.8566 19.3269 15.0175 19.4808 15.3393L21.391 19.3333M21.391 19.3333L22.6663 22M9.33301 11.3333H13.333M13.333 11.3333H15.6663M13.333 11.3333V10M15.6663 11.3333H17.333M15.6663 11.3333C15.3356 13.3049 14.5681 15.0908 13.4434 16.5896M14.6663 17.3333C14.258 17.1499 13.8414 16.8947 13.4434 16.5896M13.4434 16.5896C12.5417 15.8985 11.7348 14.9511 11.333 14M13.4434 16.5896C12.3736 18.0153 10.9806 19.1812 9.33301 20"
          stroke="#1E1B1E"
          stroke-opacity="0.8"
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_651_41370">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(8 8)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
