import { Link } from "react-router-dom";
import { GradientSection } from "../../ui/wrappers";
function ProctoringSection2({ gradient }) {
  const data = [
    {
      title: "Advanced Technology",
      text: "Ambition Hire’s proctoring tool harnesses state-of-the-art AI and machine learning algorithms to monitor exams effectively. It utilizes facial recognition, keystroke analysis, and browser lockdown features to deter cheating attempts",
    },
    {
      title: "User-Friendly Interface",
      text: "Designed with simplicity in mind, our proctoring tool offers an intuitive interface for both administrators and test-takers. Smooth navigation and clear instructions ensure a hassle-free assessment experiences",
    },
    {
      title: "Comprehensive Monitoring",
      text: "From live proctoring to automated flagging of suspicious behavior, our tool provides comprehensive monitoring throughout the assessment duration. Real-time alerts empower recruiters to intervene promptly when necessary",
    },
    {
      title: "Secure Data Handling",
      text: "We prioritize data security and confidentiality. Our proctoring tool adheres to strict privacy standards, encrypting sensitive information and ensuring compliance with regulations",
    },
  ];

  return (
    <GradientSection
      sectionHeading={"What sets Ambition Hire’s Proctoring Tool apart?"}
    >
      <div className="mt-8 sm:mt-[64px] lg:mt-24 grid grid-cols-1 md:grid-cols-2 gap-8">
        {data?.map((item, index) => (
          <div
            key={index}
            className="rounded-[24px] p-8"
            style={{ background: gradient }}
          >
            <h3 className="text-base sm:text-xl font-krona leading-5 sm:leading-[25px] text-center">
              {item.title}
            </h3>
            <p className="text-sm sm:text-lg text-[#555555] leading-[18.9px] sm:leading-[26px] font-500 mt-6 sm:mt-8">
              {index === 0 ? (
                <>
                  <Link
                    className="hover:underline text-blue-800"
                    to="/"
                  >
                    Ambition Hire’s
                  </Link>{" "}
                  proctoring tool harnesses state-of-the-art AI and machine
                  learning algorithms to monitor exams effectively. It utilizes
                  facial recognition, keystroke analysis, and browser lockdown
                  features to deter cheating attempts.
                </>
              ) : (
                item.text
              )}
            </p>
          </div>
        ))}
      </div>
    </GradientSection>
  );
}

export default ProctoringSection2;
