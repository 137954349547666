import React, { useState } from "react";
import { StrategyBox } from "../../../ui/cards";
import { CurasolButton } from "../../../ui/buttons";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
export default function Strategies() {
  const gradient =
    "linear-gradient(115deg, rgba(58, 189, 181, 0.08) 17.08%, rgba(255, 255, 255, 0.08) 39.71%, rgba(58, 189, 181, 0.16) 63.92%)";
  const strategies = [
    {
      title: "Cost-Optimization",
      content:
        "Our platform offers cost-effective hiring solutions, eliminating the need for extensive screening and testing, and reducing the risk of bad hires",
    },
    {
      title: "Time-Saving",
      content:
        "Ambition Hire's real-time application tracking and streamlined assessment process enable you to make quicker hiring decisions, ensuring you secure top talent before your competitors",
    },
    {
      title: "Eliminate Bias",
      content:
        "A diverse question bank reduces the potential for bias in the assessment process. With an array of questions, you can ensure that your assessments are fair and unbiased, offering all candidates an equal opportunity to showcase their skills and knowledge",
    },
    {
      title: "Standardized Your Hiring Process",
      content:
        "Completely standardize your hiring/interview process across geographics and functions. Ensure a standardized, location, human bias agnostic process, for  an excellent candidate experience and enhanced employer branding",
    },

    {
      title: "Proctoring and Anti-Cheat",
      content:
        "We maintain the highest ethical standards in recruitment by offering proctoring and anti-cheat solutions, ensuring the integrity of the assessment process. Rest assured that the candidates you hire have been rigorously assessed and evaluated",
    },
    {
      title: "Resume Parsing",
      content:
        "Resume parsing is a valuable tool that offers significant benefits to recruiters in streamlining and enhancing the hiring process. This technology involves the extraction and analysis of information from resumes and other job application documents, converting unstructured data into a structured format",
    },
    {
      title: "Brand Visibility",
      content:
        "Collaborating with Ambition Hire allows your company to gain visibility among job seekers actively seeking opportunities. Showcase your brand as an employer of choice and attract candidates who resonate with your values and mission",
    },
    {
      title: "User-Friendly Interface",
      content:
        "Our platform prioritizes user-friendliness, making assessments and job applications straightforward and accessible for all",
    },
  ];

  // State to track the current index
  const [currentIndex, setCurrentIndex] = useState(0);

  return (
    <div className="wrapper my-20 pb-32" id="strategies">
      <h2 className="section_heading">Our Strategy</h2>
      {/* use grid area and grid templating */}
      <div className="mt-8">
        <div className="sm:hidden">
          <CurasolButton
            length={strategies.length}
            setCurrentIndex={setCurrentIndex}
          />
        </div>
        <div className="grid  grid-cols-1 sm:hidden gap-4 lg:gap-8 mt-6 sm:mt-20 px-3 sm:px-8  place-content-center">
          {strategies.map((strategy, index) => {
            return (
              <div
                className={`${currentIndex == index ? "block" : "hidden"} `}
                key={strategy.title}
              >
                <StrategyBox
                  gradient={gradient}
                  title={strategy.title}
                  content={strategy.content}
                  key={strategy.title}
                />
              </div>
            );
          })}
        </div>
        <div className="hidden sm:block px-10 mt-[64px] lg:mt-[96px] lg:px-52">
          <Masonry columnsCount={2} gutter="24px">
            {strategies.map((strategy, index) => (
              <StrategyBox
                gradient={gradient}
                title={strategy.title}
                content={strategy.content}
              />
            ))}
          </Masonry>
        </div>
      </div>
    </div>
  );
}
