import React from 'react'
import { PageLayout, RevealAnimation } from '../../ui/wrappers'
import { Accordion } from '../../ui/cards';
import FAQMain from '../../components/faqs/faqsmain';
import FAQsQuestion from '../../components/faqs/faqsQuestion';

function FrequentlyAskedQuestionPage() {
    const gradient = 'linear-gradient(115.03deg,  rgba(108, 146, 181, 0.18) 17.08%, rgba(255, 255, 255, 0.08) 39.71%, rgba(108, 146, 181, 0.24) 63.92%)';
    return (
        <PageLayout>
            <RevealAnimation>
                <FAQMain gradient={gradient}/>
            </RevealAnimation>
            <RevealAnimation>
                <FAQsQuestion gradient={gradient}/>
            </RevealAnimation>
        </PageLayout>
    )
}

export default FrequentlyAskedQuestionPage;