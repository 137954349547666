import React, { useEffect, useState } from 'react';
import customize1 from '../../../../asset/home/customize1.png';
import customize2 from '../../../../asset/home/customize2.png';
import customizeSmall from '../../../../asset/home/smallScreen/customizeSmall.png';
import { MotionConfig, motion } from 'framer-motion';

function Customize() {

  const [zIndex, setZIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setZIndex(prevZIndex => (prevZIndex === 0 ? 1 : 0));
    }, 2000);

    // Clear the interval on component unmount to avoid memory leaks
    return () => clearInterval(intervalId);
  }, []);
  const containerVariants = {
    initial: { opacity: 0 },
    animate: { opacity: 1, transition: { type: 'spring', stiffness: 500, damping: 10, staggerChildren: 0.4 } },
  };

  const imgVariants = {
    initial: { opacity: 0, y: 1000 },
    animate: { opacity: 1, y: 0, transition: { type: 'spring', stiffness: 100, damping: 10, mass: 2 } },
  };
  const getZIndexClass = () => {
    return zIndex === 0 ? 'z-[5]' : 'z-40';
  };
  return (
    <MotionConfig
      transition={{ duration: 0.5 }}
    >
      <motion.div
        variants={containerVariants}
        initial="initial"
        animate="animate"
      >
        <h3 className='lvisible text-[20px] md:text-[24px] tracking-[-1.2px] text-[#98365C] font-krona'>Customizable Product</h3>
        <p className='lvisible text-[16px] mt-[14px] leading-[21.6px] font-500'>Tailor the user interface to align seamlessly with your organization's design theme and branding with our customization options</p>
        <motion.div
          variants={containerVariants}
          initial="initial"
          animate="animate"
          className='grid place-content-center w-full mt-[24px]'
        >
          <motion.div
            variants={imgVariants}
            className='relative z-10'
          >
            <img src={customize1} className='lvisible object-fill' />
            <img src={customizeSmall} className='lg:hidden object-fill' />
          </motion.div>
          <motion.div
            variants={imgVariants}
            className={`relative ${getZIndexClass()}`}
          >
            <img src={customize2} className='lvisible transform md:translate-x-[-40px] md:translate-y-[-100px] xl:translate-x-[150px] xl:translate-y-[-216px] object-fill' />
          </motion.div>
        </motion.div>
      </motion.div>
    </MotionConfig>
  );
}

export default Customize;
