import React, { useState, useEffect } from "react";
import { PageLayout, RevealAnimation } from "../../ui/wrappers";
import { Helmet } from "react-helmet";
import BlogMain from "../../components/blog/blogMain";
import ResumeParserSection3 from "../../components/resumeParser/resumeParserSection3";
import BlogSection2 from "../../components/blog/blogSection2";
import { useParams } from "react-router-dom";
import { getBlogById } from "../../service/api";

function BlogPage() {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);
  const color = "#3883C8";
  const gradient = `linear-gradient(115.03deg, rgba(108, 146, 181, 0.18) 17.08%, rgba(255, 255, 255, 0.08) 39.71%, rgba(108, 146, 181, 0.24) 63.92%)`;

  useEffect(() => {
    const fetchBlog = async () => {
      const data = await getBlogById(id);
      
      setBlog(data.details);
    };

    fetchBlog();
  }, [id]);

  return (
    <PageLayout>
      <Helmet>
        <title>{blog ? blog.title : "Blog"}</title>
        <meta
          name="description"
          content={blog ? blog.description : "Variety of online assessments, aimed at covering the largest amount of job seekers across industries and functional domains."}
        />
        <link rel="canonical" href={`https://ambitionhire.ai/blog/${id}`} />
      </Helmet>
      <RevealAnimation>
        <BlogMain gradient={gradient} color={color} blog={blog} />
      </RevealAnimation>
      <RevealAnimation>
        <BlogSection2 gradient={gradient} color={color} blog={blog} />
      </RevealAnimation>
    </PageLayout>
  );
}

export default BlogPage;
