import React from "react";
import noise from "../../asset/general/Noise.png";
import NavBar from "../../components/nav";
const GradientSection = ({
  id,
  gradient,
  sectionHeading,
  children,
  trailLogo,
  bgColor,
  nav,
  color,
  textColor,
  border = true,
}) => {
  const boxStyle = gradient
    ? {
        background: `${gradient}`,
      }
    : {};

  return (
    <section
      className={`mix-blend-overlay border border-b-0 border-transparent  min-h-screen relative`}
      id={id}
    >
      <div
        className="absolute -z-10 opacity-85 h-full w-full top-0 left-0"
        style={boxStyle}
      ></div>
      <div className="wrapper mt-9 relative z-10">
        {nav && (
          <NavBar
            trailLogo={trailLogo}
            bgColor={bgColor}
            color={color}
            textColor={textColor}
          />
        )}

        <main className="mt-[64px] lg:mt-[111px]">
          {sectionHeading && (
            <h2 className="section_heading">{sectionHeading}</h2>
          )}
          {children}
        </main>
      </div>
      {gradient && (
        <img
          src={noise}
          className="w-full h-full object-fill opacity-[20%] absolute top-0 -z-10 "
        />
      )}
    </section>
  );
};

export default GradientSection;
