import React from 'react';

const OR = () => {
  return (
    <div className="flex items-center w-full">
      <div className="flex-1 border-t border-solid border-white"></div>
      <div className="mx-4 text-[#858585] font-500 text-[16px] leading-[21.6px]">or</div>
      <div className="flex-1 border-t border-solid border-white"></div>
    </div>
  );
};

export default OR;
