import React from "react";

export default function Asterisk({color}) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 2V22M19.5711 4.92893L5.42893 19.0711M22.5 12H2.5M19.5711 19.0711L5.42893 4.92893"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
