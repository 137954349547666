import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getBlogs } from "../../service/api";
import blogImage from "../../asset/blog/blog.png";

const AllBlogsPage = () => {
  const [blogs, setBlogs] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBlogs = async () => {
      const data = await getBlogs();
      setBlogs(data.details);
    };

    fetchBlogs();
  }, []);

  const handleBlogClick = (id) => {
    navigate(`/blog/${id}`);
  };

  return (
    <div className="flex flex-col gap-8 font-Poppins justify-between mb-3 p-6">
      <h1 className="font-Poppins text-[22px] font-krona">Blogs</h1>

      {blogs && blogs.length > 0 ? (
        <div className="flex flex-wrap gap-6 p-6">
          {blogs.map((blog, index) => (
            <div 
              key={index} 
              className="rounded-[16px] w-[242px] h-[280px] p-6 border-2 cursor-pointer "  
              onClick={() => handleBlogClick(blog.id)}
            >
              <div className="w-full h-[164px] rounded-md bg-[#D9D9D9]">
                {blog && blog.file_url ? (
                  <img
                    src={blog.file_url}
                    alt={blog.title}
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <img
                    src={blogImage}
                    alt="Default blog"
                    className="w-full h-full object-cover"
                  />
                )}
              </div>
              <p className="font-normal font-krona text-[18px] leading-[20px] sm:leading-[22.5px] mt-4 overflow-hidden text-ellipsis"
                 style={{
                   display: '-webkit-box',
                   WebkitLineClamp: 2,
                   WebkitBoxOrient: 'vertical'
                 }}>
                {blog.title}
              </p>
            </div>
          ))}
        </div>
      ) : (
        <p>No blogs available</p>
      )}
    </div>
  );
};

export default AllBlogsPage;
