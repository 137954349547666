import React, { useState } from 'react';
import { useField, useFormikContext } from 'formik';

function TextArea({ id, label, placeholder }) {
  const { setFieldTouched, setFieldValue } = useFormikContext();
  const [field, meta] = useField(id);
  const [isFocused, setIsFocused] = useState(false);

  const handleBlur = () => {
    setIsFocused(false);
    setFieldTouched(id, true);
  };

  const handleChange = (event) => {
    setFieldValue(id, event.target.value);
  };

  const boxStyle = {
    border: `2px solid ${isFocused ? '#8B5CF6' : (meta.touched && meta.error ? '#FF0000' : '#D1D1D1')}`,
    outline: 'none',
    transition: 'border-color 0.1s ease-in-out',
  };

  return (
    <div className='flex flex-col gap-3 w-full'>
      <label className='text-[12px] font-bold' htmlFor={id}>
        {label}
      </label>
      <textarea
        id={id}
        placeholder={placeholder}
        {...field}
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={() => setIsFocused(true)}
        className='w-full placeholder:text-[#BBBBBB] placeholder:font-400 font-700 text-[#8079C8] block p-[12px] rounded-[6px] h-[96px] focus:outline-none focus:border-violet border'
        style={boxStyle}
      />
      <div className='text-[12px] h-4'>
        {meta.touched && meta.error && (
          <span className="font-500 leading-[16.2px] text-red-500">
            {meta.error}
          </span>
        )}
      </div>
    </div>
  );
}

export default TextArea;
