import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useFormikContext, useField } from 'formik';

function Input({ id, label, placeholder, phone, type, subScript, link = "" }) {
    const [isFocused, setIsFocused] = useState(false);
    const { setFieldTouched } = useFormikContext();
    const [field, meta] = useField(id || label);

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
        // Set the field as touched when it's blurred
        setFieldTouched(id || label, true);
    };

    const boxStyle = {
        border: `2px solid ${isFocused ? '#8B5CF6' : (meta.touched && meta.error ? '#FF0000' : '#D1D1D1')}`,
        outline: 'none',
        transition: 'border-color 0.1s ease-in-out',
    };

    return (
        <div className={`flex flex-col gap-y-2 overflow-hidden`}>
            <label className={`text-[12px] font-700`} htmlFor={id || label}>
                {label}
            </label>
            <div className='flex items-center bg-white rounded-[6px] p-[12px]' style={boxStyle}>
                {phone && <span className='px-2 border-r-2'>+91 </span>}
                <div className='flex flex-1 justify-center items-center'>
                    <input
                        type={phone ? 'tel' : 'text'}
                        placeholder={placeholder}
                        {...field}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        className={`w-full focus:outline-none placeholder:text-[#BBBBBB] placeholder:font-400 font-700 text-[#8079C8] rounded-[6px] ${phone ? "px-2" : ""}`}
                        id={id || label}
                    />
                </div>
            </div>
            <div className='text-[12px]  flex flex-col'>
                {/* extra subscript */}
                {subScript && subScript.map((item, index) => {
                    return (
                        <Link key={index} to={index == 1 ? { link } : "#"} className={`${index == 1 ? "font-700 text-primary  leading-[16.2px] underline underline-offset-2" : "font-500 leading-[16.2px] text-secondary"}`}>{item}</Link>
                    );
                })}

                {/* error message */}
                <div className='h-4 leading-none'>
                    {meta.touched && meta.error && (
                        <span className="font-500 leading-[16.2px] text-red-500">
                            {meta.error}
                        </span>
                    )}
                </div>

            </div>
        </div>
    );
}

export default Input;
