import React from "react";
import { PageLayout, RevealAnimation } from "../../ui/wrappers";
import { Helmet } from "react-helmet";
import ResumeParserMain from "../../components/resumeParser/resumeParserMain";
import ResumeParserSection2 from "../../components/resumeParser/resumeParserSection2";
import ResumeParserSection3 from "../../components/resumeParser/resumeParserSection3";

function ResumeParserPage() {
  const color = "#3883C8";
  const gradient = `linear-gradient(115.03deg, rgba(108, 146, 181, 0.18) 17.08%, rgba(255, 255, 255, 0.08) 39.71%, rgba(108, 146, 181, 0.24) 63.92%)`;
  return (
    <PageLayout>
      {/* <ProductsMain/> */}
      <Helmet>
        <title>Resume Parser I Online CV Parsing Tool I Resume Parser API.</title>
        <meta
          name="description"
          content="AI-powered software ensures accurate extraction of candidate information. Experience unmatched precision and speed in processing resumes."
        />
        <link rel="canonical" href="https://ambitionhire.ai/resume-parser" />
      </Helmet>
      <RevealAnimation>
        <ResumeParserMain gradient={gradient} color={color} />
      </RevealAnimation>
      <RevealAnimation>
        <ResumeParserSection2 gradient={gradient} color={color}/>
      </RevealAnimation>
      <RevealAnimation>
        <ResumeParserSection3 gradient={gradient} color={color} />
      </RevealAnimation>
    </PageLayout>
  );
}

export default ResumeParserPage;
