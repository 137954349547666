import React, { useEffect, useState } from "react";
import { GradientSection } from "../../../ui/wrappers";
import { FaAngleDown } from "react-icons/fa6";
import { CiSearch } from "react-icons/ci";
import cross from "../../../asset/questionLibrary/action.png";
import { QuestionData } from "../../../data/questionLibraryData";
import { QuestionBankCard } from "../../../ui/cards";
import { CrossCircle } from "../../../asset/icons";
import { CurasolButton } from "../../../ui/buttons";
import MultiDropdown from "../../../ui/inputs/multiDropdown";
import Fuse from "fuse.js";
import QuestionLibraryModal from "../questionLibraryModal";
import { useMedia } from "../../../utils/useMedia";
import { redirect, useNavigate, useLocation } from "react-router-dom";
function QuestionBank({ gradient }) {
  const [pageIndex, setPageIndex] = useState(0);
  const { search } = useLocation();
  console.log(search);
  const options = [
    "General",
    "Domain",
    "Technical",
    "Language",
    "Psychometric",
  ];
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [filteredData, setFilteredData] = useState(QuestionData); // Set initial value to QuestionData
  const cardsPerPage = 6;
  const [totalPages, setTotalPages] = useState(
    Math.ceil(filteredData.length / (cardsPerPage || 6))
  );
  const [selectedCardIndex, setSelectedCardIndex] = useState(null);
  const [selectedCardDetails, setSelectedCardDetails] = useState(null);
  const { isMobile } = useMedia();
  const router = useNavigate();
  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  const removeOption = (option) => {
    setSelectedOptions((prevSelected) =>
      prevSelected.filter((item) => item !== option)
    );
  };

  const clearOptions = () => {
    setSelectedOptions([]);
  };

  function handleModalNavigation(direction) {
    let newIndex =
      (selectedCardIndex + direction + filteredData.length) %
      filteredData.length;
    setSelectedCardIndex(newIndex);
    setSelectedCardDetails(filteredData[newIndex]);
  }

  const handleDetailsClick = (data, index) => {
    // console.log("clicked");
    setSelectedCardIndex(index);
    setSelectedCardDetails(data);
    if (isMobile) {
      router(`/questions-library/${data?.title}`, {
        state: { index, filteredData },
      });
    }
  };
  const closeModal = () => {
    setSelectedCardIndex(null);
  };

  useEffect(() => {
    const fuseOptions = {
      keys: ["title", "introductionText"],
    };
    if (searchInput === "") {
      setFilteredData(QuestionData);
      return;
    }

    const fuse = new Fuse(filteredData, fuseOptions);
    let filteredResults = fuse.search({
      $and: [
        { $or: [{ title: searchInput }, { introductionText: searchInput }] },
      ],
    });

    // Update filtered data
    setFilteredData(filteredResults.map((result) => result.item));
    setPageIndex(0);
  }, [searchInput]);

  useEffect(() => {
    if (selectedOptions.length !== 0) {
      // Filter based on selected options
      const newFilteredResults = QuestionData.filter((result) =>
        selectedOptions.includes(result.type)
      );
      setFilteredData(newFilteredResults);
    } else {
      // If no selected options, show all data
      setFilteredData(QuestionData);
    }
    setPageIndex(0);
  }, [selectedOptions]);

  useEffect(() => {
    setTotalPages(Math.ceil(filteredData.length / (cardsPerPage || 6)));
  }, [filteredData]);

  //for query params
  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    const type = queryParams.get("type");

    if (type) {
      setSelectedOptions([type]);
    }else{
      setSelectedOptions([]);
    }
  }, [search]);

  return (
    <GradientSection sectionHeading={"Our Question Bank Library"}>
      <div className="mt-8 sm:mt-[64px] lg:mt-24">
        <div>
          {/* Buttons */}
          <div className="flex flex-col sm:flex-row gap-6 lg:gap-10 text-[12px]">
            {/* Search button */}
            <div className="flex flex-2 lg:flex-initial items-center gap-2 rounded-[32px] border-2 bg-[#F2FCEA] p-4 text-[#505050] w-full lg:w-[479px]  text-[18px]">
              <CiSearch className="h-[22px] w-[22px]" />
              <input
                onChange={handleSearchInputChange}
                placeholder="Search question banks"
                className="serchInput flex-1 text-[14px] sm:text-[16px] bg-[#F2FCEA] outline-none leading-normal lg:leading-[21.6px] font-500"
              />
            </div>

            {/* DropDown */}
            <div className="flex flex-1 w-full  lg:flex-initial justify-start lg:justify-between flex-col xss:flex-row xs:items-center gap-[28px]">
              <button className="flex items-center justify-between border-2 rounded-[32px] p-4  text-[12px] sm:text-[14px] text-secondary lg:text-[#858585] w-full lg:w-[240px] font-500 leading-[18.9px] whitespace-nowrap">
                <MultiDropdown
                  options={options}
                  selectedOptions={selectedOptions}
                  setSelectedOptions={setSelectedOptions}
                />
              </button>
              <div className="flex sm:hidden lg:flex justify-between items-center gap-[10px] ml-auto">
                <p className="text-[12px] lg:text-[16px] text-[#858585] font-500 whitespace-nowrap">
                  Page {pageIndex + 1} of {totalPages}
                </p>
                <CurasolButton
                  setCurrentIndex={setPageIndex}
                  length={totalPages}
                />
              </div>
            </div>
          </div>

          {/* Results */}
          {selectedOptions.length > 0 && (
            <div className="flex flex-col xss:flex-row text-[12px] xs:items-center mt-4 sm:mt-8 font-500 leading-[18.9px] gap-2">
              <div className="flex gap-2 items-center">
                <span className="whitespace-nowrap">Assessment : </span>
                <div className="flex space-x-2 options">
                  {selectedOptions?.map((option) => (
                    <button
                      key={option}
                      onClick={() => removeOption(option)}
                      className="bg-[#212B36] text-white flex items-center gap-1 px-2 py-1 rounded-lg"
                    >
                      <span>{option}</span>
                      <CrossCircle
                        color={"#FFFFFF"}
                        className="cursor-pointer"
                      />
                    </button>
                  ))}
                </div>
              </div>
              <button
                onClick={clearOptions}
                className="xss:px-4 xs:px-8 text-[14px] font-500 text-secondary text-left cursor-pointer"
              >
                Clear
              </button>
            </div>
          )}
          <div className="flex justify-between mt-4 sm:mt-6">
            <p className="font-500 lg:font-700 leading-[21.6px] text-[12px] sm:text-[16px] text-[#555555]">
              {filteredData?.length} results of {QuestionData?.length}
            </p>
            <div className="hidden sm:flex lg:hidden  justify-between items-center gap-[24px]">
              <p className="text-[16px] text-[#858585] font-500 whitespace-nowrap">
                Page {pageIndex + 1} of {totalPages}
              </p>
              <CurasolButton
                setCurrentIndex={setPageIndex}
                length={totalPages}
              />
            </div>
          </div>
        </div>

        {/* Questions Data */}
        <div
          className="mt-4 sm:mt-8 grid grid-cols-1 xss:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-6"
          style={{ paddingBottom: `${225 - 2 * 96}px` }}
        >
          {filteredData
            .slice(pageIndex * cardsPerPage, (pageIndex + 1) * cardsPerPage)
            .map((data, index) => (
              <QuestionBankCard
                key={data.title}
                data={data}
                onDetailsClick={() => handleDetailsClick(data, index)}
              />
            ))}

          {/* Modal */}
          {selectedCardIndex !== null && (
            <QuestionLibraryModal
              data={selectedCardDetails}
              onClose={closeModal}
              onNavigate={handleModalNavigation}
            />
          )}
        </div>
      </div>
    </GradientSection>
  );
}

export default QuestionBank;
