import React from "react";
import { Link } from "react-router-dom";
import arrow from "../../asset/general/arrow-right.svg";
import { FaArrowRight } from "react-icons/fa6";
import { motion } from "framer-motion";
function QuizButton({ text, bgColor, textColor, py }) {
  const style = `${textColor}`;
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <motion.div
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      transition={{ duration: 0.1 }}
      className="w-full sm:w-[272px] lg:w-fit"
    >
      <Link
        to={"/quiz-demo"}
        onClick={scrollToTop}
        className={`${bgColor} border-2 flex items-center justify-center gap-2 md:gap-[18px] btn px-[32px]  ${py} lg:py-[20px]`}
      >
        <span className="text-white text-[13px] lg:text-[18px] leading-[16.25px] lg:leading-[22.5px] xss:whitespace-nowrap">
          {text}
        </span>
        <p className={` rounded-full p-1 ${style} bg-white`}>
          <FaArrowRight className={`text-[20px] ${style}`} />
        </p>
      </Link>
    </motion.div>
  );
}

export default QuizButton;
