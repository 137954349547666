import React, { useEffect } from 'react'
import ReactDom from 'react-dom';

function Modal(props) {
    useEffect(() => {
        // Disable scrolling when the modal is open
        // if (window.innerWidth > 640) {
        document.body.style.overflow = 'hidden';
        // }

        // Enable scrolling when the modal is closed
        return () => {
            document.body.style.overflow = 'visible';
        };
    }, []);


    const handleOverlayClick = () => {
        // Call the onClose function passed as a prop when the overlay is clicked
        props.onClose();
    }

    return ReactDom.createPortal(
        <div className="fixed inset-0 z-50 flex items-center justify-center">
            <div
                className="fixed inset-0 bg-black opacity-50"
                onClick={handleOverlayClick}
            ></div>
            <div className="relative z-50 px-0 sm:px-6">
                {props.children}
            </div>
        </div>
        ,
        document.getElementById('modal')
    )
}

export default Modal