import { useMediaQuery } from 'react-responsive';

export const useMedia = () => {
    const isMobile = useMediaQuery({ maxWidth: 640 });
    const isTablet = useMediaQuery({ minWidth: 641, maxWidth: 1024 });
    const isDesktop = useMediaQuery({ minWidth: 1025 });

    return { isMobile, isTablet, isDesktop };
};


/**
// MyComponent.js
import React from 'react';
import { useMedia } from './useMedia';
import styles from './styles.module.css';

const MyComponent = () => {
  const { isMobile, isTablet, isDesktop } = useMedia();

  return (
    <div className={styles.imageContainer}>
      {isMobile && <img className={styles.image} src="img1.jpg" alt="Mobile Image" />}
      {isTablet && <img className={styles.image} src="img2.jpg" alt="Tablet Image" />}
      {isDesktop && <img className={styles.image} src="img3.jpg" alt="Desktop Image" />}
    </div>
  );
};

export default MyComponent;
 */