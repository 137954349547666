export const targetDate = new Date("2024-04-18T11:00:00");

export function CalculateTimeRemaining() {
  // Get the current date in IST
  const currentDate = new Date();

  // Set the target date to January 24, 2024, 15:50:00 IST
  // Adjust target date to IST
  targetDate.setMinutes(
    targetDate.getMinutes() + targetDate.getTimezoneOffset() + 330
  );

  const totalMilliseconds = targetDate - currentDate;
  let totalSeconds = Math.floor(totalMilliseconds / 1000) + 24 * 60 * 60;

  return totalSeconds;
}
