import React from 'react'
import { GradientBorder, GradientSection } from '../../ui/wrappers'
import { ContactUsButton, QuizButton } from '../../ui/buttons';
import { PlayCircle } from '../../asset/icons';
import { color } from 'framer-motion';
import { Link } from 'react-router-dom';
import { FaArrowRight } from 'react-icons/fa6';

function PricingMain() {
  const color = '#189C82'
  const gradient = `linear-gradient(115deg, rgba(24, 156, 130, 0.18) 17.08%, rgba(255, 255, 255, 0.08) 39.71%, rgba(24, 156, 130, 0.24) 63.92%)`
  return (
    <GradientSection nav={true} color={'#189C82'} bgColor={'bg-teal'} gradient={gradient} textColor={'text-[#189C82]'}>
      <div className='flex flex-col items-center justify-center'>
        {/* Headings */}
        <h1 className='text-[24px] lg:text-[48px] font-krona tracking-[-2.4px] text-center max-w-[781px]'>Unleash the Power of Ambition Hire for the Cost of a Burger!</h1>
        <p className='text-[14px] lg:text-[22px] font-500 leading-[22px] lg:leading-normal mt-4 lg:mt-6 text-[#505050]'>Get in touch with us for further details</p>

        {/* Buttons */}
        <div className='mt-6 lg:mt-[64px] flex flex-col items-center sm:items-start   sm:flex-row gap-4 lg:gap-6'>
          <Link to={'/contact'} className='flex gap-2 lg:gap-[18px] items-center justify-center bg-teal px-[32px] py-[12px] lg:py-4 rounded-[128px] w-full'>
            <span className='text-[13px] lg:text-[18px] font-krona text-white'>Contact Us</span>
            <p className='bg-white rounded-full grid place-content-center p-[5.455px] lg:p-[7.273px]'>
              <FaArrowRight className={`text-[20px] text-teal`} />
            </p>
          </Link>
          <Link to={'/quiz-demo'} className='font-krona text[14px] lg:text-[18px] text-teal border-[3px] border-teal px-[40px] py-[12px] lg:py-[20px] rounded-[128px] whitespace-nowrap'>
            Take a Quick Demo
          </Link>
        </div>

        {/* Play Text */}
        <div className='mt-8 lg:mt-[24px] flex justify-center md:justify-start gap-2 items-center font-700'>
          <PlayCircle color={color} />
          <a href='#' className={`text-[14px] lg:text-[18px] leading-[18.9px] lg:leading-[24.3px]`} style={{ color: color }}>Check out our Product Summary</a>
        </div>
        <div>
        </div>
      </div>
    </GradientSection>
  )
}

export default PricingMain;