import React from "react";
import { PageLayout, RevealAnimation } from "../../ui/wrappers";
import PricingMain from "../../components/pricing/pricingMain";
import { Helmet } from "react-helmet";

function PricingPage() {
  return (
    <PageLayout>
      <Helmet>
        <title>A cost effective online assessment platform for hiring.</title>
        <meta
          name="description"
          content="Unleash the Power of Ambition Hire for the Cost of a Burger!"
        />
        <link rel="canonical" href="https://ambitionhire.ai/pricing" />
      </Helmet>
      <RevealAnimation>
        <PricingMain />
      </RevealAnimation>
    </PageLayout>
  );
}

export default PricingPage;
