import React from 'react'
import { FaArrowRight } from 'react-icons/fa6'
import { motion } from 'framer-motion'
function SubmitButton({ text, bgColor, textColor }) {
    return (
        <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            transition={{ duration: .1 }}
            type='submit'
            className={`flex w-full sm:w-auto gap-[6px] items-center justify-center ${bgColor} text-white px-6 py-3 rounded-[128px]`}
        >
            <span className='text-[16px] font-700'>{text}</span>
            <p className={` rounded-full p-1 bg-white`}>
                <FaArrowRight className={`text-[20px] ${textColor}`} />
            </p>
        </motion.button>
    )
}

export default SubmitButton;