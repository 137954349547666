import React from 'react'

function Offer_Box({ title, desc }) {
    return (
        <div className='border-2 rounded-[12px] p-6'>
            <h4 className='text-blue-light text-[18px] lg:text-[22px] leading-[24.3px] lg:leading-[32.4px] font-700 '>{title}</h4>
            <p className='text-[14px] lg:text-[18px] mt-4 font-500 leading-[18.9px] lg:leading-[24.3px]'>{desc}</p>
        </div>
    )
}

export default Offer_Box;