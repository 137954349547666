import TrialIcon from "./trial";
import PlayCircle from "./playCircle";
import CrossCircle from "./crossCircle";
import GlobeIcon from "./globeIcon";
import Star from "./star";
import Menu from "./menu";
import ScrollDown from "./scrollDown";
import Youtube from "./youtube";
import Instagram from "./instagram";
import Facebook from "./facebook";
import Linkedin from "./linkedin";
import ChevronRight from "./chevron-right";
import ArrowNarrowLeft from "./arrowNarrowLeft";
import Asterisk from "./asterisk";
import CircleCheck from "./circleCheck";
const Icons = {
  CircleCheck,
  TrialIcon,
  PlayCircle,
  CrossCircle,
  GlobeIcon,
  Star,
  Menu,
  ScrollDown,
  Youtube,
  Instagram,
  Facebook,
  Linkedin,
  ChevronRight,
  ArrowNarrowLeft,
  Asterisk,
};

export default Icons;

export {
  Asterisk,
  TrialIcon,
  PlayCircle,
  CrossCircle,
  GlobeIcon,
  Star,
  Menu,
  ScrollDown,
  Youtube,
  Linkedin,
  Instagram,
  Facebook,
  ChevronRight,
  ArrowNarrowLeft,
};
