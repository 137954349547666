import { GradientBorder, GradientSection } from "../../ui/wrappers";
import { Icons } from "../../asset";
import Arrow1 from "../../asset/proctoring/arrow1";
function ProctoringSection4({ gradient, color }) {
  const data = [
    "Parsing resumes in multiple formats (PDF, Word, plain text)",
    "Extracting key candidate information (name, contact details, education, work experience, skills)",
    "Secure and scalable API infrastructure",
    "Customizable parsing rules to match your specific requirements",
    "Real-time parsing and data retrieval",
  ];

  return (
    <GradientSection sectionHeading={"How does our Proctoring tool work?"}>
      <div className="mt-8 sm:mt-[64px] lg:mt-24 flex flex-col gap-6 mb-32">
        <div className="flex gap-x-8 gap-y-16 md:gap-y-[-20px] flex-col lg:px-20 relative">
          <div className="hidden md:block absolute left-[200px] top-[250px] md:left-[400px] lg:left-[550px] md:top-[200px] xl:left-[580px] xl:top-[130px]">
            <Arrow1 />
          </div>
          <div className="hidden md:block absolute left-[100px] top-[600px] md:left-[100px] md:top-[650px] lg:left-[200px]  xl:left-[330px] lg:top-[600px] xl:top-[550px] transform scale-x-[-1]">
            <Arrow1 />
          </div>
          {/* Box 1 */}
          <div className="bg-[#8079C81A] rounded-[24px] border p-8 md:w-[48%]">
            <h3 className="font-krona text-base md:text-[28px] leading-5 md:leading-[35px] text-center">
              <span>#1</span> <br></br> <span>Assessment Setup</span>
            </h3>
            <p className="text-[#555555] text-sm sm:text-lg font-500 leading-[22px] sm:leading-[26px] mt-6 sm:mt-8">
              Administrators can easily configure test parameters and select
              monitoring options according to their preferences. From webcam
              requirements to proctoring levels, customization is key
            </p>
          </div>
          {/* Box 2 */}
          <div className="bg-[#8079C81A] rounded-[24px] border p-8  md:w-[48%] md:place-self-end">
            <h3 className="font-krona text-base md:text-[28px] leading-5 md:leading-[35px] text-center">
              <span>#2</span> <br></br> <span>Assessment Monitoring</span>
            </h3>
            <p className="text-[#555555] text-sm sm:text-lg font-500 leading-[22px] sm:leading-[26px] mt-6 sm:mt-8">
              During the assessment, our tool employs various monitoring
              techniques, including facial recognition and screen recording, to
              detect any irregularities. Real-time alerts notify proctors of
              potential breaches
            </p>
          </div>
          {/* Box 3 */}
          <div className="bg-[#8079C81A] rounded-[24px] border p-8  md:w-[48%]">
            <h3 className="font-krona text-base md:text-[28px] leading-5 md:leading-[35px] text-center">
              <span>#3</span> <br></br> <span>Post-Assessment Analysis</span>
            </h3>
            <p className="text-[#555555] text-sm sm:text-lg font-500 leading-[22px] sm:leading-[26px] mt-6 sm:mt-8">
              After the assessment concludes, administrators can review
              proctoring reports and flag incidents for further investigation.
              Detailed analytics provide insights into student behavior and
              assessment integrity
            </p>
          </div>
        </div>
      </div>
    </GradientSection>
  );
}

export default ProctoringSection4;
